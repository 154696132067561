import Collection from '../utils/Collection.js';

import {
  FETCH_PAYMENTS_SUCCEEDED,
  FETCH_PAYMENTS_FAILED,
} from '../actions/payment';

const initialState = {
  payments: [],
  count: 0,
  lastSearchOpts: {
    page: 1,
    sortOption: 0,
    locale: 0,
    plan: 0,
    planStatus: 0,
    nameOrEmail: "",
    paypalProfileToken: ""
  }
}

export default function paymentReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_PAYMENTS_SUCCEEDED:
      return {
        ...state, 
        payments: Collection.reset(state.payments, payload.payments),
        count: payload.count,
        lastSearchOpts: payload.opts
      };
    default:
      return state;
  }
}