import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const TimeZoneApiUtils = {
  fetch() {
    return xhr(ApiConstants.FetchTimeZones);
  }

};

export default TimeZoneApiUtils;