import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';

const CalendarHeader = (props) => {
  return (
    <div className="cal-header">
      <table>
        <tbody>
          <tr>
            <td className="cal-timezone side text-center">GMT+8</td>
            <td className="text-center">Sun</td>
            <td className="text-center">Mon</td>
            <td className="text-center">Tue</td>
            <td className="text-center">Wed</td>
            <td className="text-center">Thu</td>
            <td className="text-center">Fri</td>
            <td className="text-center">Sat</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CalendarHeader.propTypes = {
};

export default CalendarHeader;
