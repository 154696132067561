import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class LessonDetailsSidenav extends Component {

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  goBack(e) {
    e.preventDefault();
    const router = this.context.router;
  }

  render() {
    return (
      <div className="sidenav layout-box o-has-rows row-space-3">
        <div className="sidenav-filter-wrapper">
          <ul className="sidenav-list">
            <li><h4>Lesson Details</h4></li>
            <li><Link to="/a/db/lessons" className="sidenav-item">Lesson List</Link></li>            
          </ul>
        </div>
      </div>
    );
  }
}

LessonDetailsSidenav.contextTypes = {
  router: PropTypes.object,
}

export default LessonDetailsSidenav;