import _ from 'lodash';

class FileValidator {
  constructor(file, opts={}) {
    this.file = file;
    this.allowedExtensions = opts.allowedExtensions || ['jpeg', 'jpg', 'png', 'gif'];
    this.maxSize = opts.maxSize || 5000000;
    this.minSize = opts.minSize || 1000;
    this.errors = [];
  }

  validate() {
    const checkValidity = Promise.all([
      this.checkMinFileSize(),
      this.checkMaxFileSize(),
      this.checkExtension()
    ]).then(
      (responses) => Promise.resolve(responses),
      (error) => Promise.reject(error)
    );
    return checkValidity;
  }

  checkExtension() {
    const fileName = this.file.name;
    const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (_.includes(this.allowedExtensions, extension)) {
      return Promise.resolve(this.file);
    } else {
      this.errors.push("Invalid file extension.");
      return Promise.reject(new Error('Invalid file extension.'));
    }
  }

  checkMinFileSize() {
    if (this.minSize <= this.file.size) {
      return Promise.resolve(this.file);
    } else {
      this.errors.push("File size is too small.")
      return Promise.reject(new Error('File size is too small.'));
    }
  }

  checkMaxFileSize() {
    if (this.file.size <= this.maxSize) {
      return Promise.resolve(this.file);
    } else {
      this.errors.push("File size is too big.")
      return Promise.reject(new Error('File size is too big.'));
    }
  }
}

export default FileValidator;