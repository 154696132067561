import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as TutorActions from '../../../actions/tutor';
import * as ScheduleActions from '../../../actions/schedule';
import classnames from 'classnames';
import Immutable from 'immutable';
import Collection from '../../../utils/Collection';
import Calendar from '../../../components/hr/tutor/tutorSchedules/Calendar';
import ContentWrapper from '../../../components/hr/tutor/ContentWrapper';
import moment from 'moment-timezone';
import _ from 'lodash';
import appUtils from '../../../utils/appUtils';


function mapStateToProps(state) {
  const { shift, schedule, booking, holiday } = state
  const { tutorShifts } = shift;
  const { tutorBookings } = booking;
  const { holidays } = holiday;
  const {
    loading,
    updating,
    tutorSchedules,
    editType,
    startTime,
    newAddTypeSchedules,
    newRemoveTypeSchedules,
  } = schedule;
  return {
    loading,
    updating,
    editType,
    startTime,
    newAddTypeSchedules,
    newRemoveTypeSchedules,
    schedules: tutorSchedules,
    shifts: tutorShifts,
    bookings: tutorBookings,
    holidays,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    tutorActions: bindActionCreators(TutorActions, dispatch),
    scheduleActions: bindActionCreators(ScheduleActions, dispatch),
  };
}


class TutorSchedulesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorId: props.match.params.id
    }
    this.saveNewSchedules = this.saveNewSchedules.bind(this);
    this.cancelCreatingNewSchedules = this.cancelCreatingNewSchedules.bind(this);
    this.onUpdateEditType = this.onUpdateEditType.bind(this);
    this.onUpdateNewSchedules = this.onUpdateNewSchedules.bind(this);
    this.addAddTypeSchedules = this.addAddTypeSchedules.bind(this);
    this.addRemoveTypeSchedules = this.addRemoveTypeSchedules.bind(this);
    this.goNextWeek = this.goNextWeek.bind(this);
    this.goPreWeek = this.goPreWeek.bind(this);
    this.fetchNeededData = this.fetchNeededData.bind(this);
  }

  componentWillMount() {
    const startTime = this.getBeginningOfWeek();
    this.fetchNeededData(startTime)
  }

  goNextWeek() {
    const {startTime} = this.props;
    const newStart = startTime.add(7, 'days')
    this.fetchNeededData(newStart)
  }

  goPreWeek() {
    const {startTime} = this.props;
    const newStart = startTime.subtract(7, 'days')
    this.fetchNeededData(newStart)
  }

  getBeginningOfWeek(time=null) {
    if (time) {
      return moment(time).tz("Asia/Manila").startOf('week');
    } else {
      return moment().tz("Asia/Manila").startOf('week');
    }
  }

  fetchNeededData(startTime) {
    const {tutorActions} = this.props;
    const {tutorId} = this.state;
    tutorActions.fetchDataForSchedulesCal(tutorId, startTime)
  }

  cancelCreatingNewSchedules(e) {
    e.preventDefault();
    const {scheduleActions} = this.props;
    scheduleActions.clearEditingSchedules();
  }

  saveNewSchedules(e) {
    e.preventDefault();
    const {
      tutorId,
    } = this.state;
    const {
      scheduleActions,
      startTime,
      newAddTypeSchedules,
      newRemoveTypeSchedules
    } = this.props;
    const params = {
      startTime,
      newAddTypeSchedules,
      newRemoveTypeSchedules
    }
    scheduleActions.createMany(tutorId, params);
  }

  onUpdateNewSchedules(startTimes=[]) {
    const {editType} = this.props;
    if (startTimes.length > 0) {
      if (editType === "Add") {
        this.addAddTypeSchedules(startTimes);
      } else {
        this.addRemoveTypeSchedules(startTimes);
      }
    }
  }

  addAddTypeSchedules(startTimes=[]) {
    const {scheduleActions} = this.props;
    let { newAddTypeSchedules, newRemoveTypeSchedules } = this.props;
    newRemoveTypeSchedules = _.pullAll(newRemoveTypeSchedules, startTimes);
    newAddTypeSchedules = _.concat(newAddTypeSchedules, startTimes);
    newAddTypeSchedules = _.sortedUniq(newAddTypeSchedules)
    scheduleActions.updateEditingSchedules({newRemoveTypeSchedules, newAddTypeSchedules});
  }

  addRemoveTypeSchedules(startTimes=[]) {
    const {scheduleActions} = this.props;
    let { newAddTypeSchedules, newRemoveTypeSchedules } = this.props;
    newAddTypeSchedules = _.pullAll(newAddTypeSchedules, startTimes);
    newRemoveTypeSchedules = _.concat(newRemoveTypeSchedules, startTimes);
    newRemoveTypeSchedules = _.sortedUniq(newRemoveTypeSchedules)
    scheduleActions.updateEditingSchedules({newRemoveTypeSchedules, newAddTypeSchedules});
  }

  onUpdateEditType(value, e=null) {
    if (e) e.preventDefault();
    this.props.scheduleActions.changeEditType(value);
  }

  render() {
    const {
      tutorId,
    } = this.state;
    const {
      startTime,
      shifts,
      bookings,
      schedules,
      loading,
      updating,
      newAddTypeSchedules,
      newRemoveTypeSchedules,
      editType
    } = this.props;
    const params = {
      tutorId,
      startTime,
      shifts,
      bookings,
      schedules,
      loading,
      updating,
      newAddTypeSchedules,
      newRemoveTypeSchedules,
      editType,
      onUpdateNewSchedules: this.onUpdateNewSchedules,
      onUpdateEditType: this.onUpdateEditType,
      cancelCreatingNewSchedules: this.cancelCreatingNewSchedules,
      saveNewSchedules: this.saveNewSchedules,
      goNextWeek: this.goNextWeek,
      goPreWeek: this.goPreWeek,
    }
    let calendar = <div/>
    if (!loading && !appUtils.isNull(startTime)) {
      calendar = <Calendar {...params} />;
    }
    return (
      <ContentWrapper tutorId={tutorId}>
        {calendar}
      </ContentWrapper>
    );
  }
}

TutorSchedulesContainer.contextTypes = {
  router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorSchedulesContainer)
