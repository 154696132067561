import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as TutorActions from '../../../actions/tutor';
import classnames from 'classnames';
import Immutable from 'immutable';
import Collection from '../../../utils/Collection';
import TutorEditorHome from '../../../components/hr/tutor/tutorEditor/Home';
import ContentWrapper from '../../../components/hr/tutor/ContentWrapper';
import UserValidator from '../../../validators/UserValidator';


function mapStateToProps(state) {
  const { tutorData } = state;
  const { tutor, updating, avatarProcessing } = tutorData;
  return {
    tutor,
    updating,
    avatarProcessing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tutorActions: bindActionCreators(TutorActions, dispatch),
  };
}

class TutorAccountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorId: props.match.params.id,
      tempFirstName: null,
      tempLastName: null,
      tempActiveStatus: null,
      tempEmail: null,
      tempNickname: null,
      tempSkypeId: null,
      tempMobileNumber: null,
      tempBio: null,
      tempTeachingExperience: null,
      tempExperienceYears: null,
      tempVideoUrl: null,
      tempStartWorking: null,
      tempEndWorking: null,
      tempBaseRate: null,
      tempGender: null,
      changeAdded: false,
      firstNameValidationResult: null,
      lastNameValidationResult: null,
      emailValidationResult: null,
      nicknameValidationResult: null,
      skypeIdValidationResult: null,
      bioValidationResult: null,
      teachingExperienceValidationResult: null,
      videoUrlValidationResult: null,
    };
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleUpdateDuration = this.handleUpdateDuration.bind(this);
  }

  componentDidMount() {
    const { match, tutorActions } = this.props;
    const tutorId = match.params.id;
    tutorActions.fetchOne(tutorId);
  }

  onUpdateInput(value, stateType) {
    const state = this.state;
    state[stateType] = value;
    state.changeAdded = true;
    this.setState(state);
  }

  handleUpdateDuration(dates) {
    this.setState({
      tempStartWorking: dates.startDate,
      tempEndWorking: dates.endDate,
      changeAdded: true,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      tutor,
      tutorActions,
    } = this.props;
    const {
      tutorId,
      changeAdded,
      tempActiveStatus,
      tempFirstName,
      tempLastName,
      tempEmail,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempBio,
      tempTeachingExperience,
      tempExperienceYears,
      tempStartWorking,
      tempEndWorking,
      tempVideoUrl,
      tempBaseRate,
      tempGender,
    } = this.state;
    const params = {
      tempActiveStatus,
      tempFirstName,
      tempLastName,
      tempEmail,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempBio,
      tempTeachingExperience,
      tempExperienceYears,
      tempStartWorking,
      tempEndWorking,
      tempVideoUrl,
      tempBaseRate,
      tempGender,
    };
    if (!this.state.changeAdded) {
      return false;
    }
    const userValidator = new UserValidator({
      email: tempEmail || tutor.email,
    });
    userValidator.validateTutorProfile()
    .then((result) => {
      if (result.valid) {
        tutorActions.update(tutorId, params);
      } else {
        let message = '';
        for (const err of result.errors) {
          message += `${err} `;
        }
        // ServerMessageActions.addMessage({
        //   alertType: "error",
        //   type: "userValidationFailed",
        //   message,
        //   autoDisappear: false,
        // });
      }
    });
  }

  render() {
    const {
      tutor,
      updating,
      avatarProcessing,
      tutorActions,
    } = this.props;
    const {
      tutorId,
      changeAdded,
      tempActiveStatus,
      tempFirstName,
      tempLastName,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempEmail,
      tempBio,
      tempTeachingExperience,
      tempVideoUrl,
      tempStartWorking,
      tempEndWorking,
      tempBaseRate,
      tempGender,
      tempExperienceYears,
    } = this.state;
    let tutorEditorHome = <div />;
    if (tutor) {
      tutorEditorHome = (<TutorEditorHome
        tutor={tutor}
        onSubmit={this.onSubmit}
        changeAdded={changeAdded}
        onUpdateInput={this.onUpdateInput}
        tempActiveStatus={tempActiveStatus}
        tempFirstName={tempFirstName}
        tempLastName={tempLastName}
        tempNickname={tempNickname}
        tempSkypeId={tempSkypeId}
        tempMobileNumber={tempMobileNumber}
        tempEmail={tempEmail}
        tempBio={tempBio}
        tempVideoUrl={tempVideoUrl}
        tempStartWorking={tempStartWorking}
        tempEndWorking={tempEndWorking}
        tempTeachingExperience={tempTeachingExperience}
        tempExperienceYears={tempExperienceYears}
        tempBaseRate={tempBaseRate}
        tempGender={tempGender}
        avatarProcessing={avatarProcessing}
        uploadAvatar={tutorActions.uploadAvatar}
        deleteAvatar={tutorActions.deleteAvatar}
        onUpdateDuration={this.handleUpdateDuration}
      />);
    }
    return (
      <ContentWrapper tutorId={tutorId}>
        {tutorEditorHome}
      </ContentWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorAccountContainer);
