import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { routerMiddleware, push } from 'react-router-redux';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export default function configureStore(initialState) {
  const finalCreateStore = createStoreWithMiddleware(rootReducer, initialState);

  return finalCreateStore;
}
