import Collection from '../utils/Collection.js';
import _ from 'lodash';

import {
  CREATE_MANY_SCHEDULES_SENT,
  CREATE_MANY_SCHEDULES_SUCCEEDED,
  CREATE_MANY_SCHEDULES_FAILED,
  UPDATE_EDITING_SCHEDULES,
  CLEAR_EDITING_SCHEDULES,
  CHANGE_SCHEDULE_EDIT_TYPE
} from '../actions/schedule';

import {
  FETCH_DATA_FOR_SCHEDULES_CAL_SENT,
  FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED
} from '../actions/tutor';

const initialState = {
  loading: false,
  updating: false,
  schedules: [],
  tutorSchedules: [],
  editType: "Add",
  startTime: null,
  newAddTypeSchedules: [],
  newRemoveTypeSchedules: [],
}

export default function scheduleReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_DATA_FOR_SCHEDULES_CAL_SENT:
      return {
        ...state, 
        startTime: payload.startTime,
        loading: true,
      };
    case FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED:
      return {
        ...state, 
        tutorSchedules: Collection.reset(state.tutorSchedules, payload.schedules),
        loading: false
      };
    case CREATE_MANY_SCHEDULES_SENT:
      return {
        ...state, 
        updating: true
      };
    case CREATE_MANY_SCHEDULES_SUCCEEDED:
      return {
        ...state, 
        schedules: [],
        tutorSchedules: Collection.reset(state.tutorSchedules, payload.schedules),
        updating: false,
        newAddTypeSchedules: [],
        newRemoveTypeSchedules: []
      };
    case UPDATE_EDITING_SCHEDULES:
      const {
        newAddTypeSchedules,
        newRemoveTypeSchedules
      } = payload;
      return {
        ...state, 
        newAddTypeSchedules,
        newRemoveTypeSchedules
      };
    case CLEAR_EDITING_SCHEDULES:
      return {
        ...state, 
        newAddTypeSchedules: [],
        newRemoveTypeSchedules: []
      };
    case CHANGE_SCHEDULE_EDIT_TYPE:
      return {
        ...state, 
        editType: payload.editType
      };
    default:
      return state;
  }
}