import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';

const LessonApiUtils = {
  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.FetchLessons + query);
  },

  fetchOne(id) {
    return xhr(ApiConstants.FetchLessons + '/' + id);
  },

  cancel(id, params) {
    return xhr(`${ApiConstants.Base}lessons/${id}/cancel`, {
      method: "put",
      body: JSON.stringify({
        reason: params.reason,
        comment: params.comment,
      })
    })
  }
};

export default LessonApiUtils;
