import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NotFound = () =>
  (
  <div className="container-fluid row-space-top-4 row-space-4">
    <div className="row">
      <div className="col-center col-md-8">
        <h3 className="row-space-2">Sorry this page is not exist.</h3>
        <p className="row-space-4">
          We changed the url or you entered wrong url.
          Sorry for the inconvenience but please try other url or back to the top page.
        </p>
        <Link to="/">Go to Home</Link>
      </div>
    </div>
  </div>
  );

export default NotFound;
