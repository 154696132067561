import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ShiftSetActions from '../../../actions/shiftSet';
import * as ShiftActions from '../../../actions/shift';
import Collection from '../../../utils/Collection';
import moment from 'moment-timezone';
import _ from 'lodash';
import classnames from 'classnames';
import Immutable from 'immutable';
import TutorShiftHome from '../../../components/hr/tutor/tutorShift/Home';
import ContentWrapper from '../../../components/hr/tutor/ContentWrapper';

function mapStateToProps(state) {
  const { shiftSet } = state;
  const {
    loading,
    editing,
    saving,
    chosenShiftSetId,
    tutorShiftSets,
    newShiftSetShifts,
    durationEndOfFomerSet,
    durationStartOfNewSet,
    durationEndOfNewSet,
    editType,
    shiftSetIdToDuplicate,
  } = shiftSet;
  return {
    loading,
    editing,
    saving,
    chosenShiftSetId,
    newShiftSetShifts,
    durationEndOfFomerSet,
    durationStartOfNewSet,
    durationEndOfNewSet,
    editType,
    shiftSetIdToDuplicate,
    shiftSets: tutorShiftSets,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    shiftSetActions: bindActionCreators(ShiftSetActions, dispatch),
  };
}

class TutorShiftContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorId: this.props.match.params.id,
    };
    this.openAddShiftSetModal = this.openAddShiftSetModal.bind(this);
    this.openEditShiftSetModal = this.openEditShiftSetModal.bind(this);
    this.initiateNewShiftSet = this.initiateNewShiftSet.bind(this);
    this.saveNewShiftSet = this.saveNewShiftSet.bind(this);
    this.cancelCreatingNewShiftSet = this.cancelCreatingNewShiftSet.bind(this);
    this.onUpdateDurationEndOfFormerSet = this.onUpdateDurationEndOfFormerSet.bind(this);
    this.onUpdateDurationStartOfNewSet = this.onUpdateDurationStartOfNewSet.bind(this);
    this.onUpdateDurationEndOfNewSet = this.onUpdateDurationEndOfNewSet.bind(this);
    this.onUpdateDurationOfNewSet = this.onUpdateDurationOfNewSet.bind(this);
    this.onUpdateTypeOfEditor = this.onUpdateTypeOfEditor.bind(this);
    this.onUpdateNewShiftSetShifts = this.onUpdateNewShiftSetShifts.bind(this);
    this.addToNewShiftSet = this.addToNewShiftSet.bind(this);
    this.removeFromNewShiftSet = this.removeFromNewShiftSet.bind(this);
    this.switchShiftSet = this.switchShiftSet.bind(this);
  }

  componentWillMount() {
    this.props.shiftSetActions.fetchByTutor(this.state.tutorId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.shiftSets.length === 0 && nextProps.shiftSets.length > 0) {
      this.switchShiftSet(nextProps.shiftSets[0].id);
    }
  }

  switchShiftSet(shiftSetId, e = null) {
    if (e) e.preventDefault();
    this.props.shiftSetActions.updateChosenShiftSetId(shiftSetId);
  }

  initiateNewShiftSet(shiftSetId) {
    let newShiftSetShifts = [];
    const { shiftSets, shiftSetActions } = this.props;
    const chosenId = shiftSetId;
    if (chosenId !== 0) {
      const shiftSet = _.findLast(shiftSets, ss => ss.id === chosenId);
      if (shiftSet) {
        for (const shift of shiftSet.shifts) {
          const tempShifts = [];
          let i = shift.start_slot;
          while (i < shift.end_slot) {
            tempShifts.push(i);
            i += 0.5;
          }
          newShiftSetShifts = newShiftSetShifts.concat(tempShifts);
        }
      }
    }
    shiftSetActions.initiateNewShiftSet(newShiftSetShifts);
  }

  onUpdateDurationEndOfFormerSet(value) {
    this.props.shiftSetActions.updateDurationEndOfFormerSet(value);
  }

  onUpdateDurationStartOfNewSet(value) {
    this.props.shiftSetActions.updateDurationStartOfNewSet(value);
  }

  onUpdateDurationEndOfNewSet(value) {
    this.props.shiftSetActions.updateDurationEndOfNewSet(value);
  }

  onUpdateDurationOfNewSet(dates) {
    this.props.shiftSetActions.updateDurationOfNewSet(dates);
  }

  onUpdateSetIdToDuplicate(value) {
    this.props.shiftSetActions.updateShiftSetIdToDuplicate(value);
  }

  cancelCreatingNewShiftSet(e) {
    e.preventDefault();
    this.props.shiftSetActions.cancelCreatingNewShiftSet();
    window.scrollTo(0, 0);
  }

  saveNewShiftSet(e) {
    e.preventDefault();
    const { tutorId } = this.state;
    const {
      shiftSetActions,
      durationEndOfFomerSet,
      durationStartOfNewSet,
      durationEndOfNewSet,
      newShiftSetShifts,
    } = this.props;
    const params = {
      durationEndOfFomerSet,
      durationStartOfNewSet,
      durationEndOfNewSet,
      newShiftSetShifts,
    };
    shiftSetActions.create(tutorId, params);
  }

  onUpdateNewShiftSetShifts(slotNums = []) {
    const { editType } = this.props;
    if (slotNums.length > 0) {
      if (editType === 'Add') {
        this.addToNewShiftSet(slotNums);
      } else {
        this.removeFromNewShiftSet(slotNums);
      }
    }
  }

  addToNewShiftSet(slotNums = []) {
    const { shiftSetActions } = this.props;
    let { newShiftSetShifts } = this.props;
    newShiftSetShifts = _.concat(newShiftSetShifts, slotNums);
    newShiftSetShifts = _.sortedUniq(newShiftSetShifts);
    shiftSetActions.updateNewShiftSetShifts(newShiftSetShifts);
  }

  removeFromNewShiftSet(slotNums = []) {
    const { shiftSetActions } = this.props;
    let { newShiftSetShifts } = this.props;
    newShiftSetShifts = _.pullAll(newShiftSetShifts, slotNums);
    newShiftSetShifts = _.sortedUniq(newShiftSetShifts);
    shiftSetActions.updateNewShiftSetShifts(newShiftSetShifts);
  }

  onUpdateTypeOfEditor(value, e = null) {
    if (e) e.preventDefault();
    this.props.shiftSetActions.changeEditType(value);
  }

  openAddShiftSetModal() {
    const { shiftSets } = this.state;
    this.context.showModal('addShiftSet', {
      shiftSets,
      initiateNewShiftSet: this.initiateNewShiftSet,
    });
  }

  openEditShiftSetModal(e) {
    const { tutorId } = this.state;
    const { shiftSets, chosenShiftSetId } = this.props;
    this.context.showModal('editShiftSet', {
      tutorId,
      shiftSets,
      chosenShiftSetId,
    });
  }

  render() {
    const { tutorId } = this.state;
    const {
      shiftSets,
      chosenShiftSetId,
      loading,
      editing,
      saving,
      newShiftSetShifts,
      durationEndOfFomerSet,
      durationStartOfNewSet,
      durationEndOfNewSet,
      editType,
    } = this.props;
    let tutorShiftHome = <div />;
    if (!loading) {
      tutorShiftHome = (<TutorShiftHome
        openAddShiftSetModal={this.openAddShiftSetModal}
        openEditShiftSetModal={this.openEditShiftSetModal}
        initiateNewShiftSet={this.initiateNewShiftSet}
        durationEndOfFomerSet={durationEndOfFomerSet}
        durationStartOfNewSet={durationStartOfNewSet}
        durationEndOfNewSet={durationEndOfNewSet}
        onUpdateDurationEndOfFormerSet={this.onUpdateDurationEndOfFormerSet}
        onUpdateDurationStartOfNewSet={this.onUpdateDurationStartOfNewSet}
        onUpdateDurationEndOfNewSet={this.onUpdateDurationEndOfNewSet}
        onUpdateDurationOfNewSet={this.onUpdateDurationOfNewSet}
        onUpdateTypeOfEditor={this.onUpdateTypeOfEditor}
        onUpdateNewShiftSetShifts={this.onUpdateNewShiftSetShifts}
        saveNewShiftSet={this.saveNewShiftSet}
        cancelCreatingNewShiftSet={this.cancelCreatingNewShiftSet}
        switchShiftSet={this.switchShiftSet}
        newShiftSetShifts={newShiftSetShifts}
        shiftSets={shiftSets}
        chosenShiftSetId={chosenShiftSetId}
        editType={editType}
        editing={editing}
        saving={saving}
        loading={loading}
      />);
    }
    return (
      <ContentWrapper tutorId={tutorId}>
        {tutorShiftHome}
      </ContentWrapper>
    );
  }
}

TutorShiftContainer.contextTypes = {
  showModal: PropTypes.func,
  router: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorShiftContainer);
