import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table';
// import { Link } from 'react-router-dom';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';
import moment from 'moment';
import LinkCell from '../../db/LinkCell';

class TutorsTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  render() {
    const items = this.props.tutors;
    const screenWidth = this.state.width
    let width = screenWidth >= 1100 ? screenWidth * 0.833 - 25 : screenWidth - 25;
    let height = 50 + items.length * 50 > 500 ? 500 : 50 + items.length * 50;
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={items.length}
        headerHeight={50}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={
            <LinkCell
              baseUrl='/a/hr/'
              tableName='tutors'
              data={items}
              field="id"
            />
          }
          width={50}
        />
        <Column
          header={<Cell>NAME</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].name}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>EMAIL</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].email}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>SKYPE ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].skype_name}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Taught</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].taught}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>CREATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].created_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
        <Column
          header={<Cell>UPDATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].updated_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
      </Table>
    );
  }
}

TutorsTable.propTypes = {
  tutors: PropTypes.array,
};

export default TutorsTable;
