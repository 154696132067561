import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as UserActions from '../../actions/user';
import classnames from 'classnames';
import Sidenav from '../../components/db/Sidenav'
import { DBRoutes } from '../../routes';

function mapStateToProps(state) {
}

function mapDispatchToProps(dispatch) {
}

class DbIndex extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="admin-database-content" className="row-space-4 row-space-top-4">
        <DBRoutes />
      </div>
    );
  }

}

export default DbIndex
// export default connect(mapStateToProps, mapDispatchToProps)(DbIndex);
