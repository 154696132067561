import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatabaseSidenav from '../Sidenav.js';
import LessonFilter from './LessonFilter.js';
import LessonsTable from './LessonsTable.js';

class Lessons extends Component {
  render() {
    const {
      handleSubmit,
      changeAdded,
      handleUpdateSortOption,
      handleUpdateChosenStatus,
      handleUpdateStudentEmail,
      handleUpdateTutorEmail,
      handleUpdatePage,
      handleUpdateTrialOption,
      chosenSortOption,
      chosenStatus,
      chosenTrialOption,
      studentEmail,
      tutorEmail,
      page,
      lessons,
      count,
      onUpdateDuration,
      durationStart,
      durationEnd,
    } = this.props;
    return (
      <div id="database-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <DatabaseSidenav />
            </div>
            <div className="col-lg-10">
              <LessonFilter
                handleSubmit={handleSubmit}
                changeAdded={changeAdded}
                updateSortOption={handleUpdateSortOption}
                updateChosenStatus={handleUpdateChosenStatus}
                updateStudentEmail={handleUpdateStudentEmail}
                updateTutorEmail={handleUpdateTutorEmail}
                updateTrialOption={handleUpdateTrialOption}
                updatePage={handleUpdatePage}
                chosenSortOption={chosenSortOption}
                chosenStatus={chosenStatus}
                chosenTrialOption={chosenTrialOption}
                studentEmail={studentEmail}
                tutorEmail={tutorEmail}
                onUpdateDuration={onUpdateDuration}
                durationStart={durationStart}
                durationEnd={durationEnd}
                page={page}
                count={count} />
              <LessonsTable lessons={lessons}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Lessons;
