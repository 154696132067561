import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class LessonDetailsIssue extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.context.showModal('updateResolvedIssue', {
      issue: this.props.issue
    });
  }
  
  render() {
    const issue = this.props.issue;
    let reported = "N/A";
    let sender = "N/A";
    let comment = "N/A";
    let status = "N/A";
    let reason = "N/A";
    let reasonComment = "N/A";
    let action = "N/A";
    let actionComment = "N/A";
    //let homework = "N/A"
    //let others = "N/A"
    if (issue !== null) {
      status = issue.status_id === 1 ? "Open" : "Resolved";
      reported = issue.issue_types[0];
      sender = issue.sender_name + " (" + issue.sender_id.toString() + ")";
      comment = issue.comment
      if (issue.reason !== null) {
        reason = issue.reason.title;
        reasonComment = issue.reason.comment
      } else {
        reason = "No Data"
        reasonComment = "No Data"
      }
      if (issue.action !== null) {
        action = issue.action.title
        actionComment = issue.action.comment
      } else {
        action = "No Data"
        actionComment = "No Data"
      }
    }
    return (
      <div className="panel row-space-4">
        <div className="panel-header">Lesson Issue</div>
        <div className="panel-body panel-body-no-border">
          <div className="form-body">
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Reported</b>
              </div>
              <div className="col-sm-10">
                {reported}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Comment</b>
              </div>
              <div className="col-sm-10">
                {comment}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Sender</b>
              </div>
              <div className="col-sm-10">
                {sender}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Status</b>
              </div>
              <div className="col-sm-10">
                {status}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Reason</b>
              </div>
              <div className="col-sm-10">
                {reason}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Reason Comment</b>
              </div>
              <div className="col-sm-10">
                {reasonComment}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Action</b>
              </div>
              <div className="col-sm-10">
                {action}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Action Comment</b>
              </div>
              <div className="col-sm-10">
                {actionComment}
              </div>
            </div>
          </div>
        </div>
        <div className="panel-footer text-right">
          <div className="btn btn-green" onClick={this.onClick}>Update</div>
        </div>
      </div>
    );
  }
}

LessonDetailsIssue.contextTypes = {
  showModal: PropTypes.func
}

export default LessonDetailsIssue;