import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import CalendarContainer from './cal/CalendarContainer';
import classnames from 'classnames';
import _ from 'lodash';

class HrTutorShiftEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedOnDurationEndOfFormerSet: false,
      focusedInputOnNewSet: null
    }
    this.onFocusOnDurationOfNewSetChange = this.onFocusOnDurationOfNewSetChange.bind(this);
  }

  onFocusOnDurationOfNewSetChange(focused) {
    this.setState({
      focusedInputOnNewSet: focused
    })
  }

  renderAddRemoveButtons() {
    const {editType, onUpdateTypeOfEditor} = this.props;
    let addClass = classnames("btn btn-small btn-no-radius", 
      {"btn-secondary": editType === "Remove"}, {"btn-green": editType === "Add"});
    let removeClass = classnames("btn btn-small btn-no-radius with-left-margin", 
      {"btn-secondary": editType === "Add"}, {"btn-green": editType === "Remove"});
    return (
      <div className="shift-edit-type-buttons">
        <button className={addClass} onClick={(e) => {onUpdateTypeOfEditor("Add", e)}}>Add</button>
        <button className={removeClass} onClick={(e) => {onUpdateTypeOfEditor("Remove", e)}}>Remove</button>
      </div>
    )
  }

  renderSetEndOfFomerSet() {
    const {shiftSets} = this.props;
    const lastSet = _.last(shiftSets);
  }

  render() {
    const {
      focusedOnDurationEndOfFormerSet,
      focusedInputOnNewSet
    } = this.state;
    const {
      editing,
      saving,
      editType,
      newShiftSetShifts,
      durationEndOfFomerSet, 
      durationStartOfNewSet,
      durationEndOfNewSet,
      onUpdateDurationEndOfFormerSet,
      onUpdateDurationStartOfNewSet,
      onUpdateDurationEndOfNewSet,
      onUpdateDurationOfNewSet,
      onUpdateTypeOfEditor,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet,
      shiftSets,
    } = this.props;
    let durationEndOfFormerSetPart = <div/>
    if (shiftSets.length > 0) {
      durationEndOfFormerSetPart = (
        <div>
          <h4 className="row-space-2">Please choose the duration end of the shift set currently active. </h4>
          <SingleDatePicker
            id="duration_end_of_former_set_input"
            date={durationEndOfFomerSet}
            numberOfMonths={1}
            focused={focusedOnDurationEndOfFormerSet}
            onDateChange={(date) => { onUpdateDurationEndOfFormerSet(date)}}
            onFocusChange={({ focused }) => { this.setState({ focusedOnDurationEndOfFormerSet: focused }); }}
          />
          <hr/>
        </div>
      );
    }
    return (
      <div>
        <div className="actions-wrapper row-space-4">
          <h3 className="row-space-4 row-space-top-4">Create new shfif set</h3>
          {durationEndOfFormerSetPart }
          <h4 className="row-space-2">Please choose the duration start and end of the new shift set. You can keep the duration end empty. </h4>
          <DateRangePicker
            startDate={durationStartOfNewSet}
            endDate={durationEndOfNewSet}
            numberOfMonths={1}
            focusedInput={focusedInputOnNewSet}
            onDatesChange={(dates) => {onUpdateDurationOfNewSet(dates)}}
            onFocusChange={( focused ) => { this.onFocusOnDurationOfNewSetChange(focused) }}
          />
          <hr/>
          <h4 className="row-space-2">Set weekly wchedule</h4>
          {this.renderAddRemoveButtons()}
          <CalendarContainer
            editType={editType}
            editing={editing}
            saving={saving}
            shifts={newShiftSetShifts}
            saveNewShiftSet={saveNewShiftSet}
            onUpdateNewShiftSetShifts={onUpdateNewShiftSetShifts}
          />
        </div>
      </div>
    );
  }
}

export default HrTutorShiftEditor;