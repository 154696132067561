import Collection from '../utils/Collection.js';

import {
  FETCH_SHIFT_SETS_BY_TUTOR_SENT,
  FETCH_SHIFT_SETS_BY_TUTOR_SUCCEEDED,
  FETCH_SHIFT_SETS_BY_TUTOR_FAILED,
  CREATE_SHIFT_SET_SENT,
  CREATE_SHIFT_SET_SUCCEEDED,
  CREATE_SHIFT_SET_FAILED,
  SET_SHIFT_SET_END_DATE_SENT,
  SET_SHIFT_SET_END_DATE_SUCCEEDED,
  SET_SHIFT_SET_END_DATE_FAILED,
  UPDATE_CHOSEN_SHIFT_SET_ID,
  UPDATE_DURATION_END_OF_FORMER_SET,
  UPDATE_DURATION_START_OF_NEW_SET,
  UPDATE_DURATION_END_OF_NEW_SET,
  UPDATE_DURATION_OF_NEW_SET,
  UPDATE_SHIFT_SET_ID_TO_DUPLICATE,
  UPDATE_SHIFT_SET_ID_TO_EDIT,
  CANCEL_CREATING_NEW_SHIFT_SET,
  INITIATE_NEW_SHIFT_SET,
  CHANGE_SHIFT_SET_EDIT_TYPE,
  UPDATE_DURATION_END_OF_CHOSEN_SET,
  UPDATE_NEW_SHIFT_SET_SHIFTS
} from '../actions/shiftSet';

const initialState = {
  tutorShiftSets: [],
  chosenShiftSetId: null,
  loading: true,
  saving: false,
  editing: false,
  shiftSetIdToDuplicate: null,
  newShiftSetShifts: [],
  durationEndOfFomerSet: null,
  durationStartOfNewSet: null,
  durationEndOfNewSet: null,
  editType: "Add",
  shiftSetIdToEdit: null,
  durationEndOfChosenSet: null,
}

export default function shiftSetReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_CHOSEN_SHIFT_SET_ID:
      return {
        ...state, 
        chosenShiftSetId: payload.id,
      };
    case UPDATE_SHIFT_SET_ID_TO_EDIT:
      return {
        ...state, 
        shiftSetIdToEdit: payload.id,
        durationEndOfChosenSet: null
      };
    case FETCH_SHIFT_SETS_BY_TUTOR_SENT:
      return {
        ...state, 
        loading: true,
      };
    case FETCH_SHIFT_SETS_BY_TUTOR_SUCCEEDED:
      return {
        ...state, 
        tutorShiftSets: Collection.reset(state.tutorShiftSets, payload.shift_sets),
        loading: false
      };
    case FETCH_SHIFT_SETS_BY_TUTOR_FAILED:
      return {
        ...state, 
        loading: false
      };
    case UPDATE_DURATION_END_OF_FORMER_SET:
      return {
        ...state, 
        durationEndOfFomerSet: payload.value
      };
    case UPDATE_DURATION_START_OF_NEW_SET:
      return {
        ...state, 
        durationStartOfNewSet: payload.value
      };
    case UPDATE_DURATION_END_OF_NEW_SET:
      return {
        ...state, 
        durationEndOfNewSet: payload.value
      };
    case UPDATE_DURATION_OF_NEW_SET:
      return {
        ...state, 
        durationStartOfNewSet: payload.dates.startDate,
        durationEndOfNewSet: payload.dates.endDate
      };
    case UPDATE_DURATION_END_OF_CHOSEN_SET:
      return {
        ...state, 
        durationEndOfChosenSet: payload.value
      };
    case UPDATE_SHIFT_SET_ID_TO_DUPLICATE:
      return {
        ...state, 
        shiftSetIdToDuplicate: payload.id
      };
    case CHANGE_SHIFT_SET_EDIT_TYPE:
      return {
        ...state, 
        editType: payload.editType
      };
    case INITIATE_NEW_SHIFT_SET:
      return {
        ...state, 
        editing: true,
        editType: "Add",
        newShiftSetShifts: payload.newShiftSetShifts,
      };
    case CANCEL_CREATING_NEW_SHIFT_SET:
      return {
        ...state, 
        editing: false,
        newShiftSetShifts: []
      };
    case CREATE_SHIFT_SET_SENT:
      return {
        ...state,
        saving: true
      }
    case CREATE_SHIFT_SET_SUCCEEDED:
      if (payload.former_shfit_set) {
        let tempShiftSets = Collection.update(state.tutorShiftSets, payload.former_shift_set.id, payload.former_shift_set)
        return {
          ...state,
          tutorShiftSets: Collection.add(tempShiftSets, payload.new_shift_set),
          saving: false
        }
      } else {
        return {
          ...state,
          tutorShiftSets: Collection.add(state.tutorShiftSets, payload.new_shift_set),
          saving: false
        }
      }
    case SET_SHIFT_SET_END_DATE_SENT:
      return {
        ...state,
        saving: true
      }
    case SET_SHIFT_SET_END_DATE_SUCCEEDED:
      return {
        ...state, 
        tutorShiftSets: Collection.update(state.tutorShiftSets, payload.changed_shift_set.id, payload.changed_shift_set),
        saving: false
      };
    case UPDATE_NEW_SHIFT_SET_SHIFTS:
      return {
        ...state,
        newShiftSetShifts: payload.newShiftSetShifts
      }
    case CREATE_SHIFT_SET_FAILED:
    case SET_SHIFT_SET_END_DATE_FAILED:
      return {
        ...state,
        saving: false
      }
    default:
      return state;
  }
}