import {
  SHOW_SECONDARY_MODAL,
  HIDE_SECONDARY_MODAL,
  ADD_SECONDARY_MODAL_MESSAGE,
  CLEAR_SECONDARY_MODAL_MESSAGE
} from '../actions/modal';

const initialState = {
  isOpen: false,
  content: "",
  autoDisappear: false,
  message: "",
  messageType: "info",
  modalProps: {}
}

export default function secondaryModalReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SHOW_SECONDARY_MODAL:
      const {content, modalProps} = payload;
      return {
        ...state,
        show: true,
        content,
        modalProps
      };
    case HIDE_SECONDARY_MODAL:
      return {
        ...state,
        show: false,
        
      };
    case ADD_SECONDARY_MODAL_MESSAGE:
      return {
        ...state,
        message: payload.message,
        messageType: payload.type || "info",
        autoDisappearMessage: payload.autoDisappear || false
      };
    case CLEAR_SECONDARY_MODAL_MESSAGE: 
      return {
        ...state,
        message: "",
        autoDisappear: false
      };
    default:
      return state;
  }
}