import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class LessonDetailsSummary extends Component {

  constructor(props) {
    super(props);
  }
  
  render() {
    const summary = this.props.summary;
    let content = "N/A"
    let part = "N/A"
    let homework = "N/A"
    let others = "N/A"
    if (summary !== null) {
      content = summary.content;
      part = summary.part;
      homework = summary.homework;
      others = summary.others
    }
    return (
      <div className="panel row-space-4">
        <div className="panel-header">Lesson Summary</div>
        <div className="panel-body panel-body-no-border">
          <div className="form-body">
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Content</b>
              </div>
              <div className="col-sm-10">
                {content}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>part</b>
              </div>
              <div className="col-sm-10">
                {part}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>homework</b>
              </div>
              <div className="col-sm-10">
                {homework}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>others</b>
              </div>
              <div className="col-sm-10">
                {others}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LessonDetailsSummary;