import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import qs from 'query-string';
import moment from 'moment-timezone';
import * as DataActions from '../../actions/data';
import DataDashboardMain from './data/Main';
import ContentWrapper from './ContentWrapper';

function mapStateToProps(state) {
  const { dataForDashboard } = state;
  const {
    loading,
    loaded,
    chosenDate,
    numOfTrialStudentsGrowh,
    numOfTrialStudents,
    numOfTrialLessonsGrowth,
    numOfTrialLessons,
    subscribeRateAfterOneMonth,
    subscribeRateGrowth,
    lessonCompletionRate,
    tutorRelatedIssueRate,
    tutorRelatedNetworkingIssueRate,
    tutorRelatedBlackoutRate,
    tutorRelatedComputerProblemRate,
    tutorRelatedSicknessRate,
    tutorRalatedFamilySicknessRate,
    tutorRelatedOtherIssuesRate,
    studentRelatedIssueRate,
  } = dataForDashboard;
  return {
    loading,
    loaded,
    chosenDate,
    numOfTrialStudentsGrowh,
    numOfTrialStudents,
    numOfTrialLessonsGrowth,
    numOfTrialLessons,
    subscribeRateAfterOneMonth,
    subscribeRateGrowth,
    lessonCompletionRate,
    tutorRelatedIssueRate,
    tutorRelatedNetworkingIssueRate,
    tutorRelatedBlackoutRate,
    tutorRelatedComputerProblemRate,
    tutorRelatedSicknessRate,
    tutorRalatedFamilySicknessRate,
    tutorRelatedOtherIssuesRate,
    studentRelatedIssueRate,
  }
}

function mapDispatchToProps(dispatch) {
  return { dataActions: bindActionCreators(DataActions, dispatch)};
}

class HrDataDashboard extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      changeAdded: false,
      chosenDate: query.chosenDate ? moment.unix(Number(query.chosenDate) / 1000) : null,
    };
    this.handleUpdateChosenDate = this.handleUpdateChosenDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
  }

  componentDidMount() {
    this.props.dataActions.fetchDataForDashboard(this.state.chosenDate)
  }

  handleUpdateChosenDate(date) {
    this.setState({
      chosenDate: date, // momentObject
      changeAdded: true,
    });
  }


  handleSubmit() {
    const { chosenDate } = this.state;
    this.changeUrl({ chosenDate });
    this.props.dataActions.fetchDataForDashboard(chosenDate);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    let first = true;
    let query = '?';
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          query += '&'
        }
        query += `${key}=${opts[key]}`;
        first = false;
      }
      baseUrl += query
    }
    this.props.history.push(this.props.match.path + query);
    window.scroll(0, 0);
  }

  render() {
    const {
      loading,
      loaded,
      numOfTrialStudentsGrowh,
      numOfTrialStudents,
      numOfTrialLessonsGrowth,
      numOfTrialLessons,
      subscribeRateAfterOneMonth,
      subscribeRateGrowth,
      lessonCompletionRate,
      tutorRelatedIssueRate,
      tutorRelatedNetworkingIssueRate,
      tutorRelatedBlackoutRate,
      tutorRelatedComputerProblemRate,
      tutorRelatedSicknessRate,
      tutorRalatedFamilySicknessRate,
      tutorRelatedOtherIssuesRate,
      studentRelatedIssueRate,
    } = this.props;
    const {
      chosenDate
    } = this.state;
    const mainProps = {
      loading,
      loaded,
      chosenDate,
      numOfTrialStudentsGrowh,
      numOfTrialStudents,
      numOfTrialLessonsGrowth,
      numOfTrialLessons,
      subscribeRateAfterOneMonth,
      subscribeRateGrowth,
      lessonCompletionRate,
      tutorRelatedIssueRate,
      tutorRelatedNetworkingIssueRate,
      tutorRelatedBlackoutRate,
      tutorRelatedComputerProblemRate,
      tutorRelatedSicknessRate,
      tutorRalatedFamilySicknessRate,
      tutorRelatedOtherIssuesRate,
      studentRelatedIssueRate,
      handleUpdateChosenDate: this.handleUpdateChosenDate,
      handleSubmit: this.handleSubmit
    }
    return (
      <ContentWrapper>
        <DataDashboardMain {...mainProps} />
      </ContentWrapper>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(HrDataDashboard);
