import Collection from '../utils/Collection.js';

import {
  FETCH_MATERIAL_SUCCEEDED,
  FETCH_MATERIAL_FAILED,
  UPDATE_MATERIAL_SUCCEEDED,
  UPDATE_MATERIAL_FAILED,
  UPLOAD_MATERIAL_FILE_SUCCEEDED,
  UPLOAD_MATERIAL_FILE_FAILED,
  DELETE_MATERIAL_FILE_SUCCEEDED,
  DELETE_MATERIAL_FILE_FAILED
} from '../actions/material';

const initialState = {
  material: null
}

export default function materialDataReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_MATERIAL_SUCCEEDED:
    case UPDATE_MATERIAL_SUCCEEDED:
    case UPLOAD_MATERIAL_FILE_SUCCEEDED:
    case DELETE_MATERIAL_FILE_SUCCEEDED:
      return {
        ...state, 
        material: payload.material,
      };
    default:
      return state;
  }
}