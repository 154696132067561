import UserApiUtils from '../utils/api/user';
import {addMessage as addFlashMessage} from './flash';
import {addMessage as addModalMessage} from './modal';

export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const FETCH_USER_SUCCEEDED = 'FETCH_USER_SUCCEEDED';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export const UPDATE_USER_SENT = 'UPDATE_USER_SENT';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPLOAD_AVATAR_SENT = 'UPLOAD_AVATAR_SENT';
export const UPLOAD_AVATAR_SUCCEEDED = 'UPLOAD_AVATAR_SUCCEEDED';
export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED';
export const DELETE_AVATAR_SENT = 'DELETE_AVATAR_SENT';
export const DELETE_AVATAR_SUCCEEDED = 'DELETE_AVATAR_SUCCEEDED';
export const DELETE_AVATAR_FAILED = 'DELETE_AVATAR_FAILED';

export function fetchSucceeded(data, opts) {
  return {
    type: FETCH_USERS_SUCCEEDED,
    payload: Object.assign({}, data, {opts})
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_USERS_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetchOneSucceeded(data) {
  return {
    type: FETCH_USER_SUCCEEDED,
    payload: data
  }
}

export function fetchOneFailed(error) {
  return {
    type: FETCH_USER_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function updateSent(data) {
  return {
    type: UPDATE_USER_SENT
  }
}

export function updateSucceeded(data) {
  return {
    type: UPDATE_USER_SUCCEEDED,
    payload: data
  }
}

export function updateFailed(error) {
  return {
    type: UPDATE_USER_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function uploadAvatarSent(data) {
  return {
    type: UPLOAD_AVATAR_SENT
  }
}

export function uploadAvatarSucceeded(data) {
  return {
    type: UPLOAD_AVATAR_SUCCEEDED,
    payload: data
  }
}

export function uploadAvatarFailed(error) {
  return {
    type: UPLOAD_AVATAR_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function deleteAvatarSent(data) {
  return {
    type: DELETE_AVATAR_SENT
  }
}

export function deleteAvatarSucceeded(data) {
  return {
    type: DELETE_AVATAR_SUCCEEDED,
    payload: data
  }
}

export function deleteAvatarFailed(error) {
  return {
    type: DELETE_AVATAR_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetch(opts) {
  return (dispatch) => {
    return UserApiUtils.fetch(opts).then(
      (data) => dispatch(fetchSucceeded(data, opts)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}

export function fetchOne(userId) {
  return (dispatch) => {
    return UserApiUtils.fetchOne(userId).then(
      (data) => dispatch(fetchOneSucceeded(data)),
      (error) => dispatch(fetchOneFailed(error))
    );
  };
}

export function update(userId, params) {
  return (dispatch) => {
    dispatch(updateSent());
    return UserApiUtils.update(userId, params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully updated", {autoDisappear: true, sticky: true}));
        dispatch(updateSucceeded(data));
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(updateFailed(error))
      }
    );
  };
}

export function uploadAvatar(userId, file) {
  return (dispatch) => {
    dispatch(uploadAvatarSent());
    return UserApiUtils.uploadAvatar(userId, file).then(
      (data) => {
        dispatch(addFlashMessage("Successfully uploaded", {autoDisappear: true, sticky: true}));
        dispatch(uploadAvatarSucceeded(data));
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error"}));
        dispatch(uploadAvatarFailed(error));
      }
    );
  };
}

export function deleteAvatar(userId) {
  return (dispatch) => {
    dispatch(deleteAvatarSent());
    return UserApiUtils.deleteAvatar(userId).then(
      (data) => {
        dispatch(addFlashMessage("Successfully deleted", {autoDisappear: true, sticky: true}));
        dispatch(deleteAvatarSucceeded(data));
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(deleteAvatarFailed(error));
      }
    );
  };
}
