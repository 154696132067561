import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as ModalActions from '../../actions/modal';
import Collection from '../../utils/Collection';
import moment from 'moment-timezone';
import _ from 'lodash';
import classnames from 'classnames';
import Immutable from 'immutable';
import SimpleLayer from '../../components/SimpleLayer';
import ResolveIssueModalContainer from './ResolveIssue';
import UpdateResolvedIssueModalContainer from './UpdateResolvedIssue';
import CancelBookingModalContainer from './CancelBooking';
// import RevokeResolvedIssueModalContent from './RevokeResolvedIssue';
// import RevokeReportedIssueModalContent from './RevokeReportedIssue¥';
import ReportAndResolveIssueModalContainer from './ReportAndResolveIssue';
import AddShiftSetModalContainer from './AddShiftSet';
import EditShiftSetModalContainer from './EditShiftSet';

function mapStateToProps(state) {
  const { modal } = state
  const { 
    isOpen,
    content,
    modalProps,
    message,
    messageType,
  } = modal;
  return {
    isOpen,
    content,
    modalProps,
    message,
    messageType,
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    modalActions: bindActionCreators(ModalActions, dispatch),
  };
}

class ModalContainer extends React.Component {

  constructor(props) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.addModalMessage = this.addModalMessage.bind(this);
    this.clearModalMessage = this.clearModalMessage.bind(this);
    this.transitModal = this.transitModal.bind(this);
    this.hideModalPromise = this.hideModalPromise.bind(this);
  }

  showModal(content, props={}) {
    const {modalActions} = this.props;
    modalActions.show(content, props);
  }

  hideModal() {
    this.props.modalActions.hide();
  }

  addModalMessage(message, opts={}) {
    this.props.modalActions.addMessage(message, opts);
  }

  clearModalMessage() {
    this.props.modalActions.clearMessage();
  }

  transitModal(nextContent, props={}) {
    this.hideModalPromise().then(() => {
      this.showModal(nextContent, props);
    })
  }

  hideModalPromise() {
    return new Promise((resolve, reject) => {
      this.hideModal();
      window.setTimeout(() => {
        resolve();
      }, 300)
    });
  }

  render() {
    let modalContent;
    const {
      content,
      modalProps, 
      isOpen,
      message,
      messageType,
    } = this.props;
    const baseModalProps = {
      addModalMessage: this.addModalMessage,
      clearModalMessage: this.clearModalMessage,
      handleHideModal: this.hideModal,
      transitModal: this.transitModal,
      message,
      messageType,
    };
    const combinedModalProps = Object.assign(baseModalProps, {...modalProps})
    let transitionName = "modal-anim";
    switch (content) {
      case 'resolveIssue':
        modalContent = <ResolveIssueModalContainer {...combinedModalProps} />;
        break;
      // case 'revokeReportedIssue':
      //   modalContent = <RevokeReportedIssueModalContent {...modalProps} />;
      //   break;
      // case 'revokeResolvedIssue':
      //   modalContent = <RevokeResolvedIssueModalContent {...modalProps} />;
      //   break;
      case 'reportAndResolveIssue':
        modalContent = <ReportAndResolveIssueModalContainer {...combinedModalProps} />;
        break;
      case 'addShiftSet': 
        modalContent = <AddShiftSetModalContainer {...combinedModalProps} />;
        break;
      case 'editShiftSet':
        modalContent = <EditShiftSetModalContainer {...combinedModalProps} />;
        break;
      case 'updateResolvedIssue':
        modalContent = <UpdateResolvedIssueModalContainer {...combinedModalProps} />;
        break;
      case 'cancelBooking':
        modalContent = <CancelBookingModalContainer {...combinedModalProps} />;
        break;
      default:
        modalContent = <div/>;
        break;
    }
    let simpleLayer = !modalProps.outerClickDisabled ? <SimpleLayer onClick={this.hideModal}/> : null
    if (isOpen) {
      return (
        <ReactCSSTransitionGroup transitionAppear={true}　transitionAppearTimeout={500} transitionName={transitionName} transitionEnterTimeout={500} transitionLeaveTimeout={300}>
          <div className="modal show" >
            <div className="modal-table">
              <div className="modal-cell">
                {simpleLayer}
                {modalContent}
              </div>
            </div>
          </div>
        </ReactCSSTransitionGroup>
      ); 
    } else {
      return <ReactCSSTransitionGroup transitionAppear={true}　transitionAppearTimeout={500} transitionName={transitionName} transitionEnterTimeout={500} transitionLeaveTimeout={300} />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)