import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer id="footer" className="lb-footer sm-center">
        <div className="container-fluid">
          <hr className="footer-divider space-top-8 space-4 hide-sm" />
          <div className="text-center">
            <div className="space-top-2 text-muted">
              © LingualBox, Inc.
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
