import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import Select from '../../form/Select.js';
import TextInput from '../../form/TextInput.js';

class LessonFilter extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getStatusOptions = this.getStatusOptions.bind(this);
    this.getSortOptions = this.getSortOptions.bind(this);
    this.getPageOptions = this.getPageOptions.bind(this);
    this.getTrialOptions = this.getTrialOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFocusOnDurationChange = this.onFocusOnDurationChange.bind(this);
    this.state = {
      focusedOnRangePicker: null,
    };
  }

  onFocusOnDurationChange(focused) {
    this.setState({
      focusedOnRangePicker: focused,
    });
  }

  getOptions(keyBase, baseOpts) {
    let options = [];
    baseOpts.map((val, index) => {
      options.push({ key: keyBase + index, name: val, value: index });
    });
    return options;
  }

  getPageOptions() {
    const count = this.props.count;
    const raw_pages = count / 100;
    if (count === 0 || raw_pages <= 1) {
      return [{ key: 'user-page-1', name: 'page1', value: 1 }]
    }
    const floored = Math.floor(raw_pages);
    const totalPages = raw_pages === floored > 0 ? floored + 1 : raw_pages;
    let options = [];
    for (let i = 1; i < totalPages; i++) {
      options.push({ key: 'lessons-page-' + i, name: 'page' + i, value: i });
    }
    return options;
  }

  getStatusOptions() {
    // 1. Booked 2. Student Cancelled 3. Tutor Cancelled 4. Live 5. Issue Reported 6. Completed
    const base = [
      'Choose a Status',
      '1. Booked',
      '2. Unscheduled by student',
      '3. Unscheduled by tutor',
      '4. Live',
      '5. Issue Reported',
      '6. Completed',
      '7. Pending',
    ]
    return this.getOptions('lesson-status-', base)
  }

  getSortOptions() {
    const base = [
      'Sort by created at',
      'Sort by start at'
    ]
    return this.getOptions('lesson-sort-', base)
  }

  getTrialOptions() {
    const base = [
      'Trial and Non-trial',
      'Trial',
      'Non-trial'
    ]
    return this.getOptions('lesson-trial-status-', base)
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    const {
      focusedOnRangePicker,
    } = this.state;
    const {
      changeAdded,
      updateSortOption,
      chosenSortOption,
      updateChosenStatus,
      chosenStatus,
      updateTrialOption,
      chosenTrialOption,
      updatePage,
      page,
      studentEmail,
      tutorEmail,
      updateStudentEmail,
      updateTutorEmail,
      durationEnd,
      durationStart,
      onUpdateDuration,
    } = this.props;

    const sortProps = {
      options: this.getSortOptions(),
      updateHandler: updateSortOption,
      value: chosenSortOption,
    };
    const statusFilterProps = {
      options: this.getStatusOptions(),
      updateHandler: updateChosenStatus,
      value: chosenStatus,
    };
    const searchByStudentEmailProps = {
      placeholder: 'Student Email',
      className: '',
      value: studentEmail,
      name: 'email',
      updateHandler: updateStudentEmail,
      onKeyDown: this.handleSubmit.bind(this),
    };
    const searchByTutorEmailProps = {
      placeholder: 'Tutor Email',
      className: '',
      value: tutorEmail,
      name: 'email',
      updateHandler: updateTutorEmail,
      onKeyDown: this.handleSubmit.bind(this),
    };
    const pageProps = {
      options: this.getPageOptions(),
      updateHandler: updatePage,
      value: page
    }
    const trialProps = {
      options: this.getTrialOptions(),
      updateHandler: updateTrialOption,
      value: chosenTrialOption
    }
    return (
      <div className="data-filter">
        <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
          <li className="row-space-2">
            <Select {...sortProps} />
          </li>
          <li className="row-space-2">
            <Select {...statusFilterProps} />
          </li>
          <li className="row-space-2">
            <Select {...trialProps} />
          </li>
          <li className="row-space-2">
            <TextInput {...searchByStudentEmailProps} />
          </li>
          <li className="row-space-2">
            <TextInput {...searchByTutorEmailProps} />
          </li>
          <li className="row-space-2">
            <DateRangePicker
              startDatePlaceholderText={"Lesson Date - Start"}
              endDatePlaceholderText={"Lesson Date - End"}
              enableOutsideDays={true}
              withPortal={true}
              startDate={durationStart}
              endDate={durationEnd}
              numberOfMonths={1}
              focusedInput={focusedOnRangePicker}
              isOutsideRange={() => false}
              onDatesChange={(dates) => { onUpdateDuration(dates) }}
              onFocusChange={( focused ) => { this.onFocusOnDurationChange(focused) }}
            />
          </li>
          <li className="row-space-2">
            <Select {...pageProps} />
          </li>
          <li className="row-space-2">
            <button onClick={this.handleSubmit} className="btn btn-success" disabled={!changeAdded}>Search</button>
          </li>
        </ul>
      </div>
    );
  }
}

LessonFilter.propTypes = {
  handleSubmit: PropTypes.func,
  updateSortOption: PropTypes.func,
  chosenSortOption: PropTypes.number,
  updateChosenStatus: PropTypes.func,
  chosenStatus: PropTypes.number,
  email: PropTypes.string,
  updateEmail: PropTypes.func,
  page: PropTypes.number,
  updatePage: PropTypes.func,
  count: PropTypes.number,
};

export default LessonFilter;
