import Collection from '../utils/Collection.js';

import {
  FETCH_MATERIALS_SUCCEEDED,
  FETCH_MATERIALS_FAILED,
  FETCH_MATERIAL_SUCCEEDED,
  FETCH_MATERIAL_FAILED,
  UPDATE_MATERIAL_SENT,
  UPDATE_MATERIAL_SUCCEEDED,
  UPDATE_MATERIAL_FAILED,
  UPLOAD_MATERIAL_FILE_SENT,
  UPLOAD_MATERIAL_FILE_SUCCEEDED,
  UPLOAD_MATERIAL_FILE_FAILED,
  DELETE_MATERIAL_FILE_SENT,
  DELETE_MATERIAL_FILE_SUCCEEDED,
  DELETE_MATERIAL_FILE_FAILED
} from '../actions/material';

const initialState = {
  updating: false,
  processingFile: false,
  materials: [],
  count: 0,
  lastSearchOpts: {
    page: 1,
    sortOption: 0,
    status: 0,
    difficulty: 0
  }
}

export default function materialReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_MATERIALS_SUCCEEDED:
      return {
        ...state, 
        materials: Collection.reset(state.materials, payload.items),
        count: payload.count,
        opts: payload.opts
      };
    case UPDATE_MATERIAL_SUCCEEDED:
    case UPLOAD_MATERIAL_FILE_SUCCEEDED:
    case DELETE_MATERIAL_FILE_SUCCEEDED:
      return {
        ...state, 
        materials: Collection.update(state.materials, payload.material.id, payload.material),
        processingFile: false,
        updating: false
      }
    case UPDATE_MATERIAL_SENT:
      return {
        ...state, 
        updating: true
      }
    case UPLOAD_MATERIAL_FILE_SENT:
    case DELETE_MATERIAL_FILE_SENT:
      return {
        ...state, 
        processingFile: true
      }
    default:
      return state;
  }
}