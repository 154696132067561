import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import LinkCell from '../LinkCell';
import ActionCell from './lessonActionCell';

class LessonsTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  render() {
    const lessons = this.props.lessons;
    const screenWidth = this.state.width
    const width = screenWidth >= 1100 ? screenWidth * 0.833 - 25 : screenWidth - 25;
    const height = 50 + lessons.length * 50 > 500 ? 500 : 50 + lessons.length * 50;
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={lessons.length}
        headerHeight={50}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={
            <LinkCell
              tableName='lessons'
              data={lessons}
              field="id"
            />
          }
          width={50}
        />
        <Column
          header={<Cell>Status</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].status_id}. {lessons[props.rowIndex].status}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Actions</Cell>}
          cell={
            <ActionCell
              tableName='lessons'
              data={lessons}
            />
          }
          width={150}
        />
        <Column
          header={<Cell>Date</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(lessons[props.rowIndex].start_at).format('YYYY-MM-DD')}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Time</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(lessons[props.rowIndex].start_at).format('HH:mm')}-{moment(lessons[props.rowIndex].end_at).format('HH:mm')} UTC{moment(lessons[props.rowIndex].start_at).format('ZZ')}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Attendance</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].attendance === true ? "True" : "False"}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Trial</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].with_trial === true ? "True" : "False"}
            </Cell>
          )}
          width={80}
        />
        <Column
          header={<Cell>Student</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].student_name} ({lessons[props.rowIndex].student_id})
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Tutor</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].tutor_name} ({lessons[props.rowIndex].tutor_id})
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Consumed Tickets</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].consumed_tickets ?
                lessons[props.rowIndex].consumed_tickets : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Consumed Subtickets</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].consumed_sub_tickets ?
                lessons[props.rowIndex].consumed_sub_tickets : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Returned Tickets</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].returned_tickets ?
                lessons[props.rowIndex].returned_tickets : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Returned Subtickets</Cell>}
          cell={props => (
            <Cell {...props}>
              {lessons[props.rowIndex].returned_subtickets ?
                lessons[props.rowIndex].returned_tickets : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>CREATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(lessons[props.rowIndex].created_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
        <Column
          header={<Cell>UPDATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(lessons[props.rowIndex].updated_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
      </Table>
    );
  }
}

LessonsTable.propTypes = {
  lessons: PropTypes.array,
};

export default LessonsTable;
