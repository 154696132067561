import BaseValidator from './BaseValidator.js';

class BankInfoValidator extends BaseValidator {
  constructor(opts={}) {
    super();
    this.validationErrors = [];
    this.validity = null;
    this.bankName = opts.bankName;
    this.branchName = opts.branchName;
    this.branchNumber = opts.branchNumber;
    this.nameOnAccount = opts.nameOnAccount;
    this.accountNumber = opts.accountNumber;
  }

  validate() {
    const validityCheck = Promise.all([
      this.validateBankName(),
      this.validateBranchName(),
      this.validateBranchNumber(),
      this.validateNameOnAccount(),
      this.validateAccountNumber()
    ]).then((responses) => {
      if (this.validationErrors.length === 0) {
        return Promise.resolve({valid: true});
      } else {
        return Promise.resolve({valid: false, errors: this.validationErrors});
      }
    })
    return validityCheck
  }

  dealValidationResult(type, res) {
    if (res.success) {
      return res;
    } else {
      this.validationErrors.push(type + res.err);
      return res;
    }
  }

  validateBankName() {
    if (this.bankName === null) {
      return true;
    }
    return super.cannotEmpty(this.bankName)
    .then((res) => { 
      this.dealValidationResult("Bank Info", res);
    });
  }

  validateBranchName() {
    if (this.branchName === null) {
      return true;
    }
    return super.cannotEmpty(this.branchName)
    .then((res) => { 
      this.dealValidationResult("Branch Name", res);
    });
  }

  validateBranchNumber() {
    if (this.branchNumber === null) {
      return true;
    }
    return super.cannotEmpty(this.branchNumber)
    .then((res) => { 
      this.dealValidationResult("Branch Number", res);
    });
  }

  validateNameOnAccount() {
    if (this.nameOnAccount === null) {
      return true;
    }
    return super.cannotEmpty(this.nameOnAccount)
    .then((res) => { 
      this.dealValidationResult("Name on Account", res);
    });
  }

  validateAccountNumber() {
    if (this.nameOnAccount === null) {
      return true;
    }
    return super.cannotEmpty(this.accountNumber)
    .then((res) => { 
      this.dealValidationResult("Account Number", res);
    });
  }

}

export default BankInfoValidator;