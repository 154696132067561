import Collection from '../utils/Collection';

import {
  FETCH_TUTORS_SUCCEEDED,
  UPDATE_TUTOR_SUCCEEDED,
  UPLOAD_TUTOR_AVATAR_SUCCEEDED,
  DELETE_TUTOR_AVATAR_SUCCEEDED,
} from '../actions/tutor';


const initialState = {
  tutors: [],
  count: 0,
  lastSearchOpts: {
    page: 1,
    nameOrEmail: '',
    sortOption: 0,
  },
};

export default function tutorReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_TUTORS_SUCCEEDED:
      return {
        ...state,
        tutors: Collection.reset(state.tutors, payload.tutors),
        count: payload.count,
      };
    case UPDATE_TUTOR_SUCCEEDED:
    case UPLOAD_TUTOR_AVATAR_SUCCEEDED:
    case DELETE_TUTOR_AVATAR_SUCCEEDED:
      return {
        ...state,
        tutors: Collection.update(state.tutors, payload.user.id, payload.user),
      };
    default:
      return state;
  }
}
