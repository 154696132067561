import _ from 'lodash';

const checkResults = (results) => {
  if (_.isEmpty(results)) {
    return Promise.reject();
  }
  return Promise.resolve(results);
};

export default checkResults;
