import React, { Component } from 'react';
import { HRRoutes } from '../../routes';

class AdminHrHome extends Component {
  render() {
    return (
      <div id="admin-content">
        <HRRoutes />
      </div>
    );
  }
}

export default AdminHrHome;
