import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from '../../form/Select';
import TextInput from '../../form/TextInput';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class TutorFilter extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getPageOptions = this.getPageOptions.bind(this);
    this.getSortOptions = this.getSortOptions.bind(this);
    this.getStatusOptions = this.getStatusOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getOptions(keyBase, baseOpts) {
    let options = [];
    baseOpts.map((val, index) => {
      options.push({ key: keyBase + index, name: val, value: index });
    });
    return options;
  }

  getPageOptions() {
    const count = this.props.count;
    const raw_pages = count / 100;
    if (count === 0 || raw_pages <= 1) {
      return [{ key: 'tutor-page-1', name: 'page1', value: 1 }]
    }
    const floored = Math.floor(raw_pages);
    const totalPages = raw_pages === floored > 0 ? floored + 1 : raw_pages;
    let options = [];
    for (let i = 1; i < totalPages; i++) {
      options.push({ key: 'tutor-page-' + i, name: 'page' + i, value: i });
    }
    return options;
  }

  getStatusOptions() {
    let options = [];
    const base = ["active", "inactive", "any"];
    const keyBase = 'tutor-status-';
    base.map((val, index) => {
      options.push({ key: keyBase + index, name: _.capitalize(val), value: val });
    });
    return options;
  }

  getSortOptions() {
    const base = ['Sort by created at', 'Taught'];
    return this.getOptions('tutor-sort-', base);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    const {
      count,
      page,
      changeAdded,
      chosenStatus,
      chosenSortOption,
      nameOrEmail,
      handleUpdatePage,
      handleUpdateSortOption,
      handleUpdateStatus,
      handleUpdateNameOrEmail,
      handleSubmit
    } = this.props

    const sortProps = {
      options: this.getSortOptions(),
      updateHandler: handleUpdateSortOption,
      value: chosenSortOption,
    };
    const pageProps = {
      options: this.getPageOptions(),
      updateHandler: handleUpdatePage,
      value: page
    };
    const statusProps = {
      options: this.getStatusOptions(),
      updateHandler: handleUpdateStatus,
      value: chosenStatus,
    };
    const nameOrEmailProps = {
      placeholder: 'Name or Email',
      className: '',
      value: nameOrEmail,
      name: 'name-or-email',
      updateHandler: handleUpdateNameOrEmail,
      onKeyDown: this.handleSubmit,
    };

    return (
      <div className="data-filter">
        <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
          <li className="row-space-2">
            <Select {...sortProps} />
          </li>
          <li className="row-space-2">
            <Select {...statusProps} />
          </li>
          <li className="row-space-2">
            <TextInput {...nameOrEmailProps} />
          </li>
          <li className="row-space-2">
            <Select {...pageProps} />
          </li>
          <li className="row-space-2">
            <button onClick={this.handleSubmit} className="btn btn-success" disabled={!changeAdded}>Search</button>
          </li>
          <li className="row-space-2">
            <Link to="/a/hr/tutors/create">
              <button className="btn btn-purple btn--square">
                <i className="fa fa-plus"/>
              </button>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

TutorFilter.propTypes = {
  changeAdded: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleUpdateSortOption: PropTypes.func,
  handleUpdatePage: PropTypes.func,
  handleUpdateNameOrEmail: PropTypes.func,
  chosenSortOption: PropTypes.number,
  nameOrEmail: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number
};

export default TutorFilter;