import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Collection from '../../utils/Collection';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import classnames from 'classnames';
import * as ShiftSetActions from '../../actions/shiftSet';
import ModalMessage from '../../components/modals/ModalMessage';
import RadioInputs from '../../components/form/RadioInputs';

function mapStateToProps(state) {
  const { shiftSet } = state
  const {
    saving,
    shiftSetIdToEdit,
    durationEndOfChosenSet
  } = shiftSet;
  return {
    saving,
    setIdToEdit: shiftSetIdToEdit,
    durationEndOfChosenSet
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    shiftSetActions: bindActionCreators(ShiftSetActions, dispatch),
  };
}

class EditShiftSetModalContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      focusedOnDurationEndOfChosenSet: false
    };
    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getShiftSetOptions = this.getShiftSetOptions.bind(this);
    this.onUpdateSetIdToEdit = this.onUpdateSetIdToEdit.bind(this);
  }
  
  getShiftSetOptions() {
    const {shiftSets} = this.props;
    const options = shiftSets.map((ss) => {
      const duration_start = moment(ss.duration_start).tz("Asia/Manila").format("YYYY-MM-DD")
      const duration_end = moment(ss.duration_end).tz("Asia/Manila").format("YYYY-MM-DD")
      return {content: "Shift Set: " + duration_start + " - " + duration_end, id: ss.id, value: ss.id.toString()}
    });
    return options;
  }

  onUpdateSetIdToEdit(value) {
    const {shiftSetActions} = this.props;
    shiftSetActions.updateSetIdToEdit(value)
  }

  onUpdateDurationEndOfChosenSet(date) {
    const {shiftSetActions} = this.props;
    shiftSetActions.updateDurationEndOfChosenSet(date);
  }

  hideModal(e) {
    e.preventDefault();
    this.props.handleHideModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    const {shiftSetActions, setIdToEdit, durationEndOfChosenSet} = this.props;
    const {tutorId} = this.props;
    shiftSetActions.setEndDate(tutorId, setIdToEdit, durationEndOfChosenSet);
  }

  render() {
    const {
      message,
      messageType,
      saving,
      setIdToEdit,
      durationEndOfChosenSet,
    } = this.props;
    const {
      focusedOnDurationEndOfChosenSet,
    } = this.state;
    const shiftSetOptions = this.getShiftSetOptions();
    const setIdToEditProps = {
      options: shiftSetOptions,
      className: '',
      id: 'select-issue-type',
      value: setIdToEdit ? setIdToEdit.toString() : null,
      name: "set-to-duplicate-select",
      updateHandler: this.onUpdateSetIdToEdit,
      invalidLabel: true,
      onKeyDown: this.handleSubmit
    }
    return (
      <div className="modal-content">
        <div className="panel panel-no-border">
          <div className="panel-header">
            <span>Edit Shift Set</span>
            <a className="modal-close" onClick={this.hideModal}>
              <span>☓</span>
            </a>
          </div>
          <ModalMessage message={message} messageType={messageType}/>
          <form
            method="put"
            className="report-and-resolve-issue-form"
            onSubmit={this.handleSubmit}
            noValidate>
            <div className="panel-body">
              <div className="issue-type-wrapper row-space-2">
                <h5>Choose a shift set to edit</h5>
                <RadioInputs {...setIdToEditProps} />
              </div>
              <div className="row-space-2 date-picker-relative">
                <h5>Set the End date</h5>
                <SingleDatePicker
                  id="duration_end_of_set_input"
                  date={durationEndOfChosenSet}
                  numberOfMonths={1}
                  focused={focusedOnDurationEndOfChosenSet}
                  onDateChange={(date) => { this.onUpdateDurationEndOfChosenSet(date)}}
                  onFocusChange={({ focused }) => { this.setState({ focusedOnDurationEndOfChosenSet: focused }); }}
                />
                <hr/>
              </div>
            </div>
            <div className="panel-footer">
              <div className="text-right">
                <button 
                  className="btn btn-primary btn-large" 
                  disabled={saving}>Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


EditShiftSetModalContainer.propTypes = {
  shiftSets: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(EditShiftSetModalContainer)
