import React, {Component} from 'react';
import TextInput from '../../../form/TextInput';
import Select from '../../../form/Select.js';
import FileInput from '../../../form/FileInput';
import MaterialValidator from '../../../../validators/MaterialValidator';

class MaterialDetailsBase extends Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.onUpdateCourse = this.onUpdateCourse.bind(this);
    this.getCourseOptions = this.getCourseOptions.bind(this);
    this.getUnitOptions = this.getUnitOptions.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    const {material} = props;
    const {course_id, unit_id, number, title} = material;
    this.state = {
      tempCourseId: course_id || null,
      tempUnitId: unit_id || null,
      tempNumber: number || null,
      tempTitle: title || "",
      changeAdded: false
    };
  }

  getCourseOptions() {
    const { courses } = this.props;
    let options = [
      {name: "Select a Course", value: 0, key: "course-select-0"}
    ];
    if (courses.length > 0) {
      for (let course of courses) {
        options.push({name: course.name, value: course.id, key: "course-select-" + course.id.toString()});
      }
    }
    return options;
  }

  getUnitOptions() {
    const { units } = this.props;
    let options = [
      {name: "Select a Unit", value: 0, key: "course-select-0"}
    ];
    if (units.length > 0) {
      for (let unit of units) {
        options.push({name: unit.theme, value: unit.id, key: "unit-select-" + unit.id.toString()});
      }
    }
    return options;
  }

  onUpdateCourse(value) {
    let newState = Object.assign(this.state, {
      changeAdded: true, 
      tempCourseId: value, 
      units: [],
      tempUnitId: 0});
    this.setState(newState);
    this.props.fetchCourseUnits(value);
  }

  onUpdateInput(value, stateType) {
    let state = this.state;
    state[stateType] = value;
    state.changeAdded = true;
    this.setState(state);
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      changeAdded,
      tempCourseId,
      tempUnitId,
      tempNumber,
      tempTitle
    } = this.state;
    const params = {
      changeAdded,
      tempCourseId,
      tempUnitId,
      tempNumber,
      tempTitle
    };
    if (!this.state.changeAdded) {
      return false;
    }
    let materialValidator = new MaterialValidator({
      courseId: tempCourseId,
      unitId: tempUnitId,
      number: tempNumber,
      title: tempTitle,
    });
    materialValidator.validate()
    .then((result) => {
      // if (result.valid) {
      //   materialActions.update(this.props.material.id, params);
      // } else {
      //   let message = '';
      //   for (let err of result.errors) {
      //     message += err + " ";
      //   }
      // }
    });
  }

  uploadFile(newFile) {
    const {material, uploadFile} = this.props;
    const materialId = material.id
    uploadFile(materialId, newFile);

  }

  deleteFile() {
    const {material, deleteFile} = this.props;
    const materialId = material.id
    deleteFile(materialId);
  }

  render() {
    const {
      material,
      updating,
      processingFile,
    } = this.props;
    const { 
      id,
      course_id,
      unit_id,
      number,
      title,
      file_url } = material
    const {
      tempCourseId,
      tempUnitId,
      tempNumber,
      tempTitle } = this.state;
    const courseProps = {
      options: this.getCourseOptions(),
      className: 'edit-material',
      id: 'course-select',
      stateType: 'tempCourseId',
      value: tempCourseId !== null ? tempCourseId : (course_id || ''),
      updateHandler: this.onUpdateCourse,
      onKeyDown: this.onSubmit,
    };
    const unitProps = {
      options: this.getUnitOptions(),
      className: 'edit-material',
      id: 'unit-select',
      stateType: 'tempUnitId',
      value: tempUnitId !== null ? tempUnitId : (unit_id || ''),
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const numberProps = {
      className: 'edit-material',
      value: tempNumber !== null ? tempNumber : (number.toString() || ''),
      name: 'number',
      stateType: 'tempNumber',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const titleProps = {
      className: 'edit-material',
      value: tempTitle !== null ? tempTitle : (title || ''),
      name: 'title',
      stateType: 'tempTitle',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    return (
      <div className="panel panel-no-border">
        <form method="put" onSubmit={this.onSubmit}>
          <div className="panel-body panel-body-no-border">
            <h4 className="form-title">Edit Material</h4>
            <div className="form-body">
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  File
                </label>
                <div className="col-sm-9">
                  <FileInput
                    currentFile={file_url}
                    uploadFile={this.uploadFile}
                    deleteFile={this.deleteFile}
                    allowedExtensions={['pdf']}
                    processing={processingFile}/>
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Course
                </label>
                <div className="col-sm-9">
                  <Select {...courseProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Unit
                </label>
                <div className="col-sm-9">
                  <Select {...unitProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Number
                </label>
                <div className="col-sm-9">
                  <TextInput {...numberProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Title
                </label>
                <div className="col-sm-9">
                  <TextInput {...titleProps} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="text-right">
              <button
                className="btn btn-primary btn-large"
                type="submit"
                disabled={!this.state.changeAdded || updating}
              >Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default MaterialDetailsBase;