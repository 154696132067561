import {
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  FETCH_CURRENT_USER_SUCCEEDED,
  FETCH_CURRENT_USER_FAILED
} from '../actions/auth';

const initialState = {
  isLoggedIn: localStorage.getItem('isLoggedIn') || null,
  authToken: localStorage.getItem('authToken') || null,
  user: JSON.parse(localStorage.getItem('user')) || null
}

export default function authReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        isLoggedIn: true,
        authToken: payload.authToken,
        user: payload.user
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        authToken: null,
        user: null
      };
    case FETCH_CURRENT_USER_SUCCEEDED:
      return {
        ...state,
        user: payload.user
      }
    default:
      return state;
  }
}