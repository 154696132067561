import Collection from '../utils/Collection.js';

import {
  FETCH_TIME_ZONES_SUCCEEDED,
  FETCH_TIME_ZONES_FAILED
  ,
} from '../actions/timeZone';

const initialState = {
  timeZones: []
}

export default function timeZoneReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_TIME_ZONES_SUCCEEDED:
      return {
        ...state, 
        timeZones: payload
      };
    default:
      return state;
  }
}