import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../form/TextInput';
import Select from '../../../form/Select';
import Textarea from '../../../form/Textarea';
import ProfilePhotoInput from '../../../form/ProfilePhotoInput';
import RadioInputs from '../../../form/RadioInputs';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import {START_DATE, END_DATE} from 'react-dates/constants';
import _ from 'lodash';

class TutorEditorHome extends Component {

  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getStatusOptions = this.getStatusOptions.bind(this);
    this.onFocusOnDurationChange = this.onFocusOnDurationChange.bind(this);
    this.state = {
      focusedOnRangePicker: null,
    };
  }

  onFocusOnDurationChange(focused) {
    this.setState({
      focusedOnRangePicker: focused,
    });
  }

  getOptions(keyBase, baseOpts, useIndex=true) {
    let options = [];
    baseOpts.map((val, index) => {
      if (useIndex || index === 0) {
        options.push({ key: keyBase + index, name: val, value: index });
      } else {
        options.push({ key: keyBase + index, name: val, value: val });
      }
    });
    return options;
  }
  
  getStatusOptions() {
    const base = ["active", "inactive"]
    const options = base.map((val, index) => {
      return {
        content: _.capitalize(val), id: `tutor-status-${val}`, value: val
      }
    });
    return options;
  }

  render() {
    const {
      focusedOnRangePicker,
    } = this.state;
    const { 
      id,
      first_name, 
      last_name,
      nickname,
      skype_name,
      email,
      mobile_number,
      bio,
      teaching_experience,
      large_avatar_url,
      status,
      start_working,
      end_working,
      video_url,
      gender,
      base_rate,
      experience_years
    } = this.props.tutor;
    const { 
      updating,
      changeAdded,
      onSubmit,
      onUpdateInput,
      avatarProcessing,
      tempFirstName, 
      tempLastName,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempEmail,
      tempBio,
      tempTeachingExperience,
      tempActiveStatus,
      tempStartWorking,
      tempEndWorking,
      tempVideoUrl,
      tempBaseRate,
      tempGender,
      uploadAvatar,
      deleteAvatar,
      onUpdateDuration,
      tempExperienceYears,
    } = this.props;
    const firstNameProps = {
      className: 'first-name',
      value: tempFirstName !== null ? tempFirstName : (first_name || ''),
      name: 'first-name',
      stateType: 'tempFirstName',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const lastNameProps = {
      className: 'last-name',
      value: tempLastName !== null ? tempLastName : (last_name || ''),
      name: 'last-name',
      stateType: 'tempLastName',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const nicknameProps = {
      className: 'nickname',
      value: tempNickname !== null ? tempNickname : (nickname || ''),
      name: 'nickname',
      stateType: 'tempNickname',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const skypeIdProps = {
      className: 'skype-id',
      value: tempSkypeId !== null ? tempSkypeId : (skype_name || ''),
      name: 'skypeId',
      stateType: 'tempSkypeId',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const videoUrlProps = {
      className: 'video-url',
      value: tempVideoUrl !== null ? tempVideoUrl : (video_url || ''),
      name: 'videoUrl',
      stateType: 'tempVideoUrl',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const mobileNumberProps = {
      className: 'mobile-number',
      value: tempMobileNumber !== null ? tempMobileNumber : (mobile_number || ''),
      name: 'mobileNumber',
      stateType: 'tempMobileNumber',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    }
    const emailProps = {
      className: 'email',
      value: tempEmail !== null ? tempEmail : (email || ''),
      name: 'email',
      type: 'email',
      stateType: 'tempEmail',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const experienceYearsProps = {
      className: 'experience_years',
      value: tempExperienceYears !== null ? tempExperienceYears : experience_years,
      name: 'exprienceYears',
      type: 'text',
      stateType: 'tempExperienceYears',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const activeStatusProps = {
      options: this.getStatusOptions(),
      className: 'active-status',
      value: tempActiveStatus !== null ? tempActiveStatus : status,
      name: 'status',
      type: 'status',
      stateType: 'tempActiveStatus',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    }
    const baseRateProps = {
      className: 'base_rate',
      value: tempBaseRate !== null ? tempBaseRate : base_rate,
      name: 'baseRate',
      type: 'text',
      stateType: 'tempBaseRate',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const genders = ["Choose the gender", "Male", "Female"]
    let genderNum = "0"
    if (gender === "Female") {
      genderNum = "2"
    } else if (gender === "Male") {
      genderNum = "1"
    }
    const genderProps = {
      options: this.getOptions("gender", genders),
      updateHandler: onUpdateInput,
      stateType: 'tempGender',
      value: tempGender !== null ? tempGender : (genderNum || '0'),
    };
    const bioProps = {
      placeholder: '',
      className: 'bio',
      value: tempBio !== null ? tempBio : bio,
      name: 'bio',
      stateType: 'tempBio',
      rows: 5,
      updateHandler: onUpdateInput,
    };
    const teachingExperienceProps = {
      placeholder: '',
      className: 'teaching-experience',
      value: tempTeachingExperience !== null ? tempTeachingExperience : teaching_experience,
      name: 'teaching-experience',
      stateType: 'tempTeachingExperience',
      rows: 5,
      updateHandler: onUpdateInput,
    };
    return (
      <div className="panel panel-no-border">
        <form method="put" onSubmit={onSubmit}>
          <div className="panel-body panel-body-no-border">
            <h4 className="form-title">Edit Profile</h4>
            <div className="form-body">
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Status
                </label>
                <div className="col-sm-9">
                  <RadioInputs {...activeStatusProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Avatar
                </label>
                <div className="col-sm-9">
                  <ProfilePhotoInput
                    userId={id}
                    currentAvatarUrl={large_avatar_url}
                    processing={avatarProcessing}
                    uploadAvatar={uploadAvatar}
                    deleteAvatar={deleteAvatar}/>
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Duration of Duty
                </label>
                <div className="col-sm-9">
                  <DateRangePicker
                    startDatePlaceholderText={"Start"}
                    endDatePlaceholderText={"End"}
                    enableOutsideDays={true}
                    withPortal={false}
                    startDate={tempStartWorking ? tempStartWorking : moment(start_working)}
                    endDate={tempEndWorking ? tempEndWorking : (end_working ? moment(end_working) : null)}
                    numberOfMonths={1}
                    focusedInput={focusedOnRangePicker}
                    isOutsideRange={() => false}
                    onDatesChange={(dates) => { onUpdateDuration(dates) }}
                    onFocusChange={( focused ) => { this.onFocusOnDurationChange(focused) }}
                  />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  First Name
                </label>
                <div className="col-sm-9">
                  <TextInput {...firstNameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Last Name
                </label>
                <div className="col-sm-9">
                  <TextInput {...lastNameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Gender
                </label>
                <div className="col-sm-9">
                  <Select {...genderProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Nickname
                </label>
                <div className="col-sm-9">
                  <TextInput {...nicknameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Email
                </label>
                <div className="col-sm-9">
                  <TextInput {...emailProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Skype ID
                </label>
                <div className="col-sm-9">
                  <TextInput {...skypeIdProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Video URL
                </label>
                <div className="col-sm-9">
                  <TextInput {...videoUrlProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Mobile Number
                </label>
                <div className="col-sm-9">
                  <TextInput {...mobileNumberProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Base Rate
                </label>
                <div className="col-sm-9">
                  <TextInput {...baseRateProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Experience Years
                </label>
                <div className="col-sm-9">
                  <TextInput {...experienceYearsProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Bio
                </label>
                <div className="col-sm-9">
                  <Textarea {...bioProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Teaching Experience
                </label>
                <div className="col-sm-9">
                  <Textarea {...teachingExperienceProps} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="text-right">
              <button
                className="btn btn-primary btn-large"
                type="submit"
                disabled={!changeAdded || updating}
              >Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default TutorEditorHome;