import Collection from '../utils/Collection.js';

import {
  FETCH_LESSONS_SUCCEEDED,
  FETCH_LESSONS_FAILED,
  FETCH_LESSON_SUCCEEDED,
  FETCH_LESSON_FAILED,
  CANCEL_LESSON_RESERVATION_SENT,
  CANCEL_LESSON_RESERVATION_SUCCEEDED,
  CANCEL_LESSON_RESERVATION_FAILED
} from '../actions/lesson';

const initialState = {
  lessons: [],
  saving: false,
  count: 0,
  lastSearchOpts: {
    page: 1,
    sortOption: 0,
    status: 0,
    trialOption: 0,
    studentEmail: '',
    tutorEmail: ''
  }
}

export default function lessonReducer(state = initialState, action) {
  const { payload, type, opts } = action;
  switch (type) {
    case FETCH_LESSONS_SUCCEEDED:
      return {
        ...state, 
        lessons: Collection.reset(state.lessons, payload.lessons),
        count: payload.count,
        lastSearchOpts: opts
      };
    case CANCEL_LESSON_RESERVATION_SENT: 
      return {
        ...state, 
        saving: true
      }; 
    case CANCEL_LESSON_RESERVATION_SUCCEEDED: 
      return {
        ...state, 
        lessons: Collection.update(state.lessons, payload.lesson.id, payload.lesson),
        saving: true
      };
    case CANCEL_LESSON_RESERVATION_FAILED: 
      return {
        ...state, 
        saving:false
      }; 
    
    default:
      return state;
  }
}