import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import asyncComponent from './asyncComponent';
// import App from './containers/App';
import AdminDatabaseIndex from './containers/db/index';
import AdminDatabaseDashboard from './components/db/Dashboard';
import AdminDatabaseUsersTableContainer from './containers/db/users/Table';
import AdminDatabaseUserDetailsContainer from './containers/db/users/UserDetails';
import AdminDatabaseLessonsTableContainer from './containers/db/lessons/Table';
import AdminDatabaseLessonDetailsContainer from './containers/db/lessons/LessonDetails';
import AdminDatabaseLessonIssuesTableContainer from './containers/db/issues/Table';
import AdminDatabasePaymentsTableContainer from './containers/db/payments/Table';
import AdminDatabaseCoursesTableContainer from './containers/db/courses/Table';
import AdminDatabaseMaterialsTableContainer from './containers/db/materials/Table';
import AdminDatabaseMaterialDetailsContainer from './containers/db/materials/MaterialDetails';
import AdminDatabaseHolidaysTableContainer from './containers/db/holidays/Table';
import AdminHrHome from './components/hr/Home';
import AdminHrDashboard from './components/hr/Dashboard';
import AdminHrShiftsCalendarContainer from './containers/hr/shifts/Calendar';
import AdminHrTutorsContainer from './containers/hr/tutors/Tutors';
import AdminHrTutorCreatorContainer from './containers/hr/tutor/Creator';
import AdminHrTutorAccountContainer from './containers/hr/tutor/Account';
import AdminHrTutorShiftsContainer from './containers/hr/tutor/Shifts';
import AdminHrTutorSchedulesContainer from './containers/hr/tutor/Schedules';
import AdminHrTutorBankInfoContainer from './containers/hr/tutor/BankInfo';
import AdminHrDataDashboard from './components/hr/DataDashboard';
import AdminHrDataByTutors from './components/hr/DataByTutorsContainer';
import NotFound from './components/NotFound';
import Login from './containers/Login';

function isLoggedIn() {
  if (localStorage.getItem('authToken') &&
    localStorage.getItem('user')
  ) {
    return true;
  }
  return false;
}

const AuthConstraintRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={renderProps => (
    isLoggedIn() ? (
      <Component {...renderProps} />
    ) : (
      <Redirect to={{
        pathname: '/',
        state: { from: renderProps.location }
      }} />
    )
  )} />
)

// This router comes under AdminDatabaseIndex
export const DBRoutes = () => {
  return (
    <Switch>
      <Route exact path="/a/db" component={AdminDatabaseDashboard} />
      <Route exact path="/a/db/users" component={AdminDatabaseUsersTableContainer} />
      <Route path="/a/db/users/:id" component={AdminDatabaseUserDetailsContainer} />
      <Route exact path="/a/db/lessons" component={AdminDatabaseLessonsTableContainer} />
      <Route path="/a/db/lessons/:id" component={AdminDatabaseLessonDetailsContainer} />
      <Route path="/a/db/lesson-issues" component={AdminDatabaseLessonIssuesTableContainer} />
      <Route path="/a/db/payments" component={AdminDatabasePaymentsTableContainer} />
      <Route path="/a/db/courses" component={AdminDatabaseCoursesTableContainer} />
      <Route exact path="/a/db/materials" component={AdminDatabaseMaterialsTableContainer} />
      <Route path="/a/db/materials/:id" component={AdminDatabaseMaterialDetailsContainer} />
      <Route path="/a/db/holidays" component={AdminDatabaseHolidaysTableContainer} />
    </Switch>
  );
};

// // This router comes under AdminHrHome
export const HRRoutes = () => {
  return (
    <Switch>
      <Route exact path="/a/hr/" component={AdminHrDashboard} />
      <Route exact path="/a/hr/tutors/" component={AdminHrTutorsContainer} />
      <Route path="/a/hr/tutors/:id/bank-info" component={AdminHrTutorBankInfoContainer} />
      <Route exact path="/a/hr/tutors/create" component={AdminHrTutorCreatorContainer} />
      <Route exact path="/a/hr/tutors/:id" component={AdminHrTutorAccountContainer} />
      <Route path="/a/hr/shifts" component={AdminHrShiftsCalendarContainer} />
      <Route path="/a/hr/tutors/:id/schedules" component={AdminHrTutorSchedulesContainer} />
      <Route path="/a/hr/tutors/:id/shifts" component={AdminHrTutorShiftsContainer} />
      <Route exact path="/a/hr/data/" component={AdminHrDataDashboard} />
      <Route exact path="/a/hr/data/by-tutors/" component={AdminHrDataByTutors} />
    </Switch>
  );
};


export const RootRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <AuthConstraintRoute path="/a/db/" component={AdminDatabaseIndex} />
      <AuthConstraintRoute path="/a/hr/" component={AdminHrHome} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

// const routes = (
//   <Route path='/' component={App}>
//     <IndexRoute component={Login} onEnter={redirectToDashboard}/>
//     <Route path='/a/db' component={AdminDatabaseIndex} onEnter={redirectToLogin}>
//       <IndexRoute component={AdminDatabaseDashboard} />
//       <Route path='/a/db/users' component={AdminDatabaseUsersTableContainer}/>
//       <Route path='/a/db/users/:id' component={AdminDatabaseUserDetailsContainer}/>
//       <Route path='/a/db/lessons' component={AdminDatabaseLessonsTableContainer}/>
//       <Route path='/a/db/lessons/:id' component={AdminDatabaseLessonDetailsContainer}/>
//       <Route path='/a/db/lesson-issues' component={AdminDatabaseLessonIssuesTableContainer}/>
//       <Route path='/a/db/payments' component={AdminDatabasePaymentsTableContainer}/>
//       <Route path='/a/db/courses' component={AdminDatabaseCoursesTableContainer}/>
//       <Route path='/a/db/materials' component={AdminDatabaseMaterialsTableContainer}/>
//       <Route path='/a/db/materials/:id' component={AdminDatabaseMaterialDetailsContainer}/>
//       <Route path='/a/db/holidays' component={AdminDatabaseHolidaysTableContainer}/>
//     </Route>
//     <Route path='/a/hr' component={AdminHrHome}>
//       <IndexRoute component={AdminHrDashboard} />
//       <Route path='/a/hr/tutors' component={AdminHrTutorsContainer} />
//       <Route path='/a/hr/tutors/:id/bank-info' component={AdminHrTutorBankInfoContainer} />
//       <Route path='/a/hr/tutors/:id' component={AdminHrTutorAccountContainer} />
//       <Route path='/a/hr/shifts' component={AdminHrShiftsCalendarContainer}/>
//       <Route path='/a/hr/tutors/:id/schedules' component={AdminHrTutorSchedulesContainer} />
//       <Route path='/a/hr/tutors/:id/shifts' component={AdminHrTutorShiftsContainer} />
//     </Route>
//     <Route path="*" component={NotFound}/>
//   </Route>
// );
