import React, {Component} from 'react';
import classnames from 'classnames';

class RadioInputs extends Component {
  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      this.props.onKeyDown(e);
    }
  }

  handleChange(e) {
    const {updateHandler, stateType} = this.props
    if (stateType) {
      updateHandler(e.target.value, stateType);
    } else {
      updateHandler(e.target.value);
    }
  }


  render() {
    const self = this;
    const { name, value } = this.props
    let options = this.props.options.map((opt) => {
      const checked = opt.value === value;
      const content = opt.content ? opt.content : opt.value;
      return (
        <label key={`${name}-${opt.id}-${opt.value}`}>
          <input
            key={`${name}-${opt.id}`}
            type="radio"
            name={name}
            value={opt.value}
            checked={checked}
            onChange={this.handleChange}/>
          {content}
        </label>
        );
      });
    const validationResult = this.props.validationResult;
    let className;
    if (validationResult && !validationResult.success) {
      className = classnames(this.props.className, 'invalid');
    } else {
      className = this.props.className;
    }
    let wrapperClass = '';
    return (
      <div className={wrapperClass}>
        {options}
      </div>
    );
  }
}

export default RadioInputs;