import React, {Component} from 'react';
import PropTypes from 'prop-types';
import HrSidenav from '../HrSidenav';
import TutorFilter from './TutorFilter';
import TutorsTable from './TutorsTable';
import ContentWrapper from '../ContentWrapper';

class Tutors extends Component {
  render() {
    const {
      tutors,
      count,
      page,
      changeAdded,
      chosenSortOption,
      nameOrEmail,
      chosenStatus,
      handleUpdatePage,
      handleUpdateSortOption,
      handleUpdateNameOrEmail,
      handleUpdateStatus,
      handleSubmit
    } = this.props
    return (
      <ContentWrapper>
        <div id="tutor-list">
          <TutorFilter 
            count={count}
            page={page}
            chosenStatus={chosenStatus}
            changeAdded={changeAdded}
            chosenSortOption={chosenSortOption}
            nameOrEmail={nameOrEmail}
            handleUpdatePage={handleUpdatePage}
            handleUpdateStatus={handleUpdateStatus}
            handleUpdateSortOption={handleUpdateSortOption}
            handleUpdateNameOrEmail={handleUpdateNameOrEmail}
            handleSubmit={handleSubmit} />
          <TutorsTable
            tutors={tutors} />
        </div>
      </ContentWrapper>
    );
  }
}

Tutors.propTypes = {
  tutors: PropTypes.array,
};

export default Tutors;
