import { combineReducers } from 'redux';
import authReducer from './auth';
import bookingReducer from './booking';
import courseReducer from './course';
import flashReducer from './flash';
import holidayReducer from './holiday';
import lessonReducer from './lesson';
import lessonDataReducer from './lessonData';
import lessonIssueReducer from './lessonIssue';
import materialReducer from './material';
import materialDataReducer from './materialData';
import modalReducer from './modal';
import paymentReducer from './payment';
import scheduleReducer from './schedule';
import secondaryModalReducer from './secondaryModal';
import shiftReducer from './shift';
import shiftSetReducer from './shiftSet';
import timeZoneReducer from './timeZone';
import tutorReducer from './tutor';
import tutorDataReducer from './tutorData';
import tutorBankInfoReducer from './tutorBankInfo';
import unitReducer from './unit';
import userReducer from './user';
import userDataReducer from './userData';
import dataForDashboardReducer from './data/forDashboard';
import dataByTutorsReducer from './data/byTutors';
import { Router, Route, browserHistory } from 'react-router'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'

const rootReducer = combineReducers({
  auth: authReducer,
  booking: bookingReducer,
  course: courseReducer,
  dataForDashboard: dataForDashboardReducer,
  dataByTutors: dataByTutorsReducer,
  flash: flashReducer,
  holiday: holidayReducer,
  lesson: lessonReducer,
  lessonData: lessonDataReducer,
  lessonIssue: lessonIssueReducer,
  material: materialReducer,
  materialData: materialDataReducer,
  modal: modalReducer,
  payment: paymentReducer,
  secondaryModal: secondaryModalReducer,
  schedule: scheduleReducer,
  shift: shiftReducer,
  shiftSet: shiftSetReducer,
  timeZone: timeZoneReducer,
  tutor: tutorReducer,
  tutorData: tutorDataReducer,
  tutorBankInfo: tutorBankInfoReducer,
  unit: unitReducer,
  user: userReducer,
  userData: userDataReducer,
  routing: routerReducer
});

export default rootReducer;
