import Collection from '../utils/Collection.js';

import {
  FETCH_BANK_INFO_BY_TUTOR_SENT,
  FETCH_BANK_INFO_BY_TUTOR_SUCCEEDED,
  FETCH_BANK_INFO_BY_TUTOR_FAILED,
  UPDATE_BANK_INFO_SENT,
  UPDATE_BANK_INFO_SUCCEEDED,
  UPDATE_BANK_INFO_FAILED
  ,
} from '../actions/tutorBankInfo';


const initialState = {
  loading: false,
  updating: false,
  bankInfo: null
}

export default function tutorBankInfoReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_BANK_INFO_BY_TUTOR_SENT:
      return {
        ...state, 
        loading: true
      };
    case UPDATE_BANK_INFO_SENT:
      return {
        ...state, 
        updating: true
      };
    case FETCH_BANK_INFO_BY_TUTOR_SUCCEEDED:
    case UPDATE_BANK_INFO_SUCCEEDED:
      return {
        ...state, 
        bankInfo: payload.bank_info,
        updating: false,
        loading: false
      };
    case UPDATE_BANK_INFO_FAILED:
      return {
        ...state, 
        updating: false
      };
    default:
      return state;
  }
}