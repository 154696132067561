import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatabaseSidenav from '../Sidenav';
import CourseFilter from './CourseFilter';
import CoursesTable from './CoursesTable';

class Courses extends Component {
  render() {
    const {
      handleSubmit,
      changeAdded,
      handleUpdateSortOption,
      handleUpdateStatus,
      handleUpdateDifficulty,
      handleUpdatePage,
      chosenSortOption,
      chosenStatus,
      chosenDifficulty,
      page,
      courses,
      count
    } = this.props;
    return (
      <div id="database-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <DatabaseSidenav />
            </div>
            <div className="col-lg-10">
              <CourseFilter
                handleSubmit={handleSubmit}
                changeAdded={changeAdded}
                handleUpdateSortOption={handleUpdateSortOption}
                handleUpdateStatus={handleUpdateStatus}
                handleUpdateDifficulty={handleUpdateDifficulty}
                handleUpdatePage={handleUpdatePage}
                chosenSortOption={chosenSortOption}
                chosenStatus={chosenStatus}
                chosenDifficulty={chosenDifficulty}
                page={page}
                count={count} />
              <CoursesTable courses={courses}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Courses;
