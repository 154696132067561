import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Collection from '../../utils/Collection';
import Textarea from '../../components/form/Textarea';
import RadioInputs from '../../components/form/RadioInputs';
import ModalMessage from '../../components/modals/ModalMessage';
import * as LessonActions from '../../actions/lesson';

const getBooking = (lessons, ownProps) => {
  const { lesson } = ownProps;
  let booking = Collection.get(lessons, lesson.id);
  if (booking) {
    return booking;
  } else {
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const { 
    lesson,
  } = state;
  const {
    saving,
    lessons,
  } = lesson;
  return {
    booking: getBooking(lessons, ownProps),
    saving,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    lessonActions: bindActionCreators(LessonActions, dispatch)
  };
}

const propTypes = {
  lesson: PropTypes.object.isRequired,
  saving: PropTypes.bool.isRequired,
  booking: PropTypes.object,
  handleHideModal: PropTypes.func.isRequired,
};

const FIFTY_MINUTES = 3000000;

class CancelBookingModalContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedReason: null,
      comment: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  onUpdateInput(value, stateType) {
    let state = this.state;
    state[stateType] = value;
    state.changeAdded = true;
    this.setState(state);
  }

  hideModal(e) {
    e.preventDefault();
    this.props.handleHideModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { selectedReason, comment } = this.state
    const {
      booking,
      lessonActions,
      addModalMessage,
      clearModalMessage
    } = this.props
    if (selectedReason && comment) {
      const params = {
        reason: selectedReason, 
        comment
      }
      lessonActions.cancel(booking.id, params);
      clearModalMessage();
    } else {
      addModalMessage("Reason and comment cannot be empty.", {
        type: "error",
        autoDisappear: false
      });
      // addModalMessage("Reason and comment are required.", {
      //   autoDisappear: false,
      //   type: "error"
      // });
    }
  }

  getOptions() {
    const polyglot = this.context.polyglot;
    const options =
      [
        {value: "Cancel on behalf of student", id: "0"},
        {value: "Unstable internet connection", id: "1"},
        {value: "Blackout", id: "2"},
        {value: "Computer problem", id: "3"},
        {value: "Sickness", id: "4"},
        {value: "Taking care of sick family member", id: "5" },
        {value: "Other tutor-side reason", id: "6"},
        {value: "Other", id: "7"},
      ];
    return options;
  }


  render() {
    const options = this.getOptions();
    const {
      selectedReason,
      comment
    } = this.state;
    const {
      saving,
      booking,
      message,
      messageType
    } = this.props;
    const reasonSelectProps = {
      options,
      className: '',
      id: 'cancel-booking-reason',
      value: selectedReason,
      name: "issue-type",
      updateHandler: this.onUpdateInput,
      stateType: "selectedReason",
      invalidLabel: true,
      onKeyDown: this.handleSubmit
    }
    const commentProps = {
      rows: 5,
      value: comment,
      stateType: "comment",
      updateHandler: this.onUpdateInput,
    }
    const panelBodyClass = classnames("panel-body", {loading: saving});
    return (
      <div className="modal-content">
        <div className="panel panel-no-border">
          <div className="panel-header">
            <span>Cancel Booking</span>
            <a className="modal-close" onClick={this.hideModal}>
              <span>☓</span>
            </a>
          </div>
          <ModalMessage message={message} messageType={messageType}/>
           <form
              method="put"
              className="report-issue-form cancel-booking-by-tutor-form"
              onSubmit={this.handleSubmit}
              noValidate>
            <div className={panelBodyClass}>
              <div className="reasons-wrapper">
                <h5>Select a reason</h5>
                <RadioInputs {...reasonSelectProps} />
              </div>
              <div className="message-wrapper row-space-top-4">
                <h5 className="row-space-2">Message to the student</h5>
                <Textarea {...commentProps} />
              </div>
            </div>
            <div className="panel-footer">
              <div className="text-right">
                <button
                  disabled={!!saving}
                  className="btn btn-primary btn-large">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CancelBookingModalContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(CancelBookingModalContainer);
