import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const {updateHandler, stateType} = this.props
    if (stateType) {
      updateHandler(e.target.value, stateType);
    } else {
      updateHandler(e.target.value);
    }
  }

  render() {
    const {
      rows,
      name,
      value,
      className,
      placeholder
    } = this.props;
    return (
      <textarea
        rows={rows}
        name={name}
        value={value}
        onChange={this.handleChange}
        className={className}
        placeholder={placeholder}
      />
    );
  }
}

Textarea.propTypes = {
  id: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  onKeyDown: PropTypes.func,
  validationResult: PropTypes.object,
  invalidLabel: PropTypes.bool,
  showInvalidLabel: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  updateHandler: PropTypes.func,
};

export default Textarea;