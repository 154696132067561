// @flow

import React from 'react';
import TextInput from '../form/TextInput';
import PasswordInput from '../form/PasswordInput';
import UserValidator from '../../validators/UserValidator';
import logoImg from '../../assets/images/logo_large.png';

class Login extends React.Component {
  onUpdateEmail: Function;
  onUpdatePassword: Function;
  onSubmit: Function;

  props: {
    onClickLogin: Function
  }

  state: {
    email: string,
    password: string,
    validationErrors: Array<string>
  };

  constructor() {
    super();
    this.onUpdateEmail = this.onUpdateEmail.bind(this);
    this.onUpdatePassword = this.onUpdatePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email: '',
      password: '',
      validationErrors: [],
    };
  }

  onUpdateEmail(value : string) : void {
    const state = this.state;
    state.email = value;
    this.setState(state);
  }

  onUpdatePassword(value : string) : void {
    const state = this.state;
    state.password = value;
    this.setState(state);
  }

  onSubmit(e : Event) : void {
    e.preventDefault();
    const { email, password } = this.state;
    const { onClickLogin } = this.props;
    let userValidator = new UserValidator({email, password})
    userValidator.validate()
    .then((result) => {
      if (result.valid) {
        onClickLogin(email, password);
      } else {
        this.setState({validationErrors: result.errors})
        let message = '';
        for (let err of result.errors) {
          message += err + " ";
        }
        // ServerMessageActions.addMessage({
        //   alertType: "error",
        //   type: "loginValidationFailed",
        //   message,
        //   autoDisappear: false,
        // });
      }
    });
  }

  render() {
    const {
      email,
      password,
      validationErrors
    } = this.state;
    const emailProps = {
      placeholder: 'Email Address',
      className: 'email',
      value: email,
      type: 'email',
      name: 'email',
      updateHandler: this.onUpdateEmail,
      validationResult: validationErrors[0],
      invalidLabel: true,
    };
    const passwordProps = {
      placeholder: 'Password',
      className: 'password',
      value: password,
      name: 'password',
      updateHandler: this.onUpdatePassword,
      validationResult: validationErrors[1],
      invalidLabel: true,
    };
    const logo = <img src={logoImg} height='31.5px' width='180px' role="presentation" />;
    return (
      <div>
        <div className="container-fluid auth-container row-space-top-large row-space-8">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-center">
              <div className="panel panel-no-border">
                <div className="panel-header show-sm">
                  <span>Login</span>
                </div>
                <div className="panel-body">
                  <div className="logo-for-login row-space-6 row-space-top-6 text-center">
                    {logo}
                  </div>
                  <form
                    method="post"
                    className="login-form col-center col-sm-11"
                    onSubmit={this.onSubmit}
                    noValidate
                  >
                    <div className="control-group row-space-3">
                      <TextInput {...emailProps} onKeyDown={this.onSubmit} />
                    </div>
                    <div className="control-group row-space-3">
                      <PasswordInput {...passwordProps} onKeyDown={this.onSubmit} />
                    </div>
                    <div className="control-group row-space-6">
                      <button
                        className="btn btn-block btn-primary btn-large"
                        type="submit"
                      >Login</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Login;
