import ApiConstants from '../../constants/ApiConstants';
import xhr from '../xhr';

const DataApiUtils = {

  fetchDataForDashboard(chosenDate=null) {
    let query = "";
    if (chosenDate) {
      query = '?chosenDate=' + chosenDate.valueOf().toString();
    }
    return xhr(ApiConstants.Base + 'data/for-dashboard' + query);
  },

  fetchDataByTutors(chosenDate=null) {
    let query = "";
    if (chosenDate) {
      query = '?chosenDate=' + chosenDate.valueOf().toString();
    }
    return xhr(ApiConstants.Base + 'data/by-tutors' + query);
  },

  fetchDataByTutor(tutorId, chosenDate=null) {
    let query = "";
    if (chosenDate) {
      query = '?chosenDate=' + chosenDate.valueOf().toString();
    }
    return xhr(ApiConstants.Base + 'data/by-tutor/' + tutorId.toString() + query);
  }
};

export default DataApiUtils;
