import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Collection from '../../utils/Collection';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import classnames from 'classnames';
import * as LessonActions from '../../actions/lesson';
import * as LessonIssueActions from '../../actions/lessonIssue';
import ModalMessage from '../../components/modals/ModalMessage';
import RadioInputs from '../../components/form/RadioInputs';
import Textarea from '../../components/form/Textarea';

const FIFTY_MINUTES = 3000000;

function mapStateToProps(state) {
  const { lessonIssue } = state
  const {
    saving,
    selectedType,
    selectedAction,
    selectedReason,
    comment
  } = lessonIssue;
  return {
    saving,
    selectedType,
    selectedAction,
    selectedReason,
    comment,
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    lessonActions: bindActionCreators(LessonActions, dispatch),
    lessonIssueActions: bindActionCreators(LessonIssueActions, dispatch),
  };
}

class ReportAndResolveIssueModalContainer extends Component {

  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onUpdateType = this.onUpdateType.bind(this);
    this.onUpdateAction = this.onUpdateAction.bind(this);
    this.onUpdateReason = this.onUpdateReason.bind(this);
    this.onUpdateComment = this.onUpdateComment.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.getIssueTypeOptions = this.getIssueTypeOptions.bind(this);
    this.getReasonOptions = this.getReasonOptions.bind(this);
    this.renderLessonInfo = this.renderLessonInfo.bind(this);
  }

  onUpdateType(value) {
    this.props.lessonIssueActions.updateSelectedType(value);
  }

  onUpdateAction(value) {
    this.props.lessonIssueActions.updateSelectedAction(value);
  }

  onUpdateReason(value) {
    this.props.lessonIssueActions.updateSelectedReason(value);
  }

  onUpdateComment(value) {
    this.props.lessonIssueActions.updateComment(value);
  }

  hideModal(e) {
    e.preventDefault();
    this.props.handleHideModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { 
      lesson,
      lessonIssueActions, 
      selectedType,
      selectedAction, 
      selectedReason, 
      comment 
    } = this.props
    if (
      selectedType !== null &&
      selectedAction !== null && 
      selectedReason !== null) {
      lessonIssueActions.reportAndResolve(
        lesson.id,
        selectedType,
        selectedAction,
        selectedReason,
        comment
      );
    } else {
      this.props.addModalMessage("Type, Action, Reason cannot be empty.", {
        type: "error",
        autoDisappear: false
      });
    }
  }

  getOptions() {
    const { lesson } = this.props;
    const lessonDuration = (lesson.end_at - lesson.start_at) === FIFTY_MINUTES ? '50' : '25';
    let options = [
        {value: "Mark lesson as completed and issue 100% refund", id: "0"},
        {value: "Mark lesson as completed", id: "2"}
      ];
    if (lessonDuration === '50') {
      options =
        [
          {value: "Mark lesson as completed and issue 100% refund", id: "0"},
          {value: "Mark lesson as completed and issue 50% rerund", id: "1"},
          {value: "Mark lesson as completed", id: "2"}
        ];
    }
    return options;
  }

  getIssueTypeOptions() {
    const options =
      [
        {value: "Student was absent", id: "0"},
        {value: "Teacher was absent", id: "1"},
        {value: "Student-related technical problem", id: "2"},
        {value: "Tutor-related technical problem", id: "3"},
        {value: "Other student-related issue", id: "4"},
        {value: "Other tutor-related issue", id: "5"},
        {value: "Other", id: "7"},
      ];
    return options;
  }

  getReasonOptions() {
    const options = 
      [
        {value: "Lesson was delivered", id: "0"},
        {value: "Unstable internet connection", id: "1"},
        {value: "Blackout", id: "2"},
        {value: "Computer problem", id: "3"},
        {value: "Sickness", id: "4"},
        {value: "Taking care of sick family member", id: "5" },
        {value: "Other tutor-side reason", id: "6"},
        {value: "Other", id: "7"},
      ];
    return options;
  }

  renderLessonInfo() {
    const { lesson } = this.props;
    const startAt = moment.tz(lesson.start_at, "Asia/Manila");
    const endAt = moment.tz(lesson.end_at, "Asia/Manila");
    return (
      <div>
        <div className="row-space-1">
          <b>Student: </b>
          <span>{lesson.student_id}: {lesson.student_name}</span>
        </div>
        <div className="row-space-1">
          <b>Tutor: </b>
          <span>{lesson.tutor_id}: {lesson.tutor_name}</span>
        </div>
        <div className="row-space-1">
          <b>Date: </b>
          <span>{startAt.format('YYYY-MM-DD')}</span>
        </div>
        <div className="row-space-1">
          <b>Time: </b>
          <span>{startAt.format('hh:mmA')} - {endAt.format('hh:mmA')} UTC{startAt.format('ZZ')}</span>
        </div>
      </div>
    );
  }

  render() {
    const {
      saving,
      selectedType,
      selectedAction,
      selectedReason,
      comment,
      issue,
      message,
      messageType
    } = this.props;
    const options = this.getOptions();
    const typeOptions = this.getIssueTypeOptions();
    const reasonOptions = this.getReasonOptions();
    const issueTypeSelectProps = {
      options: typeOptions,
      className: '',
      id: 'select-issue-type',
      value: selectedType,
      name: "issue-type",
      updateHandler: this.onUpdateType,
      invalidLabel: true,
      onKeyDown: this.props.handleSubmit
    }
    const actionSelectProps = {
      options,
      className: '',
      id: 'select-resolve-action',
      value: selectedAction,
      name: "resolve-action",
      updateHandler: this.onUpdateAction,
      invalidLabel: true,
      onKeyDown: this.props.handleSubmit
    }
    const reasonSelectProps = {
      options: reasonOptions,
      className: '',
      id: 'select-issue-reason',
      value: selectedReason,
      name: "issue-reason",
      updateHandler: this.onUpdateReason,
      invalidLabel: true,
      onKeyDown: this.handleSubmit
    }
    const commentProps = {
      rows: 5,
      value: comment,
      updateHandler: this.onUpdateComment,
    }
    return (
      <div className="modal-content">
        <div className="panel panel-no-border">
          <div className="panel-header">
            <span>Report and resolve issue</span>
            <a className="modal-close" onClick={this.hideModal}>
              <span>☓</span>
            </a>
          </div>
          <ModalMessage message={message} messageType={messageType}/>
          <form
            method="post"
            className="report-issue-form"
            onSubmit={this.handleSubmit}
            noValidate>
            <div className="panel-body">
              <div className="lesson-wrapper">
                <h5 className="row-space-3">About Lesson</h5>
                {this.renderLessonInfo()}
              </div>
              <hr/>
              <div className="issue-types-wrapper row-space-4">
                <h5>Please choose the issue type</h5>
                <RadioInputs {...issueTypeSelectProps} />
              </div>
              <hr/>
              <div className="actions-wrapper row-space-4">
                <h5>How do you like to resolve the issue?</h5>
                <RadioInputs {...actionSelectProps} />
              </div>
              <hr/>
              <div className="reasons-wrapper row-space-4">
                <h5>What is the reason of the issue?</h5>
                <RadioInputs {...reasonSelectProps} />
              </div>
              <hr/>
              <div className="message-wrapper">
                <h5 className="row-space-2">Leave your comment.</h5>
                <Textarea {...commentProps} />
              </div>
            </div>
            <div className="panel-footer">
              <div className="text-right">
                <button 
                  className="btn btn-primary btn-large"
                  disabled={saving}>Report and resolve</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


ReportAndResolveIssueModalContainer.propTypes = {
  issue: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportAndResolveIssueModalContainer)

