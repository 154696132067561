import DataApiUtils from '../utils/api/data';

export const FETCH_DATA_FOR_DASHBOARD_SENT = 'FETCH_DATA_FOR_DASHBOARD_SENT';
export const FETCH_DATA_FOR_DASHBOARD_SUCCEEDED = 'FETCH_DATA_FOR_DASHBOARD_SUCCEEDED';
export const FETCH_DATA_FOR_DASHBOARD_FAILED = 'FETCH_DATA_FOR_DASHBOARD_FAILED';
export const FETCH_DATA_BY_TUTORS_SENT = 'FETCH_DATA_BY_TUTORS_SENT';
export const FETCH_DATA_BY_TUTORS_SUCCEEDED = 'FETCH_DATA_BY_TUTORS_SUCCEEDED';
export const FETCH_DATA_BY_TUTORS_FAILED = 'FETCH_DATA_BY_TUTORS_FAILED';

export function fetchDataForDashboardSent(chosenDate) {
  return {
    type : FETCH_DATA_FOR_DASHBOARD_SENT,
    payload: { chosenDate }
  }
}

export function fetchDataForDashboardSucceeded(data) {
  return {
    type: FETCH_DATA_FOR_DASHBOARD_SUCCEEDED,
    payload: data
  }
}

export function fetchDataForDashboardFailed(error) {
  return {
    type: FETCH_DATA_FOR_DASHBOARD_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetchDataForDashboard(chosenDate=null) {
  return (dispatch) => {
    dispatch(fetchDataForDashboardSent(chosenDate));
    return DataApiUtils.fetchDataForDashboard(chosenDate).then(
      (data) => dispatch(fetchDataForDashboardSucceeded(data)),
      (error) => dispatch(fetchDataForDashboardFailed(error))
    );
  };
}


export function fetchDataByTutorsSent(chosenDate) {
  return {
    type : FETCH_DATA_BY_TUTORS_SENT,
    payload: { chosenDate }
  }
}

export function fetchDataByTutorsSucceeded(data) {
  return {
    type: FETCH_DATA_BY_TUTORS_SUCCEEDED,
    payload: data
  }
}

export function fetchDataByTutorsFailed(error) {
  return {
    type: FETCH_DATA_BY_TUTORS_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetchDataByTutors(chosenDate=null) {
  return (dispatch) => {
    dispatch(fetchDataByTutorsSent(chosenDate));
    return DataApiUtils.fetchDataByTutors(chosenDate).then(
      (data) => dispatch(fetchDataByTutorsSucceeded(data)),
      (error) => dispatch(fetchDataByTutorsFailed(error))
    );
  };
}
