import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class AdminHrDashboard extends Component {
  render() {
    return (
      <div id="admin-hr-dashboard">
        <div className="container-fluid">
          <div className="row">
            <ul>   
              <li><Link className="row-space-2" to="/a/hr/tutors">Active Tutors</Link></li>
              <li><Link className="row-space-2" to="/a/hr/shifts">Shift Calendar</Link></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminHrDashboard;
