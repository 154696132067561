import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';

const CourseApiUtils = {

  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.FetchCourses + query);
  },

  fetchUnits(courseId) {
    return xhr(ApiConstants.FetchCourses + '/' + courseId + '/units');
  },
  
};

export default CourseApiUtils;
