import LessonApiUtils from '../utils/api/lesson';
import {addMessage as addFlashMessage} from './flash';
import * as ModalActions from './modal';

export const FETCH_LESSONS_SUCCEEDED = 'FETCH_LESSONS_SUCCEEDED';
export const FETCH_LESSONS_FAILED = 'FETCH_LESSONS_FAILED';
export const FETCH_LESSON_SUCCEEDED = 'FETCH_LESSON_SUCCEEDED';
export const FETCH_LESSON_FAILED = 'FETCH_LESSON_FAILED';
export const CANCEL_LESSON_RESERVATION_SENT = 'CANCEL_LESSON_RESERVATION_SENT';
export const CANCEL_LESSON_RESERVATION_SUCCEEDED = 'CANCEL_LESSON_RESERVATION_SUCCEEDED';
export const CANCEL_LESSON_RESERVATION_FAILED = 'CANCEL_LESSON_RESERVATION_FAILED';

export function fetchSucceeded(data, opts) {
  return {
    type: FETCH_LESSONS_SUCCEEDED,
    payload: Object.assign({}, data, {opts})
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_LESSONS_FAILED
  }
}

export function fetchOneSucceeded(data) {
  return {
    type: FETCH_LESSON_SUCCEEDED,
    payload: data
  }
}

export function fetchOneFailed(error) {
  return {
    type: FETCH_LESSON_FAILED
  }
}

export function fetch(opts) {
  return (dispatch) => {
    return LessonApiUtils.fetch(opts).then(
      (data) => dispatch(fetchSucceeded(data, opts)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}

export function fetchOne(id) {
  return (dispatch) => {
    return LessonApiUtils.fetchOne(id).then(
      (data) => dispatch(fetchOneSucceeded(data)),
      (error) => dispatch(fetchOneFailed(error))
    );
  };
}

export function cancelSent() {
  return {
    type: CANCEL_LESSON_RESERVATION_SENT
  }
}

export function cancelSucceeded(data) {
  return {
    type: CANCEL_LESSON_RESERVATION_SUCCEEDED,
    payload: data
  }
}

export function cancelFailed(error) {
  return {
    type: CANCEL_LESSON_RESERVATION_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function cancel(id, params) {
  return (dispatch) => {
    dispatch(cancelSent);
    return LessonApiUtils.cancel(id, params).then(
      (data) => {
        dispatch(ModalActions.hide());
        dispatch(addFlashMessage("Successfully canceled.", {autoDisappear: true, sticky: true}));
        dispatch(cancelSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(cancelFailed(error))
      }
    );
  }
}
