import Collection from '../utils/Collection.js';

import {
  FETCH_SHIFTS_SENT,
  FETCH_SHIFTS_SUCCEEDED,
  FETCH_SHIFTS_FAILED,
} from '../actions/shift';

import {
  FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED
} from '../actions/tutor';

const initialState = {
  loading: false,
  shifts: [],
  tutorShifts: [],
  lessonNumsOfSlots: {}
}

export default function shiftReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_SHIFTS_SENT:
      return {
        ...state, 
        loading: true
      };
    case FETCH_SHIFTS_SUCCEEDED:
      return {
        ...state, 
        shifts: Collection.add(state.shifts, payload.shifts),
        lessonNumsOfSlots: payload.lesson_num_of_slots_hash,
        loading: false
      };
    case FETCH_SHIFTS_FAILED:
      return {
        ...state, 
        loading: false
      };
    case FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED:
      return {
        ...state, 
        tutorShifts: Collection.reset(state.tutorShifts, payload.shifts),
      };
    default:
      return state;
  }
}