import ScheduleApiUtils from '../utils/api/schedule';
import {addMessage as addFlashMessage} from './flash';

export const CREATE_MANY_SCHEDULES_SENT = 'CREATE_MANY_SCHEDULES_SENT';
export const CREATE_MANY_SCHEDULES_SUCCEEDED = 'CREATE_MANY_SCHEDULES_SUCCEEDED';
export const CREATE_MANY_SCHEDULES_FAILED = 'CREATE_MANY_SCHEDULES_FAILED';
export const CLEAR_EDITING_SCHEDULES = "CLEAR_EDITING_SCHEDULES";
export const UPDATE_EDITING_SCHEDULES = "UPDATE_EDITING_SCHEDULES";
export const CHANGE_SCHEDULE_EDIT_TYPE = "CHANGE_SCHEDULE_EDIT_TYPE";

export function createManySent() {
  return {
    type: CREATE_MANY_SCHEDULES_SENT
  }
}

export function createManySucceeded(data) {
  return {
    type: CREATE_MANY_SCHEDULES_SUCCEEDED,
    payload: data
  }
}

export function createManyFailed(error) {
  return {
    type: CREATE_MANY_SCHEDULES_FAILED
  }
}

export function createMany(tutorId, params) {
  return (dispatch) => {
    dispatch(createManySent());
    return ScheduleApiUtils.createMany(tutorId, params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully added the schedules", {autoDisappear: true, sticky: true }));
        dispatch(createManySucceeded(data));
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true }));
        dispatch(createManyFailed(error));
      }
    );
  };
}

export function clearEditingSchedules() {
  return { type: CLEAR_EDITING_SCHEDULES }
}

export function updateEditingSchedules(opts) {
  const {newRemoveTypeSchedules, newAddTypeSchedules} = opts;
  return { 
    type: UPDATE_EDITING_SCHEDULES,
    payload: {
      newRemoveTypeSchedules,
      newAddTypeSchedules
    }
  }
}

export function changeEditType(value) {
  return { 
    type: CHANGE_SCHEDULE_EDIT_TYPE,
    payload: {
      editType: value
    }
  }
}

