export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE';

export function addMessage(message, opts={}) {
  return {
    type: ADD_FLASH_MESSAGE,
    payload: Object.assign({}, {message}, opts)
  }
}

export function clearMessage() {
  return {
    type: CLEAR_FLASH_MESSAGE
  }
}