import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Column, Cell } from 'fixed-data-table';
// import { Link } from 'react-router-dom';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';
import LinkCell from '../LinkCell';

class UsersTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  render() {
    const users = this.props.users;
    const screenWidth = this.state.width;
    const width = screenWidth >= 1100 ? (screenWidth * 0.833) - 25 : screenWidth - 25;
    const height = 50 + (users.length * 50) > 500 ? 500 : 50 + (users.length * 50);
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={users.length}
        headerHeight={50}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={
            <LinkCell
              tableName="users"
              data={users}
              field="id"
            />
          }
          width={200}
        />
        <Column
          header={<Cell>NAME</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].name}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>PROFILE TYPE</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].profile_type}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>EMAIL</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].email}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>SKYPE ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].skype_name}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>TIMEZONE</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].js_time_zone}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>LOCALE</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].locale.value}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>PLAN</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].payment_info ? users[props.rowIndex].payment_info.name : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>PLAN STATUS</Cell>}
          cell={props => (
            <Cell {...props}>
              {users[props.rowIndex].payment_info ? users[props.rowIndex].payment_info.status : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>CREATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(users[props.rowIndex].created_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
        <Column
          header={<Cell>UPDATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(users[props.rowIndex].updated_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
      </Table>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.array,
};

export default UsersTable;
