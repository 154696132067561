import BaseValidator from './BaseValidator.js';

class MaterialValidator extends BaseValidator {
  constructor(opts={}) {
    super();
    this.validationErrors = [];
    this.validity = null;
    this.courseId = opts.courseId;
    this.unitId = opts.unitId;
    this.number = opts.number;
    this.title = opts.title
  }

  validate() {
    const validityCheck = Promise.all([
      this.validateCourseId(),
      this.validateUnitId(),
      this.validateNumber(),
      this.validateTitle()
    ]).then((responses) => {
      if (this.validationErrors.length === 0) {
        return Promise.resolve({valid: true});
      } else {
        return Promise.resolve({valid: false, errors: this.validationErrors});
      }
    })
    return validityCheck
  }

  dealValidationResult(type, res) {
    if (res.success) {
      return res;
    } else {
      this.validationErrors.push(type + res.err);
      return res;
    }
  }

  validateCourseId() {
    if (this.courseId === null) {
      return true;
    }
    return super.cannotEmpty(this.courseId)
    .then(this.cannotBeZero)
    .then((res) => {
      this.dealValidationResult("Course ", res);
    });
  }

  validateUnitId() {
    if (this.unitId === null) {
      return true;
    }
    return super.cannotEmpty(this.unitId)
    .then(this.cannotBeZero)
    .then((res) => { 
      this.dealValidationResult("Unit ", res);
    });
  }

  validateNumber() {
    if (this.number === null) {
      return true;
    }
    return super.cannotEmpty(this.number)
    .then(this.cannotBeZero)
    .then((res) => { 
      this.dealValidationResult("Number", res);
    });
  }

  validateTitle() {
    if (this.title === null) {
      return true;
    }
    return super.cannotEmpty(this.title)
    .then((res) => { 
      this.dealValidationResult("Title", res);
    });
  }

  cannotBeZero(res) {
    if (res.value === null || +res.value !== 0) {
      return res
    } else {
      return {success: false, err: " format is invalid."}
    }
  }



}

export default MaterialValidator;
