import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class LessonDetailsBase extends Component {

  constructor(props) {
    super(props);
  }

  
  render() {
    const { id,
            student_id, 
            student_name,
            tutor_id,
            tutor_name,
            start_at,
            end_at,
          } = this.props.lesson;
    return (
      <div className="panel row-space-4">
        <div className="panel-header">Lesson Info</div>
        <div className="panel-body panel-body-no-border">
          <div className="form-body">
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Student</b>
              </div>
              <div className="col-sm-10">
                {student_id}: {student_name}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Tutor</b>
              </div>
              <div className="col-sm-10">
                {tutor_id}: {tutor_name}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Date</b>
              </div>
              <div className="col-sm-10">
                {moment(start_at).format('YYYY-MM-DD')}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Time</b>
              </div>
              <div className="col-sm-10">
                {moment(start_at).format('HH:mm')}-{moment(end_at).format('HH:mm')} UTC{moment(start_at).format('ZZ')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LessonDetailsBase;