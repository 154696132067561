import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatabaseSidenav from '../Sidenav.js';
import LessonIssueFilter from './LessonIssueFilter.js';
import LessonIssuesTable from './LessonIssuesTable.js';

class LessonIssues extends Component {
  render() {
    const {
      handleSubmit,
      changeAdded,
      handleUpdateSortOption,
      handleUpdateStatus,
      handleUpdateType,
      handleUpdateReason,
      handleUpdateStudentEmail,
      handleUpdateTutorEmail,
      handleUpdatePage,
      chosenSortOption,
      chosenStatus,
      chosenType,
      chosenReason,
      studentEmail,
      tutorEmail,
      page,
      issues,
      count,
      onUpdateDuration,
      durationStart,
      durationEnd,
    } = this.props;
    return (
      <div id="database-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <DatabaseSidenav />
            </div>
            <div className="col-lg-10">
              <LessonIssueFilter
                handleSubmit={handleSubmit}
                changeAdded={changeAdded}
                handleUpdateSortOption={handleUpdateSortOption}
                handleUpdateStatus={handleUpdateStatus}
                handleUpdateType={handleUpdateType}
                handleUpdateReason={handleUpdateReason}
                handleUpdateStudentEmail={handleUpdateStudentEmail}
                handleUpdateTutorEmail={handleUpdateTutorEmail}
                handleUpdatePage={handleUpdatePage}
                chosenSortOption={chosenSortOption}
                chosenStatus={chosenStatus}
                chosenType={chosenType}
                chosenReason={chosenReason}
                studentEmail={studentEmail}
                tutorEmail={tutorEmail}
                onUpdateDuration={onUpdateDuration}
                durationStart={durationStart}
                durationEnd={durationEnd}
                page={page}
                count={count} />
              <LessonIssuesTable issues={issues}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LessonIssues;
