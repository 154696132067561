import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import moment from 'moment-timezone';
import _ from 'lodash';
import 'react-dates/lib/css/_datepicker.css';
import * as ShiftSetActions from '../../actions/shiftSet';
import ModalMessage from '../../components/modals/ModalMessage';
import RadioInputs from '../../components/form/RadioInputs';

function mapStateToProps(state) {
  const { shiftSet } = state
  const {
    tutorShiftSets,
    shiftSetIdToDuplicate
  } = shiftSet;
  return {
    shiftSets: tutorShiftSets,
    setIdToDuplicate: shiftSetIdToDuplicate,
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    shiftSetActions: bindActionCreators(ShiftSetActions, dispatch),
  };
}


class AddShiftSetModalContainer extends Component {

  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getDuplicateOptions = this.getDuplicateOptions.bind(this);
    this.onUpdateSetIdToDuplicate = this.onUpdateSetIdToDuplicate.bind(this);
    this.initiateNewShiftSet = this.initiateNewShiftSet.bind(this);
  }


  onUpdateSetIdToDuplicate(value) {
    const { shiftSetActions } = this.props;
    shiftSetActions.updateShiftSetIdToDuplicate(value);
  }

  hideModal(e) {
    e.preventDefault();
    this.props.handleHideModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { setIdToDuplicate } = this.props;
    this.initiateNewShiftSet(setIdToDuplicate);
  }

  initiateNewShiftSet(shiftSetId) {
    let newShiftSetShifts = [];
    const {
      shiftSets,
      shiftSetActions, 
      handleHideModal, 
    } = this.props;
    let chosenId = shiftSetId;
    if (chosenId !== 0) {
      const shiftSet = _.findLast(shiftSets, (ss) => {
        return ss.id === chosenId
      });
      if (shiftSet) {
        for (let shift of shiftSet.shifts) {
          let tempShifts = []
          let i = shift.start_slot
          while (i < shift.end_slot) {
            tempShifts.push(i);
            i += 0.5;
          }
          newShiftSetShifts = newShiftSetShifts.concat(tempShifts);
        }
      }
    }
    shiftSetActions.initiateNewShiftSet(newShiftSetShifts)
    handleHideModal();
  }

  getDuplicateOptions() {
    const {shiftSets} = this.props;
    const options = shiftSets.map((ss) => {
      const duration_start = moment(ss.duration_start).tz("Asia/Manila").format("YYYY-MM-DD")
      const duration_end = moment(ss.duration_end).tz("Asia/Manila").format("YYYY-MM-DD")
      return {content: "Shift Set: " + duration_start + " - " + duration_end, id: ss.id, value: ss.id.toString()}
    });
    options.push({content: "Create without duplication", value: "0", id: 0})
    return options;
  }

  render() {
    const {
      setIdToDuplicate,
      message,
      messageType,
    } = this.props;
    const duplicateOptions = this.getDuplicateOptions();
    const setIdToDuplicateProps = {
      options: duplicateOptions,
      className: '',
      id: 'select-issue-type',
      value: setIdToDuplicate,
      name: "set-to-duplicate-select",
      updateHandler: this.onUpdateSetIdToDuplicate,
      invalidLabel: true,
      onKeyDown: this.handleSubmit
    }
    return (
      <div className="modal-content">
        <div className="panel panel-no-border">
          <div className="panel-header">
            <span>Add New Shift Set</span>
            <a className="modal-close" onClick={this.hideModal}>
              <span>☓</span>
            </a>
          </div>
          <ModalMessage message={message} messageType={messageType}/>
          <form
            method="post"
            className="report-and-resolve-issue-form"
            onSubmit={this.handleSubmit}
            noValidate>
            <div className="panel-body">
              <div className="issue-type-wrapper row-space-4">
                <h5>Choose a shift set to duplicate</h5>
                <RadioInputs {...setIdToDuplicateProps} />
              </div>
            </div>
            <div className="panel-footer">
              <div className="text-right">
                <button className="btn btn-primary btn-large">Create</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


AddShiftSetModalContainer.propTypes = {
  shiftSets: PropTypes.array,
  setIdToDuplicate: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShiftSetModalContainer)