import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const ShiftSetApiUtils = {
  fetchByTutor(tutorId) {
    return xhr(ApiConstants.Base + 'tutors/' + tutorId + "/shift_sets")
  },

  create(tutorId, params={}) {
    return xhr(ApiConstants.Base + 'tutors/' + tutorId + "/shift_sets", {
      method: "post",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        shift_set_params: params
      }),
    });
  },

  setEndDate(tutorId, shiftSetId, date) {
    return xhr(ApiConstants.Base + 'tutors/' + tutorId + "/shift_sets/" + shiftSetId, {
      method: "put",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        date
      }),
    });
  }
};

export default ShiftSetApiUtils;