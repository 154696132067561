import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as TutorBankInfoActions from '../../../actions/tutorBankInfo';
import classnames from 'classnames';
import Immutable from 'immutable';
import Collection from '../../../utils/Collection';
import TutorBankInfo from '../../../components/hr/tutor/tutorBankInfo/Home';
import appUtils from '../../../utils/appUtils';
import Helmet from 'react-helmet';
import ContentWrapper from '../../../components/hr/tutor/ContentWrapper';
import BankInfoValidator from '../../../validators/BankInfoValidator';


function mapStateToProps(state) {
  const { tutorBankInfo } = state;
  const { loading, updating, bankInfo } = tutorBankInfo;
  return {
    loading,
    bankInfo,
    updating,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tutorBankInfoActions: bindActionCreators(TutorBankInfoActions, dispatch),
  };
}


class TutorBankInfoContainer extends Component {

  constructor(props) {
    super(props);
    const { params } = props.match;
    this.state = {
      tutorId: params.id,
      id: null,
      changeAdded: false,
      tempBankName: '',
      tempBranchName: '',
      tempBranchNumber: '',
      tempNameOnAccount: '',
      tempAccountNumber: '',
      bankNameValidationResult: null,
      branchNumberValidationResult: null,
      branchNameValidationResult: null,
      nameOnAccountValidationResult: null,
      accountNumberValidationResult: null,
    };
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
    this.getErrorMessages = this.getErrorMessages.bind(this);
    this.isValid = this.isValid.bind(this);
    this.setTempInfoFromBankInfo = this.setTempInfoFromBankInfo.bind(this);
  }

  componentDidMount() {
    const { bankInfo, tutorBankInfoActions } = this.props;
    const { tutorId } = this.state;
    if (appUtils.isNull(bankInfo)) {
      tutorBankInfoActions.fetchByTutor(tutorId);
    } else if (bankInfo.tutor_id !== tutorId) {
      tutorBankInfoActions.fetchByTutor(tutorId);
    } else {
      this.setTempInfoFromBankInfo(bankInfo);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bankInfo && nextProps.bankInfo !== this.props.bankInfo) {
      this.setTempInfoFromBankInfo(nextProps.bankInfo);
    }
  }

  onUpdateInput(value, stateType) {
    const state = this.state;
    state[stateType] = value;
    state.changeAdded = true;
    this.setState(state);
  }

  setTempInfoFromBankInfo(bankInfo) {
    const {
      tempBankName,
      tempBranchName,
      tempBranchNumber,
      tempNameOnAccount,
      tempAccountNumber,
    } = this.state;
    this.setState({
      id: bankInfo.id,
      bankInfo,
      tempBankName: appUtils.isNull(bankInfo.bank_name) ? tempBankName : bankInfo.bank_name,
      tempBranchName: appUtils.isNull(bankInfo.branch_name) ? tempBranchName : bankInfo.branch_name,
      tempBranchNumber: appUtils.isNull(bankInfo.branch_number) ? tempBranchNumber : bankInfo.branch_number,
      tempNameOnAccount: appUtils.isNull(bankInfo.name_on_account) ? tempNameOnAccount : bankInfo.name_on_account,
      tempAccountNumber: appUtils.isNull(bankInfo.account_number) ? tempAccountNumber : bankInfo.account_number,
    });
  }

  onChangeBankInfo(state) {
    const bankInfo = state.bankInfo;
    this.setTempInfoFromBankInfo(bankInfo);
  }

  onSubmit(e) {
    e.preventDefault();
    const { tutorBankInfoActions } = this.props;
    const {
      id,
      tempBankName,
      tempBranchName,
      tempBranchNumber,
      tempNameOnAccount,
      tempAccountNumber,
    } = this.state;
    const params = {
      id,
      tempBankName,
      tempBranchName,
      tempBranchNumber,
      tempNameOnAccount,
      tempAccountNumber,
    };
    const bankInfoValidator = new BankInfoValidator({
      bankName: tempBankName,
      branchName: tempBranchName,
      branchNumber: tempBranchNumber,
      nameOnAccount: tempNameOnAccount,
      accountNumber: tempAccountNumber,
    });
    bankInfoValidator.validate()
    .then((result) => {
      if (result.valid) {
        tutorBankInfoActions.update(params);
      } else {
        let message = '';
        for (const err of result.errors) {
          message += `${err} `;
        }
        // ServerMessageActions.addMessage({
        //   alertType: "error",
        //   type: "bankInfoValidationFailed",
        //   message,
        //   autoDisappear: false,
        // });
      }
    });
  }

  getErrorMessages() {
    const {
      bankNameValidationResult,
      branchNumberValidationResult,
      branchNameValidationResult,
      nameOnAccountValidationResult,
      accountNumberValidationResult,
    } = this.state;
    const message =
      bankNameValidationResult.message +
      branchNameValidationResult.message +
      branchNameValidationResult.message +
      branchNumberValidationResult.message +
      nameOnAccountValidationResult.message +
      accountNumberValidationResult.message;
  }

  setStatePromise(responses) {
    const self = this;
    return new Promise((resolve) => {
      self.setState({
        bankNameValidationResult: responses[0],
        branchNameValidationResult: responses[1],
        branchNumberValidationResult: responses[2],
        nameOnAccountValidationResult: responses[3],
        accountNumberValidationResult: responses[4],
      });
      resolve('setState');
    });
  }

  isValid() {
    const {
      bankNameValidationResult,
      branchNumberValidationResult,
      branchNameValidationResult,
      nameOnAccountValidationResult,
      accountNumberValidationResult,
    } = this.state;
    return new Promise((resolve) => {
      if (bankNameValidationResult.success &&
          branchNumberValidationResult.success &&
          branchNameValidationResult.success &&
          nameOnAccountValidationResult.success &&
          accountNumberValidationResult.success) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  render() {
    const {
      bankInfo,
      loading,
      updating,
    } = this.props;
    const {
      tutorId,
      changeAdded,
      tempBankName,
      tempBranchName,
      tempBranchNumber,
      tempNameOnAccount,
      tempAccountNumber,
      bankNameValidationResult,
      branchNumberValidationResult,
      branchNameValidationResult,
      nameOnAccountValidationResult,
      accountNumberValidationResult,
    } = this.state;
    const TutorBankInfoProps = {
      bankInfo,
      loading,
      updating,
      changeAdded,
      tempBankName,
      tempBranchName,
      tempBranchNumber,
      tempNameOnAccount,
      tempAccountNumber,
      bankNameValidationResult,
      branchNumberValidationResult,
      branchNameValidationResult,
      nameOnAccountValidationResult,
      accountNumberValidationResult,
      onSubmit: this.onSubmit,
      onUpdateInput: this.onUpdateInput,
    };

    return (
      <ContentWrapper tutorId={tutorId}>
        <Helmet title="Edit Bank Info | LingualBox" />
        <TutorBankInfo {...TutorBankInfoProps} />
      </ContentWrapper>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(TutorBankInfoContainer);
