import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import ActionCell from './LessonIssueActionCell';

class HrDataByTutorsTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
    // this.getTitle = this.getTitle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  // getTitle(actionOrReason) {
  //   if (actionOrReason && actionOrReason.title) {
  //     return actionOrReason.title
  //   }
  // }

  render() {
    const items = this.props.dataList;
    const screenWidth = this.state.width
    const width = screenWidth >= 1100 ? screenWidth * 0.833 - 25 : screenWidth - 25;
    const height = 50 + items.length * 50 > 500 ? 500 : 50 + items.length * 50;
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={items.length}
        headerHeight={50}
      >
        <Column
          fixed={true}
          header={<Cell>ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].id}
            </Cell>
          )}
          width={50}
        />
        <Column
          fixed={true}
          header={<Cell>Name</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].name}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Experience</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].experienceYears}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Taught</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].hoursTaught} hours
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Average Rating</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].averageRating}
            </Cell>
          )}
          width={70}
        />
        <Column
          header={<Cell>Rate</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].salaryRate}PHP
            </Cell>
          )}
          width={70}
        />
        <Column
          header={<Cell>Num of Trials</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].numOfTrials}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Num of Subscribers</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].numOfSubscribers}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Num of Subscribers(Uniq)</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].uniqueNumOfSubscribers}
            </Cell>
          )}
          width={120}
        />
        <Column
          header={<Cell>Subscribe Rate</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].subscribeRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Completion Rate (1 Month)</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].lessonCompletionRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Completion Rate (3 Months)</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].lessonCompletionRateLast3Months) * 100).toFixed(1)}%
            </Cell>
          )}
          width={120}
        />
        <Column
          header={<Cell>Tutor-related Issue Rate</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRelatedIssueRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Issue Rate: Connection</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRelatedNetworkingIssueRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Issue Rate: Blackout</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRelatedBlackoutRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Issue Rate: Computer</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRelatedComputerProblemRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Issue Rate: Sickness</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRelatedSicknessRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Issue Rate: Family Sickness</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRalatedFamilySicknessRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Issue Rate: Family Sickness</Cell>}
          cell={props => (
            <Cell {...props}>
              {((items[props.rowIndex].tutorRelatedOtherIssuesRate) * 100).toFixed(1)}%
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Start Working</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].startWorking).format('YYYY-MM-DD')}
            </Cell>
          )}
          width={100}
        />
      </Table>
    );
  }
}

HrDataByTutorsTable.propTypes = {
  dataList: PropTypes.array,
};

export default HrDataByTutorsTable;
