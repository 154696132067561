import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table';
// import { Link } from 'react-router-dom';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

class PaymentsTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  render() {
    const payments = this.props.payments;
    let width = this.state.width - 291;
    let height = 50 + payments.length * 50 > 500 ? 500 : 50 + payments.length * 50;
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={payments.length}
        headerHeight={50}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {payments[props.rowIndex].id}
            </Cell>
          )}
          width={50}
        />
        <Column
          header={<Cell>Plan</Cell>}
          cell={props => (
            <Cell {...props}>
              {payments[props.rowIndex].plan ? payments[props.rowIndex].plan.name : ""}
            </Cell>
          )}
          width={80}
        />
        <Column
          header={<Cell>PayPal Profile ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {payments[props.rowIndex].paypal_profile_token}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Status</Cell>}
          cell={props => (
            <Cell {...props}>
              {payments[props.rowIndex].status}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>User ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {payments[props.rowIndex].user_id}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Name</Cell>}
          cell={props => (
            <Cell {...props}>
              {payments[props.rowIndex].user_name}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>CREATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(payments[props.rowIndex].created_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
        <Column
          header={<Cell>UPDATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(payments[props.rowIndex].updated_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={250}
        />
      </Table>
    );
  }
}

PaymentsTable.propTypes = {
  payments: PropTypes.array,
};

export default PaymentsTable;
