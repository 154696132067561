import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';
import checkResults from '../checkResults';

const AuthApiUtils = {

  me() {
    return xhr(ApiConstants.Me).then(checkResults);
  },

  loginWithEmail(email, password) {
    return xhr(ApiConstants.LoginWithEmail, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
  },
};

export default AuthApiUtils;
