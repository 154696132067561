import HolidayApiUtils from '../utils/api/holiday';

export const FETCH_HOLIDAYS_SUCCEEDED = 'FETCH_HOLIDAYS_SUCCEEDED';
export const FETCH_HOLIDAYS_FAILED = 'FETCH_HOLIDAYS_FAILED';

export function fetchSucceeded(data, opts) {
  return {
    type: FETCH_HOLIDAYS_SUCCEEDED,
    payload: Object.assign({}, data, {opts})
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_HOLIDAYS_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetch(opts) {
  return (dispatch) => {
    return HolidayApiUtils.fetch(opts).then(
      (data) => dispatch(fetchSucceeded(data, opts)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}
