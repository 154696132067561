import MaterialApiUtils from '../utils/api/material';
import {addMessage as addFlashMessage} from './flash';

export const FETCH_MATERIALS_SUCCEEDED = 'FETCH_MATERIALS_SUCCEEDED';
export const FETCH_MATERIALS_FAILED = 'FETCH_MATERIALS_FAILED';
export const FETCH_MATERIAL_SUCCEEDED = 'FETCH_MATERIAL_SUCCEEDED';
export const FETCH_MATERIAL_FAILED = 'FETCH_MATERIAL_FAILED';
export const UPDATE_MATERIAL_SENT = 'UPDATE_MATERIAL_SENT';
export const UPDATE_MATERIAL_SUCCEEDED = 'UPDATE_MATERIAL_SUCCEEDED';
export const UPDATE_MATERIAL_FAILED = 'UPDATE_MATERIAL_FAILED';
export const UPLOAD_MATERIAL_FILE_SENT = 'UPLOAD_MATERIAL_FILE_SENT';
export const UPLOAD_MATERIAL_FILE_SUCCEEDED = 'UPLOAD_MATERIAL_FILE_SUCCEEDED';
export const UPLOAD_MATERIAL_FILE_FAILED = 'UPLOAD_MATERIAL_FILE_FAILED';
export const DELETE_MATERIAL_FILE_SENT = 'DELETE_MATERIAL_FILE_SENT';
export const DELETE_MATERIAL_FILE_SUCCEEDED = 'DELETE_MATERIAL_FILE_SUCCEEDED';
export const DELETE_MATERIAL_FILE_FAILED = 'DELETE_MATERIAL_FILE_FAILED';

export function fetchSucceeded(data, opts) {
  return {
    type: FETCH_MATERIALS_SUCCEEDED,
    payload: Object.assign({}, data, {opts})
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_MATERIALS_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetchOneSucceeded(data) {
  return {
    type: FETCH_MATERIAL_SUCCEEDED,
    payload: data
  }
}

export function fetchOneFailed(error) {
  return {
    type: FETCH_MATERIAL_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function updateSent(data) {
  return {
    type: UPDATE_MATERIAL_SENT
  }
}

export function updateSucceeded(data) {
  return {
    type: UPDATE_MATERIAL_SUCCEEDED,
    payload: data
  }
}

export function updateFailed(error) {
  return {
    type: UPDATE_MATERIAL_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function uploadFileSent(data) {
  return {
    type: UPLOAD_MATERIAL_FILE_SENT
  }
}

export function uploadFileSucceeded(data) {
  return {
    type: UPLOAD_MATERIAL_FILE_SUCCEEDED,
    payload: data
  }
}

export function uploadFileFailed(error) {
  return {
    type: UPLOAD_MATERIAL_FILE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function deleteFileSent(data) {
  return {
    type: DELETE_MATERIAL_FILE_SENT
  }
}

export function deleteFileSucceeded(data) {
  return {
    type: DELETE_MATERIAL_FILE_SUCCEEDED,
    payload: data
  }
}

export function deleteFileFailed(error) {
  return {
    type: DELETE_MATERIAL_FILE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetch(opts) {
  return (dispatch) => {
    return MaterialApiUtils.fetch(opts).then(
      (data) => dispatch(fetchSucceeded(data, opts)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}

export function fetchOne(id) {
  return (dispatch) => {
    return MaterialApiUtils.fetchOne(id).then(
      (data) => dispatch(fetchOneSucceeded(data)),
      (error) => dispatch(fetchOneFailed(error))
    );
  };
}

export function update(id, params) {
  return (dispatch) => {
    dispatch(updateSent);
    return MaterialApiUtils.update(id, params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully updated", {autoDisappear: true, sticky: true}));
        dispatch(updateSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(updateFailed(error))
      }
    );
  };
}

export function uploadFile(id, file) {
  return (dispatch) => {
    dispatch(uploadFileSent);
    return MaterialApiUtils.uploadFile(id, file).then(
      (data) => {
        dispatch(addFlashMessage("Successfully uploaded", {autoDisappear: true, sticky: true}));
        dispatch(uploadFileSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error"}));
        dispatch(uploadFileFailed(error))
      }
    );
  };
}

export function deleteFile(id) {
  return (dispatch) => {
    dispatch(deleteFileSent);
    return MaterialApiUtils.deleteFile(id).then(
      (data) => {
        dispatch(addFlashMessage("Successfully deleted", {autoDisappear: true, sticky: true}));
        dispatch(deleteFileSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(deleteFileFailed(error))
      }
    );
  };
}