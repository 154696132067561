import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as LessonActions from '../../../actions/lesson';
import classnames from 'classnames';
import LessonDetailsBase from '../../../components/db/lessons/details/base';
import LessonDetailsSummary from '../../../components/db/lessons/details/Summary';
import LessonDetailsIssue from '../../../components/db/lessons/details/Issue';
import LessonDetailsReview from '../../../components/db/lessons/details/Review';
import LessonDetailsSidenav from '../../../components/db/lessons/details/Sidenav';
import Immutable from 'immutable';


function mapStateToProps(state) {
  const { lessonData } = state
  const { lesson, review, summary, issue } = lessonData;
  return {
    lesson,
    review,
    summary,
    issue
  }
}

function mapDispatchToProps(dispatch) {
  return {
    lessonActions: bindActionCreators(LessonActions, dispatch)
  };
}

class LessonDetailsContainer extends Component {

  componentDidMount() {
    const { match, lessonActions } = this.props;
    lessonActions.fetchOne(match.params.id);
  }

  render() {
    const { lesson, summary, review, issue } = this.props;
    let lessonDetailsBase = <div></div>;
    let lessonDetailsSummary = <div></div>;
    let lessonDetailsReview = <div></div>;
    let lessonDetailsIssue = <div></div>;
    if (lesson) {
      lessonDetailsBase = <LessonDetailsBase lesson={lesson}/>;
      lessonDetailsSummary = <LessonDetailsSummary summary={summary}/>;
      lessonDetailsReview = <LessonDetailsReview review={review}/>;
      lessonDetailsIssue = <LessonDetailsIssue issue={issue}/>;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 row-space-sm-4">
            <LessonDetailsSidenav />
          </div>
          <div className="col-md-9">
            {lessonDetailsBase}
            {lessonDetailsSummary}
            {lessonDetailsReview}
            {lessonDetailsIssue}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetailsContainer);
