import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

// const msOfOneDay = 86400000;
// const msOfHalfAnHour = 1800000;
// const TWENTY_FIVE_MINUTES = 1500000;
const FIFTY_MINUTES = 3000000;

class CalendarTimeslot extends Component {
  constructor(props) {
    super(props);
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  onMouseDown(e) {
    const {saving, onMouseDown} = this.props;
    if (!saving) {
      onMouseDown(e);
    }
  }

  render() {
    const self = this;
    const { timestamp, slotNum, slotStatus } = this.props;
    let slotClass = classnames("timeslot", 
        {red: slotStatus === "removed" && slotStatus !== "tempAdded"}, 
        {"light-red": slotStatus === "tempRemoved"},
        {"light-green": slotStatus === "tempAdded"},
        {"yellow": slotStatus === "booked"},
        {green: (slotStatus === "on" || slotStatus === "added") && slotStatus !== "tempRemoved"})
    return (
      <div 
        className={slotClass}
        data-slot-num={slotNum} 
        onMouseDown={this.onMouseDown}
      />
    );
  }
}


CalendarTimeslot.propTypes = {
  timestamp: PropTypes.number,
  status: PropTypes.object
};

export default CalendarTimeslot;
