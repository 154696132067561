import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class ModalMessage extends Component {

  render() {
    const {message, messageType} = this.props
    const alertClass = classnames("alert alert-header panel-header hidden-element notice", 
      {"alert-success": messageType === "success"},
      {"alert-error": messageType === "error"}
    )
    const transitionName = "modal-anim";
    if (message.length > 0) {
      return (
        <ReactCSSTransitionGroup transitionAppear={true}　transitionAppearTimeout={500} transitionName={transitionName} transitionEnterTimeout={500} transitionLeaveTimeout={300}>
          <div id="modal-message-container">
            <div className={alertClass}>
              <div className="icon icon-alert-alt alert-icon"></div>
              <span>{message}</span>
            </div>
          </div>
        </ReactCSSTransitionGroup>
      ); 
    } else {
      return (
        <ReactCSSTransitionGroup transitionAppear={true}　transitionAppearTimeout={500} transitionName={transitionName} transitionEnterTimeout={500} transitionLeaveTimeout={300}>
          <div id="modal-message-container"/>
        </ReactCSSTransitionGroup>
      );
    }
  }
}

ModalMessage.contextTypes = {
  message: PropTypes.string,
  messageType: PropTypes.string
}

export default ModalMessage;