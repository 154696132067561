import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const ScheduleApiUtils = {

  createMany(tutorId, params={}) {
    return xhr(ApiConstants.Base + 'tutors/' + tutorId + "/schedules", {
      method: "post",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        start_time: params.startTime,
        add_type_schedules: params.newAddTypeSchedules,
        remove_type_schedules: params.newRemoveTypeSchedules
      })
    });
  }
};

export default ScheduleApiUtils;