import React, {Component} from 'react';
import classnames from 'classnames';

class Select extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  _onKeyDown(e) {
    if (e.keyCode === 13) {
      this.props.onKeyDown(e);
    }
  }


  handleChange(e) {
    e.preventDefault();
    const {updateHandler, stateType} = this.props
    if (stateType) {
      updateHandler(e.target.value, stateType);
    } else {
      updateHandler(e.target.value);
    }
  }


  render() {
    let options = this.props.options.map((opt) => {
      return (<option key={opt.key} value={opt.value}>{opt.name}</option>)
    })
    let validationResult = this.props.validationResult
    let className;
    if (validationResult && !validationResult.success) {
      className = classnames(this.props.className, 'invalid')
    } else {
      className = this.props.className
    }
    let wrapperClass = "select";
    if (this.props.smBlockClass) {
      wrapperClass = "select sm-select-block"
    }
    return (
      <div className={wrapperClass}>
        <select id={this.props.id} className={className} value={this.props.value} onChange={this.handleChange} onKeyDown={this._onKeyDown.bind(this)}>
          {options}
        </select>
      </div>
    );
  }
}

export default Select;