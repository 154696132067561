import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Column, Cell } from 'fixed-data-table';
// import { Link } from 'react-router-dom';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';

class HolidaysTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  render() {
    const items = this.props.items;
    const screenWidth = this.state.width
    const width = screenWidth >= 1100 ? screenWidth * 0.833 - 25 : screenWidth - 25;
    const height = 50 + items.length * 50 > 500 ? 500 : 50 + items.length * 50;
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={items.length}
        headerHeight={50}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].id}
            </Cell>
          )}
          width={50}
        />
        <Column
          header={<Cell>Name</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].name}
            </Cell>
          )}
          width={250}
        />
        <Column
          header={<Cell>Date</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].date}
            </Cell>
          )}
          width={100}
        />
      </Table>
    );
  }
}

HolidaysTable.propTypes = {
  items: PropTypes.array,
};

export default HolidaysTable;
