import TutorApiUtils from '../utils/api/tutor';
import UserApiUtils from '../utils/api/user';
import {addMessage as addFlashMessage} from './flash';

export const FETCH_TUTORS_SUCCEEDED = 'FETCH_TUTORS_SUCCEEDED';
export const FETCH_TUTORS_FAILED = 'FETCH_TUTORS_FAILED';
export const FETCH_TUTOR_SUCCEEDED = 'FETCH_TUTOR_SUCCEEDED';
export const FETCH_TUTOR_FAILED = 'FETCH_TUTOR_FAILED';
export const CREATE_TUTOR_SENT = 'CREATE_TUTOR_SENT';
export const CREATE_TUTOR_SUCCEEDED = 'CREATE_TUTOR_SUCCEEDED';
export const CREATE_TUTOR_FAILED = 'CREATE_TUTOR_FAILED';
export const UPDATE_TUTOR_SENT = 'UPDATE_TUTOR_SENT';
export const UPDATE_TUTOR_SUCCEEDED = 'UPDATE_TUTOR_SUCCEEDED';
export const UPDATE_TUTOR_FAILED = 'UPDATE_TUTOR_FAILED';
export const FETCH_DATA_FOR_SCHEDULES_CAL_SENT = 'FETCH_DATA_FOR_SCHEDULES_CAL_SENT';
export const FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED = 'FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED';
export const FETCH_DATA_FOR_SCHEDULES_CAL_FAILED = 'FETCH_DATA_FOR_SCHEDULES_CAL_FAILED';
export const UPLOAD_TUTOR_AVATAR_SENT = 'UPLOAD_TUTOR_AVATAR_SENT';
export const UPLOAD_TUTOR_AVATAR_SUCCEEDED = 'UPLOAD_TUTOR_AVATAR_SUCCEEDED';
export const UPLOAD_TUTOR_AVATAR_FAILED = 'UPLOAD_TUTOR_AVATAR_FAILED';
export const DELETE_TUTOR_AVATAR_SENT = 'DELETE_TUTOR_AVATAR_SENT';
export const DELETE_TUTOR_AVATAR_SUCCEEDED = 'DELETE_TUTOR_AVATAR_SUCCEEDED';
export const DELETE_TUTOR_AVATAR_FAILED = 'DELETE_TUTOR_AVATAR_FAILED';

export function fetchSucceeded(data, opts) {
  return {
    type: FETCH_TUTORS_SUCCEEDED,
    payload: Object.assign({}, data, {opts})
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_TUTORS_FAILED
  }
}

export function fetchOneSucceeded(data) {
  return {
    type: FETCH_TUTOR_SUCCEEDED,
    payload: data
  }
}

export function fetchOneFailed(error) {
  return {
    type: FETCH_TUTOR_FAILED
  }
}

export function createSent() {
  return {
    type: CREATE_TUTOR_SENT
  }
}

export function createSucceeded(data) {
  return {
    type: CREATE_TUTOR_SUCCEEDED,
    payload: data
  }
}

export function createFailed(error) {
  return {
    type: CREATE_TUTOR_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function create(params) {
  return (dispatch) => {
    dispatch(createSent());
    return UserApiUtils.createTutor(params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully created", {autoDisappear: true, sticky: true}));
        dispatch(createSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(createFailed(error))
      }
    );
  };
}

export function updateSucceeded(data) {
  return {
    type: UPDATE_TUTOR_SUCCEEDED,
    payload: data
  }
}

export function updateFailed(error) {
  return {
    type: UPDATE_TUTOR_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetchDataForSchedulesCalSent(startTime) {
  return {
    type: FETCH_DATA_FOR_SCHEDULES_CAL_SENT,
    payload: {
      startTime
    }
  }
}

export function fetchDataForSchedulesCalSucceeded(data) {
  return {
    type: FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED,
    payload: data
  }
}

export function fetchDataForSchedulesCalFailed(error) {
  return {
    type: FETCH_DATA_FOR_SCHEDULES_CAL_FAILED
  }
}

export function uploadAvatarSent(data) {
  return {
    type: UPLOAD_TUTOR_AVATAR_SENT
  }
}

export function uploadAvatarSucceeded(data) {
  return {
    type: UPLOAD_TUTOR_AVATAR_SUCCEEDED,
    payload: data
  }
}

export function uploadAvatarFailed(error) {
  return {
    type: UPLOAD_TUTOR_AVATAR_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function deleteAvatarSent(data) {
  return {
    type: DELETE_TUTOR_AVATAR_SENT
  }
}

export function deleteAvatarSucceeded(data) {
  return {
    type: DELETE_TUTOR_AVATAR_SUCCEEDED,
    payload: data
  }
}

export function deleteAvatarFailed(error) {
  return {
    type: DELETE_TUTOR_AVATAR_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetch(opts) {
  return (dispatch) => {
    return TutorApiUtils.fetch(opts).then(
      (data) => dispatch(fetchSucceeded(data, opts)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}

export function fetchOne(id) {
  return (dispatch) => {
    return TutorApiUtils.fetchOne(id).then(
      (data) => dispatch(fetchOneSucceeded(data)),
      (error) => dispatch(fetchOneFailed(error))
    );
  };
}

export function update(tutorId, params) {
  return (dispatch) => {
    return UserApiUtils.updateTutor(tutorId, params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully updated", {autoDisappear: true, sticky: true}));
        dispatch(updateSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(updateFailed(error))
      }
    );
  };
}

export function fetchDataForSchedulesCal(tutorId, startTime) {
  return (dispatch) => {
    dispatch(fetchDataForSchedulesCalSent(startTime));
    return TutorApiUtils.fetchDataForSchedulesCal(tutorId, startTime).then(
      (data) => dispatch(fetchDataForSchedulesCalSucceeded(data)),
      (error) => dispatch(fetchDataForSchedulesCalFailed(error))
    );
  };
}

export function uploadAvatar(userId, file) {
  return (dispatch) => {
    dispatch(uploadAvatarSent());
    return UserApiUtils.uploadAvatar(userId, file).then(
      (data) => {
        dispatch(addFlashMessage("Successfully uploaded", {autoDisappear: true, sticky: true}));
        dispatch(uploadAvatarSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error"}));
        dispatch(uploadAvatarFailed(error))
      }
    );
  };
}

export function deleteAvatar(userId) {
  return (dispatch) => {
    dispatch(deleteAvatarSent());
    return UserApiUtils.deleteAvatar(userId).then(
      (data) => {
        dispatch(addFlashMessage("Successfully deleted", {autoDisappear: true, sticky: true}));
        dispatch(deleteAvatarSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(deleteAvatarFailed(error))
      }
    );
  };
}


