import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as UserActions from '../../../actions/user';
import classnames from 'classnames';
import Users from '../../../components/db/users/Users';
import Immutable from 'immutable';
import qs from 'query-string';
import moment from 'moment-timezone';

function mapStateToProps(state) {
  const { user } = state
  const { users, count, lastSearchOpts } = user
  return {
    users,
    count,
    lastSearchOpts
  }
}

function mapDispatchToProps(dispatch) {
  return { userActions: bindActionCreators(UserActions, dispatch)};
}

class UsersTableContainer extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      page: parseInt(query.page) || 1,
      changeAdded: false,
      chosenSortOption: parseInt(query.sortOption) || 0,
      chosenLocale: parseInt(query.locale) || 0,
      chosenPlan: parseInt(query.plan) || 0,
      chosenPlanStatus: parseInt(query.planStatus) || 0,
      chosenProfile: parseInt(query.profile) || 0,
      nameOrEmail: query.nameOrEmail || "",
      durationStart: query.durationStart ? moment.unix(Number(query.durationStart) / 1000) : null,
      durationEnd: query.durationEnd ? moment.unix(Number(query.durationEnd) / 1000) : null,
    };
    this.handleUpdateSortOption = this.handleUpdateSortOption.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleUpdateNameOrEmail = this.handleUpdateNameOrEmail.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleUpdateLocale = this.handleUpdateLocale.bind(this);
    this.handleUpdatePlan = this.handleUpdatePlan.bind(this);
    this.handleUpdatePlanStatus = this.handleUpdatePlanStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getOptsFromCurrentState = this.getOptsFromCurrentState.bind(this);
    this.handleUpdateDuration = this.handleUpdateDuration.bind(this);
  }

  getOptsFromCurrentState() {
    const {
      page,
      chosenSortOption,
      chosenLocale,
      chosenPlan,
      chosenPlanStatus,
      chosenProfile,
      nameOrEmail,
      durationStart,
      durationEnd,
    } = this.state;
    const opts = {
      page,
      profile: chosenProfile,
      sortOption: chosenSortOption,
      locale: chosenLocale,
      plan: chosenPlan,
      planStatus: chosenPlanStatus,
      nameOrEmail: nameOrEmail,
      durationStart,
      durationEnd,
    };
    return opts;
  }

  componentWillMount() {
    const {userActions, users, lastSearchOpts} = this.props;
    const currentOpts = Immutable.Map(this.getOptsFromCurrentState());
    if (!Immutable.is(currentOpts,lastSearchOpts) || users.length === 0) {
      userActions.fetch(currentOpts.toObject());
    }
  }

  handleUpdatePlan(value) {
    this.setState({
      chosenPlan: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdatePlanStatus(value) {
    this.setState({
      chosenPlanStatus: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateLocale(value) {
    this.setState({
      chosenLocale: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateProfile(value) {
    this.setState({
      chosenProfile: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdatePage(value) {
    this.setState({
      page: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateSortOption(value) {
    this.setState({
      chosenSortOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateNameOrEmail(value) {
    this.setState({
      nameOrEmail: value,
      changeAdded: true,
    });
  }

  handleUpdateDuration(dates) {
    this.setState({
      durationStart: dates.startDate,
      durationEnd: dates.endDate,
      changeAdded: true,
    });
  }

  handleSubmit() {
    const {
      userActions
    } = this.props
    const {
      page,
      chosenSortOption,
      chosenLocale,
      chosenPlan,
      chosenPlanStatus,
      chosenProfile,
      nameOrEmail,
      durationStart,
      durationEnd,
    } = this.state;
    const opts = {
      page,
      profile: chosenProfile,
      sortOption: chosenSortOption,
      locale: chosenLocale,
      plan: chosenPlan,
      planStatus: chosenPlanStatus,
      nameOrEmail: nameOrEmail,
      durationStart,
      durationEnd,
    };
    this.changeUrl(opts);
    userActions.fetch(opts);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    let first = true;
    let query = '?';
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          query += '&'
        }
        query += `${key}=${opts[key]}`;
        first = false;
      }
      baseUrl += query
    }
    this.props.history.push(this.props.match.path + query);
    window.scroll(0, 0);
  }

  render() {
    const {
      users,
      count
    } = this.props;
    const {
      page,
      changeAdded,
      chosenSortOption,
      chosenLocale,
      chosenPlan,
      chosenPlanStatus,
      chosenProfile,
      nameOrEmail,
      durationStart,
      durationEnd,
    } = this.state;
    return (
      <Users
        users={users}
        count={count}
        page={page}
        changeAdded={changeAdded}
        chosenSortOption={chosenSortOption}
        chosenLocale={chosenLocale}
        chosenPlan={chosenPlan}
        chosenPlanStatus={chosenPlanStatus}
        chosenProfile={chosenProfile}
        nameOrEmail={nameOrEmail}
        handleUpdatePage={this.handleUpdatePage}
        handleUpdateLocale={this.handleUpdateLocale}
        handleUpdateProfile={this.handleUpdateProfile}
        handleUpdatePlan={this.handleUpdatePlan}
        handleUpdatePlanStatus={this.handleUpdatePlanStatus}
        handleUpdateSortOption={this.handleUpdateSortOption}
        handleUpdateNameOrEmail={this.handleUpdateNameOrEmail}
        handleSubmit={this.handleSubmit}
        onUpdateDuration={this.handleUpdateDuration}
        durationStart={durationStart}
        durationEnd={durationEnd} />
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableContainer);
