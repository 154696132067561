import CourseApiUtils from '../utils/api/course';

export const FETCH_UNITS_SUCCEEDED = 'FETCH_UNITS_SUCCEEDED';
export const FETCH_UNITS_FAILED = 'FETCH_UNITS_FAILED';

export function fetchSucceeded(data) {
  return {
    type: FETCH_UNITS_SUCCEEDED,
    payload: data
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_UNITS_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetch(courseId) {
  return (dispatch) => {
    return CourseApiUtils.fetchUnits(courseId).then(
      (data) => dispatch(fetchSucceeded(data)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}
