import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as TutorActions from '../../../actions/tutor';
import classnames from 'classnames';
import Tutors from '../../../components/hr/tutors/Tutors';
import Immutable from 'immutable';
import qs from 'query-string';

function mapStateToProps(state) {
  const { tutor } = state
  const { tutors, count, lastSearchOpts } = tutor
  return {
    tutors,
    count,
    lastSearchOpts
  }
}

function mapDispatchToProps(dispatch) {
  return { tutorActions: bindActionCreators(TutorActions, dispatch)};
}

class TutorsContainer extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      page: parseInt(query.page) || 1,
      changeAdded: false,
      chosenStatus: query.status || "active",
      chosenSortOption: parseInt(query.sortOption) || 0,
      nameOrEmail: query.nameOrEmail || ""
    };
    this.handleUpdateSortOption = this.handleUpdateSortOption.bind(this);
    this.handleUpdateNameOrEmail = this.handleUpdateNameOrEmail.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getOptsFromCurrentState = this.getOptsFromCurrentState.bind(this);
  }

  getOptsFromCurrentState() {
    const {
      page,
      chosenSortOption,
      chosenStatus,
      nameOrEmail
    } = this.state;
    const opts = {
      page,
      sortOption: chosenSortOption,
      status: chosenStatus,
      nameOrEmail
    };
    return opts;
  }

  componentWillMount() {
    const {tutorActions, tutors, lastSearchOpts} = this.props;
    const currentOpts = Immutable.Map(this.getOptsFromCurrentState());
    if (!Immutable.is(currentOpts,lastSearchOpts) || tutors.length === 0) {
      tutorActions.fetch(currentOpts.toObject());
    }
  }


  handleUpdatePage(value) {
    this.setState({
      page: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateSortOption(value) {
    this.setState({
      chosenSortOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateStatus(value) {
    this.setState({
      chosenStatus: value,
      changeAdded: true,
    });
  }

  handleUpdateNameOrEmail(value) {
    this.setState({
      nameOrEmail: value,
      changeAdded: true,
    });
  }

  handleSubmit() {
    const {
      tutorActions
    } = this.props
    const opts = this.getOptsFromCurrentState();
    this.changeUrl(opts);
    tutorActions.fetch(opts);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    baseUrl += '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          baseUrl += '&';
        }
        baseUrl += `${key}=${opts[key]}`;
        first = false;
      }
    }
    window.history.pushState(opts, '', baseUrl);
    window.scroll(0, 0);
  }

  render() {
    const {
      tutors,
      count
    } = this.props;
    const {
      page,
      changeAdded,
      chosenSortOption,
      chosenStatus,
      nameOrEmail,
    } = this.state;
    return (
      <Tutors
        tutors={tutors}
        count={count}
        page={page}
        changeAdded={changeAdded}
        chosenSortOption={chosenSortOption}
        chosenStatus={chosenStatus}
        nameOrEmail={nameOrEmail}
        handleUpdatePage={this.handleUpdatePage}
        handleUpdateSortOption={this.handleUpdateSortOption}
        handleUpdateNameOrEmail={this.handleUpdateNameOrEmail}
        handleUpdateStatus={this.handleUpdateStatus}
        handleSubmit={this.handleSubmit} />
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(TutorsContainer);
