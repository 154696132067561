import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class TextInput extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }

  _onKeyDown(e) {
    if (e.keyCode === 13) {
      this.props.onKeyDown(e);
    }
  }

  handleChange(e) {
    e.preventDefault();
    const {updateHandler, stateType} = this.props
    if (stateType) {
      updateHandler(e.target.value, stateType);
    } else {
      updateHandler(e.target.value);
    }
  }


  render() {
    let className, ErrorLabel;
    if (this.props.validationResult && this.props.validationResult.success === false) {
      if(this.props.invalidLabel) {
        ErrorLabel = (<label className="invalid">{this.props.validationResult.message}</label>);
      }
      className = classnames(this.props.className, 'invalid')
    } else {
      className = this.props.className
    }
    return (
      <div>
        {ErrorLabel}
        <input type={this.props.type} name={this.props.name} value={this.props.value} onChange={this.handleChange} onKeyDown={this._onKeyDown.bind(this)} className={className} placeholder={this.props.placeholder} />
      </div>
    );
  }
};

TextInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  updateHandler: PropTypes.func.isRequired
}

TextInput.defaultProps = {
  type: 'text',
  placeholder: '',
}




export default TextInput;