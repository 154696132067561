import React, {Component} from 'react';
import PropTypes from 'prop-types';

const SimpleLayer = (props) => <div className="simple-layer" onClick={props.onClick} />;

SimpleLayer.propTypes = {
  onClick: PropTypes.func,
};

export default SimpleLayer;

