import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Calendar from './Calendar';
import moment from 'moment-timezone';

class AdminHrShiftsCalendarContainer extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      startTime: moment().tz("Asia/Manila").startOf('week'),
    }
  }

  getBeginningOfWeek(time=null) {
    if (time) {
      return moment(time).tz("Asia/Manila").startOf('week');
    } else {
      return moment().tz("Asia/Manila").startOf('week');
    }
  }

  render() {
    // const timeslotDivStyle = { cellpadding: 0 };
    let calendarProps = {};
    const {startTime} = this.state;
    const {
      shifts, 
      loading, 
      editing, 
      saving,
      editType,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet,
    } = this.props;
    calendarProps = {
      startTime,
      shifts,
      loading,
      editing,
      saving,
      editType,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet,
    };
    const panelBodyClass = loading ? 'panel-body loading panel-body-no-padding row-space-top-4' : 'panel-body panel-body-no-padding row-space-top-4';
    return (
      <div className="panel row-space-top-4">
        <div className="calendar-content">
          <div className={panelBodyClass}>
            <div className="cal week-scroll">
              <div className="cal-container">
                <Calendar {...calendarProps} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default AdminHrShiftsCalendarContainer;
