import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as ShiftActions from '../../../actions/shift';
import classnames from 'classnames';
import moment from 'moment-timezone';
import ContentWrapper from '../../../components/hr/ContentWrapper';
import Immutable from 'immutable';
import Calendar from '../../../components/hr/shifts/Calendar';

function mapStateToProps(state) {
  const { shift } = state;
  const { shifts, loading, lessonNumsOfSlots } = shift;
  return {
    shifts,
    lessonNumsOfSlots,
    loading
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    shiftActions: bindActionCreators(ShiftActions, dispatch)
  };
}


class AdminHrShiftsCalendarContainer extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      startTime: moment().tz("Asia/Manila").startOf('week'),
    }
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    const { startTime } = this.state;
    this.props.shiftActions.fetch(startTime);
  }

  getBeginningOfWeek(time=null) {
    if (time) {
      return moment(time).tz("Asia/Manila").startOf('week');
    } else {
      return moment().tz("Asia/Manila").startOf('week');
    }
  }

  hideModal() {
    this.props.handleHideModal();
  }

  render() {
    // const timeslotDivStyle = { cellpadding: 0 };
    let calendarProps = {};
    const {shifts, lessonNumsOfSlots, loading} = this.props;
    const {startTime} = this.state;
    calendarProps = {
      startTime,
      shifts,
      lessonNumsOfSlots,
      loading
    };
    const panelBodyClass = classnames('panel-body panel-body-no-padding row-space-top-4', {loading});
    return (
      <ContentWrapper>
        <div className="panel">
          <div className="calendar-content">
            <div className={panelBodyClass}>
              <div className="cal week-scroll">
                <div className="cal-container">
                  <Calendar {...calendarProps} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHrShiftsCalendarContainer);
