import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';

const HolidayApiUtils = {
  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.FetchHolidays + query);
  },
};

export default HolidayApiUtils;
