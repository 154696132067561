import ShiftSetApiUtils from '../utils/api/shiftSet';
import {addMessage as addFlashMessage} from './flash';
import * as ModalActions from './modal';

export const FETCH_SHIFT_SETS_BY_TUTOR_SENT = 'FETCH_SHIFT_SETS_BY_TUTOR_SENT';
export const FETCH_SHIFT_SETS_BY_TUTOR_SUCCEEDED = 'FETCH_SHIFT_SETS_BY_TUTOR_SUCCEEDED';
export const FETCH_SHIFT_SETS_BY_TUTOR_FAILED = 'FETCH_SHIFT_SETS_BY_TUTOR_FAILED';
export const CREATE_SHIFT_SET_SENT = 'CREATE_SHIFT_SET_SENT';
export const CREATE_SHIFT_SET_SUCCEEDED = 'CREATE_SHIFT_SET_SUCCEEDED';
export const CREATE_SHIFT_SET_FAILED = 'CREATE_SHIFT_SET_FAILED';
export const SET_SHIFT_SET_END_DATE_SENT = 'SET_SHIFT_SET_END_DATE_SENT';
export const SET_SHIFT_SET_END_DATE_SUCCEEDED = 'SET_SHIFT_SET_END_DATE_SUCCEEDED';
export const SET_SHIFT_SET_END_DATE_FAILED = 'SET_SHIFT_SET_END_DATE_FAILED';
export const UPDATE_CHOSEN_SHIFT_SET_ID = 'UPDATE_CHOSEN_SHIFT_SET_ID';
export const UPDATE_DURATION_END_OF_FORMER_SET = 'UPDATE_DURATION_END_OF_FORMER_SET';
export const UPDATE_DURATION_START_OF_NEW_SET = 'UPDATE_DURATION_START_OF_NEW_SET';
export const UPDATE_DURATION_END_OF_NEW_SET = 'UPDATE_DURATION_END_OF_NEW_SET';
export const UPDATE_DURATION_OF_NEW_SET = 'UPDATE_DURATION_OF_NEW_SET';
export const UPDATE_DURATION_END_OF_CHOSEN_SET = 'UPDATE_DURATION_END_OF_CHOSEN_SET';
export const UPDATE_SHIFT_SET_ID_TO_DUPLICATE = 'UPDATE_SHIFT_SET_ID_TO_DUPLICATE';
export const UPDATE_SHIFT_SET_ID_TO_EDIT = 'UPDATE_SHIFT_SET_ID_TO_EDIT';
export const CANCEL_CREATING_NEW_SHIFT_SET = 'CANCEL_CREATING_NEW_SHIFT_SET';
export const INITIATE_NEW_SHIFT_SET = 'INITIATE_NEW_SHIFT_SET';
export const UPDATE_NEW_SHIFT_SET_SHIFTS = 'UPDATE_NEW_SHIFT_SET_SHIFTS';
export const CHANGE_SHIFT_SET_EDIT_TYPE = 'CHANGE_SHIFT_SET_EDIT_TYPE';

export function fetchByTutorSent() {
  return {
    type: FETCH_SHIFT_SETS_BY_TUTOR_SENT
  }
}

export function fetchByTutorSucceeded(data) {
  return {
    type: FETCH_SHIFT_SETS_BY_TUTOR_SUCCEEDED,
    payload: data
  }
}

export function fetchByTutorFailed(error) {
  return {
    type: FETCH_SHIFT_SETS_BY_TUTOR_FAILED
  }
}

export function createSent() {
  return {
    type: CREATE_SHIFT_SET_SENT
  }
}

export function createSucceeded(data) {
  return {
    type: CREATE_SHIFT_SET_SUCCEEDED,
    payload: data
  }
}

export function createFailed(error) {
  return {
    type: CREATE_SHIFT_SET_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function setEndDateSent() {
  return {
    type: SET_SHIFT_SET_END_DATE_SENT
  }
}

export function setEndDateSucceeded(data) {
  return {
    type: SET_SHIFT_SET_END_DATE_SUCCEEDED,
    payload: data
  }
}

export function setEndDateFailed(error) {
  return {
    type: SET_SHIFT_SET_END_DATE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function updateChosenShiftSetId(shiftSetId) {
  return {
    type: UPDATE_CHOSEN_SHIFT_SET_ID,
    payload: {
      id: shiftSetId
    }
  }
}

export function updateSetIdToEdit(shiftSetId) {
  return {
    type: UPDATE_SHIFT_SET_ID_TO_EDIT,
    payload: {
      id: shiftSetId
    }
  }
}

export function updateDurationEndOfFormerSet(value) {
  return {
    type: UPDATE_DURATION_END_OF_FORMER_SET,
    payload: {
      value
    }
  }
}

export function updateDurationStartOfNewSet(value) {
  return {
    type: UPDATE_DURATION_START_OF_NEW_SET,
    payload: {
      value
    }
  }
}

export function updateDurationEndOfNewSet(value) {
  return {
    type: UPDATE_DURATION_END_OF_NEW_SET,
    payload: {
      value
    }
  }
}

export function updateDurationOfNewSet(dates) {
  return {
    type: UPDATE_DURATION_OF_NEW_SET,
    payload: {
      dates
    }
  }
}

export function updateDurationEndOfChosenSet(value) {
  return {
    type: UPDATE_DURATION_END_OF_CHOSEN_SET,
    payload: {
      value
    }
  }
}

export function updateShiftSetIdToDuplicate(id) {
  return {
    type: UPDATE_SHIFT_SET_ID_TO_DUPLICATE,
    payload: {
      id
    }
  }
}

export function initiateNewShiftSet(newShiftSetShifts) {
  return {
    type: INITIATE_NEW_SHIFT_SET,
    payload: {
      newShiftSetShifts
    }
  }
}

export function updateNewShiftSetShifts(newShiftSetShifts) {
  return {
    type: UPDATE_NEW_SHIFT_SET_SHIFTS,
    payload: {
      newShiftSetShifts
    }
  }
}

export function cancelCreatingNewShiftSet() {
  return { type: CANCEL_CREATING_NEW_SHIFT_SET }
}

export function changeEditType(value) {
  return {
    type: CHANGE_SHIFT_SET_EDIT_TYPE,
    payload: {
      editType: value
    }
  }
}

export function fetchByTutor(tutorId) {
  return (dispatch) => {
    dispatch(fetchByTutorSent());
    return ShiftSetApiUtils.fetchByTutor(tutorId).then(
      (data) => dispatch(fetchByTutorSucceeded(data)),
      (error) => dispatch(fetchByTutorFailed(error))
    );
  };
}

export function create(tutorId, params) {
  return (dispatch) => {
    dispatch(createSent());
    return ShiftSetApiUtils.create(tutorId, params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully created", {autoDisappear: true, sticky: true}));
        dispatch(createSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(createFailed(error))
      }
    );
  };
}

export function setEndDate(tutorId, shiftSetId, date) {
  return (dispatch) => {
    dispatch(setEndDateSent());
    return ShiftSetApiUtils.setEndDate(tutorId, shiftSetId, date).then(
      (data) => {
        dispatch(ModalActions.hide());
        dispatch(addFlashMessage("Successfully updated", {autoDisappear: true, sticky: true}));
        dispatch(setEndDateSucceeded(data))
      },
      (error) => {
        dispatch(ModalActions.addMessage(error.message, {autoDisappear: false, type: "error"}));
        dispatch(setEndDateFailed(error))
      }
    );
  };
}







