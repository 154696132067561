import Collection from '../utils/Collection.js';

import {
  FETCH_TUTOR_SUCCEEDED,
  FETCH_TUTOR_FAILED,
  CREATE_TUTOR_SENT,
  CREATE_TUTOR_SUCCEEDED,
  CREATE_TUTOR_FAILED,
  UPDATE_TUTOR_SENT,
  UPDATE_TUTOR_SUCCEEDED,
  UPDATE_TUTOR_FAILED,
  UPLOAD_TUTOR_AVATAR_SENT,
  UPLOAD_TUTOR_AVATAR_SUCCEEDED,
  UPLOAD_TUTOR_AVATAR_FAILED,
  DELETE_TUTOR_AVATAR_SENT,
  DELETE_TUTOR_AVATAR_SUCCEEDED,
  DELETE_TUTOR_AVATAR_FAILED
} from '../actions/tutor';


const initialState = {
  creating: false, 
  updating: false,
  avatarProcessing: false,
  tutor: null
}

export default function tutorDataReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_TUTOR_SUCCEEDED:
      return {
        ...state, 
        tutor: payload.tutor,
      };
    case UPDATE_TUTOR_SUCCEEDED:
    case UPLOAD_TUTOR_AVATAR_SUCCEEDED:
    case DELETE_TUTOR_AVATAR_SUCCEEDED:
      return {
        ...state, 
        tutor: payload.user,
        updating: false,
        avatarProcessing: false
      };
    case CREATE_TUTOR_SUCCEEDED:
      return {
        ...state, 
        tutor: payload.tutor,
        creating: false
      };
    case CREATE_TUTOR_SUCCEEDED:
      return {
        ...state, 
        creating: false
      };
    case UPDATE_TUTOR_FAILED:
      return {
        ...state,
        updating: false
      }
    case UPLOAD_TUTOR_AVATAR_FAILED:
    case DELETE_TUTOR_AVATAR_FAILED:
      return {
        ...state,
        avatarProcessing: false
      }
    case CREATE_TUTOR_SENT:
      return {
        ...state, 
        creating: true
      };
    case UPDATE_TUTOR_SENT:
      return {
        ...state,
        updating: true
      }
    case UPLOAD_TUTOR_AVATAR_SENT:
    case DELETE_TUTOR_AVATAR_SENT:
      return {
        ...state,
        avatarProcessing: true
      }
    default:
      return state;
  }
}