import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import UserMenu from './UserMenu.js';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      show_user_menu: false,
    };
    this.handleMouseEnterUserMenu = this.handleMouseEnterUserMenu.bind(this);
    this.handleMouseLeaveUserMenu = this.handleMouseLeaveUserMenu.bind(this);
  }

  handleMouseEnterUserMenu() {
    this.setState({ show_user_menu: true });
  }

  handleMouseLeaveUserMenu() {
    this.setState({ show_user_menu: false });
  }

  renderUserMenu() {
    const { onClickLogout } = this.props;
    return (
      <ul className="medium-right-margin tooltip tooltip-top-right dropdown-menu user-menu list-unstyled header-dropdown">
        <li className="show-sm">
          <Link to="/a/db" className="no-crawl link-reset menu-item item-dashboard">DB</Link>
          <Link to="/a/hr" className="no-crawl link-reset menu-item item-dashboard">HR</Link>
        </li>
        <li>
          <a className="no-crawl link-reset menu-item item-dashboard" onClick={onClickLogout}>Log out</a>
        </li>
      </ul>
    );
  }

  render() {
    let userMenu;
    if (this.state.show_user_menu) {
      userMenu = this.renderUserMenu();
    } else {
      userMenu = <div />;
    }
    return (
      <div id="header" className="lb-header">
        <div className="admin-container">
          <div className="flex-row flex-items-start">
            <div className="flex-ext">
              <div className="flex-items-start">
                <div className="flex-ext">
                  <div className="flex-items-start nav-items">
                    <span className="nav-item"><Link to="/" className="link-reset"><div id="logo" alt="LingualBox Logo"></div></Link></span>
                    <span className="nav-item hide-sm"><Link to="/a/db" className="link-reset">DB</Link></span>
                    <span className="nav-item hide-sm"><Link to="/a/hr" className="link-reset">HR</Link></span>
                  </div>
                </div>
                <div className="flex-items-start nav-items">
                  <span className="nav-item dropdown-trigger" onMouseEnter={this.handleMouseEnterUserMenu} onMouseLeave={this.handleMouseLeaveUserMenu}>
                    <a className="link-reset"><span>{this.props.user.first_name}</span><i className="fa fa-caret-down icon-gray"/></a>
                    {userMenu}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
