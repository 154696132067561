import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import Select from '../../form/Select.js';
import TextInput from '../../form/TextInput.js';

class LessonIssueFilter extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getStatusOptions = this.getStatusOptions.bind(this);
    this.getSortOptions = this.getSortOptions.bind(this);
    this.getTypeOptions = this.getTypeOptions.bind(this);
    this.getReasonOptions = this.getReasonOptions.bind(this);
    this.getPageOptions = this.getPageOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFocusOnDurationChange = this.onFocusOnDurationChange.bind(this);
    this.state = {
      focusedOnRangePicker: null,
    };
  }

  onFocusOnDurationChange(focused) {
    this.setState({
      focusedOnRangePicker: focused,
    });
  }

  getOptions(keyBase, baseOpts) {
    let options = [];
    baseOpts.map((val, index) => {
      options.push({ key: keyBase + index, name: val, value: index });
    });
    return options;
  }

  getPageOptions() {
    const count = this.props.count;
    const raw_pages = count / 100;
    if (count === 0 || raw_pages <= 1) {
      return [{ key: 'user-page-1', name: 'page1', value: 1 }]
    }
    const floored = Math.floor(raw_pages);
    const totalPages = raw_pages === floored > 0 ? floored + 1 : raw_pages;
    let options = [];
    for (let i = 1; i < totalPages; i++) {
      options.push({ key: 'lesson-issues-page-' + i, name: 'page' + i, value: i });
    }
    return options;
  }

  getStatusOptions() {
    const base = ['Select Status', 'Open', 'Resolved'];
    return this.getOptions('issue-status-', base);
  }

  getTypeOptions() {
    const base = [
      'Select Type',
      'Student was absent',
      "Student was late",
      "Student left early",
      "Teacher was absent",
      "Teacher was late",
      "Teacher left early",
      "Student-related technical problem",
      "Tutor-related technical problem",
      "LingualBox-related technical problem",
      "Teacher cancelled",
      "Other"
    ];
    return this.getOptions('issue-type-', base);
  }

  getReasonOptions() {
    const base = [
      'Select Reason',
      'Cancel on behalf of student',
      'Unstable internet connection',
      'Blackout',
      "Computer problem",
      "Sickness",
      "Taking care of sick family member",
      "Other tutor-side reason",
      "Other"
    ];
    return this.getOptions('issue-reason-', base);
  }

  getSortOptions() {
    const base = ['Sort by created at', 'Sort by lesson start', 'Sort by reported', 'Sort by resolved'];
    return this.getOptions('issue-sort-', base);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    const {
      focusedOnRangePicker,
    } = this.state;
    const {
      changeAdded,
      handleUpdateSortOption,
      chosenSortOption,
      handleUpdateStatus,
      chosenStatus,
      handleUpdateType,
      chosenType,
      handleUpdateReason,
      chosenReason,
      handleUpdatePage,
      page,
      studentEmail,
      tutorEmail,
      handleUpdateStudentEmail,
      handleUpdateTutorEmail,
      durationEnd,
      durationStart,
      onUpdateDuration,
    } = this.props;
    const sortProps = {
      options: this.getSortOptions(),
      updateHandler: handleUpdateSortOption,
      value: chosenSortOption,
    };
    const statusFilterProps = {
      options: this.getStatusOptions(),
      updateHandler: handleUpdateStatus,
      value: chosenStatus,
    };
    const typeFilterProps = {
      options: this.getTypeOptions(),
      updateHandler: handleUpdateType,
      value: chosenType,
    };
    const reasonFilterProps = {
      options: this.getReasonOptions(),
      updateHandler: handleUpdateReason,
      value: chosenReason,
    };
    const studentEmailProps = {
      placeholder: 'Student name or email',
      className: '',
      value: studentEmail,
      name: 'student-name-or-email',
      updateHandler: handleUpdateStudentEmail,
      onKeyDown: this.handleSubmit,
    };
    const tutorEmailProps = {
      placeholder: 'Tutor name or email',
      className: '',
      value: tutorEmail,
      name: 'tutor-name-or-email',
      updateHandler: handleUpdateTutorEmail,
      onKeyDown: this.handleSubmit,
    };
    const pageProps = {
      options: this.getPageOptions(),
      updateHandler: handleUpdatePage,
      value: page
    }
    return (
      <div className="data-filter">
        <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
          <li className="row-space-2">
            <Select {...sortProps} />
          </li>
          <li className="row-space-2">
            <Select {...statusFilterProps} />
          </li>
          <li className="row-space-2">
            <Select {...typeFilterProps} />
          </li>
          <li className="row-space-2">
            <Select {...reasonFilterProps} />
          </li>
          <li className="row-space-2">
            <TextInput {...studentEmailProps} />
          </li>
          <li className="row-space-2">
            <TextInput {...tutorEmailProps} />
          </li>
          <li className="row-space-2">
            <DateRangePicker
              startDatePlaceholderText={"Lesson Date - Start"}
              endDatePlaceholderText={"Lesson Date - End"}
              enableOutsideDays={true}
              withPortal={true}
              startDate={durationStart}
              endDate={durationEnd}
              numberOfMonths={1}
              focusedInput={focusedOnRangePicker}
              isOutsideRange={() => false}
              onDatesChange={(dates) => { onUpdateDuration(dates) }}
              onFocusChange={( focused ) => { this.onFocusOnDurationChange(focused) }}
            />
          </li>
          <li className="row-space-2">
            <Select {...pageProps} />
          </li>
          <li className="row-space-2">
            <button onClick={this.handleSubmit} className="btn btn-success" disabled={!changeAdded}>Search</button>
          </li>
        </ul>
      </div>
    );
  }
}

LessonIssueFilter.propTypes = {
  handleSubmit: PropTypes.func,
  handleUpdateSortOption: PropTypes.func,
  chosenSortOption: PropTypes.number,
  handleUpdateStatus: PropTypes.func,
  chosenStatus: PropTypes.number,
  handleUpdateType: PropTypes.func,
  chosenType: PropTypes.number,
  chosenReason: PropTypes.number,
  studentEmail: PropTypes.string,
  tutorEmail: PropTypes.string,
  handleUpdateStudentEmail: PropTypes.func,
  handleUpdateTutorEmail: PropTypes.func,
  page: PropTypes.number,
  handleUpdatePage: PropTypes.func,
  count: PropTypes.number,
};

export default LessonIssueFilter;
