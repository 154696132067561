import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CalendarTimeslotTable from './CalendarTimeslotTable';
import CalendarHeader from './CalendarHeader';

class Calendar extends Component {

  render() {
    const {
      shifts,
      lessonNumsOfSlots,
      loading,
      startTime,
    } = this.props;
    const headerProps = {
      startTime,
    };
    const timeslotTableProps = {
      startTime,
      shifts,
      lessonNumsOfSlots,
      loading,
    };
    return (
      <div>
        <CalendarHeader {...headerProps} />
        <div className="cal-week-timed">
          <table className="day">
            <tbody>
              <tr className="day-row">
                <td className="side"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
              </tr>
            </tbody>
          </table>
          <CalendarTimeslotTable {...timeslotTableProps} />
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  shifts: PropTypes.array,
  startTime: PropTypes.object,
  loading: PropTypes.bool,
};

export default Calendar;
