import React, { Component } from 'react';
import TextInput from '../../../form/TextInput';
import Select from '../../../form/Select';
import Textarea from '../../../form/Textarea';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import _ from 'lodash';

class TutorCreatorMain extends Component {

  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getStatusOptions = this.getStatusOptions.bind(this);
    this.state = {
      focusedOnStartWorking: false
    }
  }

  getOptions(keyBase, baseOpts, useIndex=true) {
    let options = [];
    baseOpts.map((val, index) => {
      if (useIndex || index === 0) {
        options.push({ key: keyBase + index, name: val, value: index });
      } else {
        options.push({ key: keyBase + index, name: val, value: val });
      }
    });
    return options;
  }
  
  getStatusOptions() {
    const base = ["active", "inactive"]
    const options = base.map((val, index) => {
      return {
        content: _.capitalize(val), id: `tutor-status-${val}`, value: val
      }
    });
    return options;
  }

  render() {
    const {
      focusedOnStartWorking,
    } = this.state;
    const { 
      creating,
      changeAdded,
      onSubmit,
      onUpdateInput,
      tempFirstName, 
      tempLastName,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempEmail,
      tempBio,
      tempTeachingExperience,
      tempStartWorking,
      tempVideoUrl,
      tempExperienceYears,
      tempBaseRate,
      tempGender,
      onUpdateDate,
    } = this.props;
    const firstNameProps = {
      className: 'first-name',
      value: tempFirstName || "",
      name: 'first-name',
      stateType: 'tempFirstName',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const lastNameProps = {
      className: 'last-name',
      value: tempLastName || "",
      name: 'last-name',
      stateType: 'tempLastName',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const nicknameProps = {
      className: 'nickname',
      value: tempNickname || "",
      name: 'nickname',
      stateType: 'tempNickname',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const skypeIdProps = {
      className: 'skype-id',
      value: tempSkypeId || "",
      name: 'skypeId',
      stateType: 'tempSkypeId',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const videoUrlProps = {
      className: 'video-url',
      value: tempVideoUrl || "",
      name: 'videoUrl',
      stateType: 'tempVideoUrl',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const mobileNumberProps = {
      className: 'mobile-number',
      value: tempMobileNumber || "",
      name: 'mobileNumber',
      stateType: 'tempMobileNumber',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    }
    const emailProps = {
      className: 'email',
      value: tempEmail || "",
      name: 'email',
      type: 'email',
      stateType: 'tempEmail',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const experienceYearsProps = {
      className: 'experience_years',
      value: tempExperienceYears || "",
      name: 'exprienceYears',
      type: 'text',
      stateType: 'tempExperienceYears',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const baseRateProps = {
      className: 'base_rate',
      value: tempBaseRate || "",
      name: 'baseRate',
      type: 'text',
      stateType: 'tempBaseRate',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const genders = ["Choose the gender", "Male", "Female"]
    const genderProps = {
      options: this.getOptions("gender", genders),
      updateHandler: onUpdateInput,
      stateType: 'tempGender',
      value: tempGender || 0
    };
    const bioProps = {
      placeholder: '',
      className: 'bio',
      value: tempBio || "",
      name: 'bio',
      stateType: 'tempBio',
      rows: 5,
      updateHandler: onUpdateInput,
    };
    const teachingExperienceProps = {
      placeholder: '',
      className: 'teaching-experience',
      value: tempTeachingExperience || "",
      name: 'teaching-experience',
      stateType: 'tempTeachingExperience',
      rows: 5,
      updateHandler: onUpdateInput,
    };
    return (
      <div className="panel panel-no-border">
        <form method="put" onSubmit={onSubmit}>
          <div className="panel-body panel-body-no-border">
            <h4 className="form-title">Edit Profile</h4>
            <div className="form-body">
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Start Working
                </label>
                <div className="col-sm-9">
                  <SingleDatePicker
                    id="start_working_input"
                    date={tempStartWorking}
                    numberOfMonths={1}
                    focused={focusedOnStartWorking}
                    onDateChange={(date) => { onUpdateDate(date, "tempStartWorking")}}
                    onFocusChange={({ focused }) => { this.setState({ 
                      focusedOnStartWorking: focused,
                    }); }}
                    withPortal={true}
                  />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  First Name
                </label>
                <div className="col-sm-9">
                  <TextInput {...firstNameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Last Name
                </label>
                <div className="col-sm-9">
                  <TextInput {...lastNameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Gender
                </label>
                <div className="col-sm-9">
                  <Select {...genderProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Nickname
                </label>
                <div className="col-sm-9">
                  <TextInput {...nicknameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Email
                </label>
                <div className="col-sm-9">
                  <TextInput {...emailProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Skype ID
                </label>
                <div className="col-sm-9">
                  <TextInput {...skypeIdProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Video URL
                </label>
                <div className="col-sm-9">
                  <TextInput {...videoUrlProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Mobile Number
                </label>
                <div className="col-sm-9">
                  <TextInput {...mobileNumberProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Experience Years
                </label>
                <div className="col-sm-9">
                  <TextInput {...experienceYearsProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Base Rate
                </label>
                <div className="col-sm-9">
                  <TextInput {...baseRateProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Bio
                </label>
                <div className="col-sm-9">
                  <Textarea {...bioProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Teaching Experience
                </label>
                <div className="col-sm-9">
                  <Textarea {...teachingExperienceProps} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="text-right">
              <button
                className="btn btn-primary btn-large"
                type="submit"
                disabled={!changeAdded || creating}
              >Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default TutorCreatorMain;