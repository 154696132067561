import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';

const LessonIssueApiUtils = {

  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.FetchLessonIssues + query);
  },

  report(lessonId, issueType, comment) {
    
  },

  reportAndResolve(lessonId, issueType, action, reason, comment) {
    return xhr(ApiConstants.Base + 'lessons/' + lessonId + '/issues/report-and-resolve', {
      method: 'post',
      body: JSON.stringify({
        issueType,
        resolveAction: action,
        reason,
        comment: comment,
      })
    });
  },

  resolve(id, action, reason, comment) {
    return xhr(ApiConstants.FetchLessonIssues + '/' + id + '/resolve', {
      method: 'put',
      body: JSON.stringify({
        resolveAction: action,
        reason: reason,
        comment,
      })
    });
  },

  updateResolvedIssue(id, reason) {
    return xhr(ApiConstants.FetchLessonIssues + '/' + id + '/update', {
      method: 'put',
      body: JSON.stringify({
        reason,
      })
    });
  },

};

export default LessonIssueApiUtils;
