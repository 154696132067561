import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class HrSidenav extends Component {

  render() {
    return (
      <div className="sidenav layout-box o-has-rows row-space-3">
        <div className="sidenav-filter-wrapper">
          <ul className="sidenav-list">
            <li><h4>HR</h4></li>
            <li><Link to="/a/hr/tutors" className="sidenav-item">Active Tutors</Link></li>
            <li><Link to="/a/hr/shifts" className="sidenav-item">Shift Calendar</Link></li>
            <li><Link to="/a/hr/data" className="sidenav-item">Data</Link></li>
            <li><Link to="/a/hr/data/by-tutors/" className="sidenav-item">Data by Tutors</Link></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default HrSidenav;
