import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';
// import LeftArrow from '../../../../../assets/svg/arrow-left.inline.svg';
//import RightArrow from '../../../../../assets/svg/arrow-right.svg';

const CalendarHeader = (props) => {
  const { startTime, goNextWeek, goPreWeek } = props;
  let format = 'ddd M/D'
  let days = _.map([0, 1, 2, 3, 4, 5, 6], (i) => {
    let copyOfStartTime = _.cloneDeep(startTime)
    let formattedDate = copyOfStartTime.add(i, 'days').format(format);
    return (
      <td
        key={`cal-header-date${i}`}
        className="today text-center"
      >{formattedDate}
      </td>
    );
  });
  let isThisWeek = false
  if (moment().tz("Asia/Manila").startOf('week').valueOf() === startTime.clone().valueOf()) {
    isThisWeek = true
  }
  return (
    <div className="cal-header">
      <div className="calendar-navigation flex-items-start row-space-top-5 row-space-3">
        <span className="flex-ext">
          <button 
            disabled={isThisWeek}
            onClick={goPreWeek}
            className="btn btn-secondary">Last Week</button>
        </span>
        <div className="flex-ext text-center cal-date-display">
          {startTime.clone().format('YYYY/M/D')} - {startTime.clone().add(6, 'days').format('YYYY/M/D')}
        </div>
        <div className="flex-ext text-right">
          <button 
            onClick={goNextWeek}
            className="btn btn-secondary">Next Week</button>
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <td className="cal-timezone side text-center">GMT+8</td>
            {days}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CalendarHeader.propTypes = {
  startTime: PropTypes.object,
};

export default CalendarHeader;
