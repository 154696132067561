import _ from 'lodash';

const appUtils = {
  isNull(obj) {
    if (obj === null) {
      return true;
    } else {
      return false;
    }
  },

  isBlank(obj) {
    if (_.isUndefined(obj) || _.isEmpty(obj)) {
      return true;
    } else {
      return false;
    }
  }
}

export default appUtils;