import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as HolidayActions from '../../../actions/holiday';
import classnames from 'classnames';
import Holidays from '../../../components/db/holidays/Holidays';
import Immutable from 'immutable';
import qs from 'query-string';

function mapStateToProps(state) {
  const { holiday } = state;
  const { holidays, count } = holiday;
  return {
    holidays,
    count
  }
}

function mapDispatchToProps(dispatch) {
  return { holidayActions: bindActionCreators(HolidayActions, dispatch)};
}

class HolidaysTableContainer extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      page: parseInt(query.page) || 1,
      changeAdded: false,
      chosenSortOption: parseInt(query.sortOption) || 0,
      chosenStatus: parseInt(query.status) || 0,
    };
    this.handleUpdateSortOption = this.handleUpdateSortOption.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getOptsFromCurrentState = this.getOptsFromCurrentState.bind(this);
  }

  getOptsFromCurrentState() {
    const {
      page,
      chosenSortOption,
      chosenStatus
    } = this.state;
    const opts = {
      page,
      status: chosenStatus,
      sortOption: chosenSortOption
    };
    return opts;
  }

  componentWillMount() {
    const {holidayActions, holidays, lastSearchOpts} = this.props;
    const currentOpts = Immutable.Map(this.getOptsFromCurrentState());
    if (!Immutable.is(currentOpts,lastSearchOpts) || holidays.length === 0) {
      holidayActions.fetch(currentOpts.toObject());
    }
  }

  handleUpdateSortOption(value) {
    this.setState({
      chosenSortOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateStatus(value) {
    this.setState({
      chosenStatus: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdatePage(value) {
    this.setState({
      page: parseInt(value),
      changeAdded: true,
    })
  }

  handleSubmit() {
    const {
      holidayActions
    } = this.props
    const {
      page,
      chosenStatus,
      chosenSortOption
    } = this.state;
    const opts = this.getOptsFromCurrentState()
    this.changeUrl(opts);
    holidayActions.fetch(opts);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    baseUrl += '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          baseUrl += '&';
        }
        baseUrl += `${key}=${opts[key]}`;
        first = false;
      }
    }
    window.history.pushState(opts, '', baseUrl);
    window.scroll(0, 0);
  }

  render() {
    const {
      holidays,
      count
    } = this.props;
    const {
      page,
      chosenSortOption,
      chosenStatus,
      changeAdded
    } = this.state;
    return (
      <Holidays
        holidays={holidays}
        count={count}
        page={page}
        chosenSortOption={chosenSortOption}
        chosenStatus={chosenStatus}
        changeAdded={this.state.changeAdded}
        handleUpdateSortOption={this.handleUpdateSortOption}
        handleSubmit={this.handleSubmit}
        handleUpdateStatus={this.handleUpdateChosenStatus}
        handleUpdatePage={this.handleUpdatePage}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysTableContainer);
