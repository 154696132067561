import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Sidenav from './HrSidenav';

class ContentWrapper extends Component {
  render() {
    return (
      <div id="main-content">
        <div className="container-fluid row-space-top-4 row-space-4">
          <div className="row">
            <div className="col-lg-2">
              <Sidenav />
            </div>
            <div className="col-lg-10">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default ContentWrapper;