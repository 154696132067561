import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as MaterialActions from '../../../actions/material';
import * as CourseActions from '../../../actions/course';
import * as UnitActions from '../../../actions/unit';
import classnames from 'classnames';
import MaterialDetailsBase from '../../../components/db/materials/details/base';
import MaterialDetailsSidenav from '../../../components/db/materials/details/Sidenav';
import Immutable from 'immutable';
import Collection from '../../../utils/Collection';


function mapStateToProps(state) {
  const { materialData, course, unit } = state
  const { material, updating, processingFile } = materialData;
  const { courses } = course;
  const { units } = unit;
  return {
    material,
    courses,
    units,
    updating,
    processingFile,
    courses,
    units
  }
}

function mapDispatchToProps(dispatch) {
  return {
    materialActions: bindActionCreators(MaterialActions, dispatch),
    courseActions: bindActionCreators(CourseActions, dispatch),
    unitActions: bindActionCreators(UnitActions, dispatch),
  };
}

class MaterialDetailsContainer extends Component {

  constructor() {
    super();
    this.fetchCourseUnits = this.fetchCourseUnits.bind(this);
  }

  componentDidMount() {
    const { match, materialActions, courseActions, material } = this.props;
    const materialId = match.params.id;
    if (!material || material.id !== materialId) {
      materialActions.fetchOne(materialId);
    }
    courseActions.fetch({page: 1});
  }

  componentWillReceiveProps(nextProps) {
    const {material, unitActions} = this.props;
    if (!material && nextProps.material) {
      unitActions.fetch(nextProps.material.course_id);
    }
  }

  fetchCourseUnits(courseId) {
    this.props.unitActions.fetch(courseId);
  }

  render() {
    const {
      material,
      courses,
      units,
      materialActions,
      updating,
      processingFile
    } = this.props;;

    let materialDetailsBase = <div></div>;
    if (material) {
      materialDetailsBase = <MaterialDetailsBase
        material={material}
        courses={courses}
        units={units}
        updating={updating}
        processingFile={processingFile}
        fetchUnits={this.fetchCourseUnits}
        updateMaterial={materialActions.update}
        uploadFile={materialActions.uploadFile}
        deleteFile={materialActions.deleteFile}/>;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 row-space-sm-4">
            <MaterialDetailsSidenav />
          </div>
          <div className="col-md-9">
            {materialDetailsBase}
          </div>
        </div>
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDetailsContainer);
