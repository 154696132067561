import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const TutorBankInfoApiUtils = {

  fetchByTutor(tutorId) {
    return xhr(ApiConstants.Base + "bank-infos/of-tutor/" + tutorId.toString());
  },

  update(bankInfoParams) {
    return xhr(ApiConstants.Base + "bank-infos/" + bankInfoParams.id, {
      method: "put",
      body: JSON.stringify({
        tutor_bank_info: bankInfoParams
      }),
    });
  }
}

export default TutorBankInfoApiUtils;
