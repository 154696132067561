import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Collection from '../../utils/Collection';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import classnames from 'classnames';
import * as LessonActions from '../../actions/lesson';
import * as LessonIssueActions from '../../actions/lessonIssue';
import ModalMessage from '../../components/modals/ModalMessage';
import RadioInputs from '../../components/form/RadioInputs';

const FIFTY_MINUTES = 3000000;

function mapStateToProps(state) {
  const { lesson, lessonIssue } = state
  return {
    lesson,
    lessonIssue,
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    lessonActions: bindActionCreators(LessonActions, dispatch),
    lessonIssueActions: bindActionCreators(LessonIssueActions, dispatch),
  };
}

class ResolveIssueModalContainer extends Component {

  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onUpdateReason = this.onUpdateReason.bind(this);
    this.getReasonOptions = this.getReasonOptions.bind(this);
    this.renderLessonInfo = this.renderLessonInfo.bind(this);
    this.renderResolvedIssueInfo = this.renderResolvedIssueInfo.bind(this);
    this.state = {
      selectedReason: null,
      changeAdded: false
    }
  }

  onUpdateReason(value) {
    this.setState({
      selectedReason: value,
      changeAdded: true,
    })
  }

  hideModal(e) {
    e.preventDefault();
    this.props.handleHideModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { 
      selectedReason, 
    } = this.state;
    const { 
      issue,
      lessonIssueActions
    } = this.props
    if ( selectedReason !== null) {
      lessonIssueActions.updateResolvedIssue(
        issue.id,
        selectedReason,
      );
    } else {
      this.props.addModalMessage("Reason cannot be empty.", {
        type: "error",
        autoDisappear: false
      });
    }
  }

  getReasonOptions() {
    const options = 
      [
        {value: "Lesson was delivered", id: "0"},
        {value: "Unstable internet connection", id: "1"},
        {value: "Blackout", id: "2"},
        {value: "Computer problem", id: "3"},
        {value: "Sickness", id: "4"},
        {value: "Taking care of sick family member", id: "5" },
        {value: "Other tutor-side reason", id: "6"},
        {value: "Other", id: "7"},
      ];
    return options;
  }

  renderLessonInfo() {
    const { issue } = this.props;
    const startAt = moment.tz(issue.lesson_start, "Asia/Manila");
    const endAt = moment.tz(issue.lesson_end, "Asia/Manila");
    return (
      <div>
        <div className="row-space-1">
          <b>Student: </b>
          <span>{issue.student_id}: {issue.student_name}</span>
        </div>
        <div className="row-space-1">
          <b>Tutor: </b>
          <span>{issue.tutor_id}: {issue.tutor_name}</span>
        </div>
        <div className="row-space-1">
          <b>Date: </b>
          <span>{startAt.format('YYYY-MM-DD')}</span>
        </div>
        <div className="row-space-1">
          <b>Time: </b>
          <span>{startAt.format('hh:mmA')} - {endAt.format('hh:mmA')} UTC{startAt.format('ZZ')}</span>
        </div>
      </div>
    );
  }

  renderResolvedIssueInfo() {
    const { issue } = this.props;
    let resolver_comment = issue.action.comment || issue.reason.comment
    return (
      <div>
        <div className="row-space-1">
          <b>Reported Issue: </b>
          <span>{issue.issue_types[0]}</span>
        </div>
        <div className="row-space-1">
          <b>Comment from Reporter:</b>
          <span>{issue.comment}</span>
        </div>
        <div className="row-space-1">
          <b>Reason: </b>
          <span>{issue.reason.title}</span>
        </div>
        <div className="row-space-1">
          <b>Action: </b>
          <span>{issue.action.title}</span>
        </div>
        <div className="row-space-1">
          <b>Comment from Resolver: </b>
          <span>{resolver_comment}</span>
        </div>
      </div>
    );
  }

  render() {
    const {
      selectedReason,
      changeAdded
    } = this.state;
    const {
      saving,
      issue,
      message,
      messageType
    } = this.props;
    const reasonOptions = this.getReasonOptions();
    const reasonSelectProps = {
      options: reasonOptions,
      className: '',
      id: 'select-issue-reason',
      value: selectedReason,
      name: "issue-reason",
      updateHandler: this.onUpdateReason,
      invalidLabel: true,
      onKeyDown: this.handleSubmit
    }
    return (
      <div className="modal-content">
        <div className="panel panel-no-border">
          <div className="panel-header">
            <span>Update Resolved Issue</span>
            <a className="modal-close" onClick={this.hideModal}>
              <span>☓</span>
            </a>
          </div>
          <ModalMessage message={message} messageType={messageType}/>
          <form
            method="put"
            className="report-issue-form"
            onSubmit={this.handleSubmit}
            noValidate>
            <div className="panel-body">
              <div className="lesson-wrapper">
                <h5 className="row-space-3">About Lesson</h5>
                {this.renderLessonInfo()}
              </div>
              <hr/>
              <div className="issue-wrapper row-space-3">
                <h5>Resolved Issue</h5>
                {this.renderResolvedIssueInfo()}
              </div>
              <hr/>
              <div className="reasons-wrapper row-space-4">
                <h5>Please choose the new reason</h5>
                <RadioInputs {...reasonSelectProps} />
              </div>
            </div>
            <div className="panel-footer">
              <div className="text-right">
                <button 
                  className="btn btn-primary btn-large"
                  disabled={saving || !changeAdded}>Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


ResolveIssueModalContainer.propTypes = {
  issue: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ResolveIssueModalContainer)

