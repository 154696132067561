import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table';

class LessonIssueActionCell extends Component {

  constructor() {
    super();
    this.openResolveModal = this.openResolveModal.bind(this);
    this.openRevokeModal = this.openRevokeModal.bind(this);
  }

  openResolveModal() {
    const {rowIndex, data, ...props} = this.props;
    this.context.showModal('resolveIssue', {
      issue: data[rowIndex]
    });
  }

  openRevokeModal() {
    const {rowIndex, data, ...props} = this.props;
    if (data[rowIndex].status_id === 1) {
      this.context.showModal('revokeReportedIssue', {
        issue: data[rowIndex]
      });
    } else {
      this.context.showModal('revokeResolvedIssue', {
        issue: data[rowIndex]
      });
    }
  }

  render() {
    const {tableName, rowIndex, field, data, ...props} = this.props;
    let statusId = data[rowIndex].status_id;
    let links = <div></div>
    if (statusId === 1) {
      links = (
        <a onClick={this.openResolveModal}>Resolve</a>
      );
    } else {
      links = (
        <a onClick={this.openRevokeModal}>Revoke</a>
      );
    }
    return (
      <Cell {...props}>
        {links}
      </Cell>
    );
  }
}

LessonIssueActionCell.contextTypes = {
  showModal: PropTypes.func
}

export default LessonIssueActionCell;