import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as LessonActions from '../../../actions/lesson';
import classnames from 'classnames';
import Lessons from '../../../components/db/lessons/Lessons';
import Immutable from 'immutable';
import qs from 'query-string';
import moment from 'moment-timezone';

function mapStateToProps(state) {
  const { lesson } = state;
  const { lessons, count, lastSearchOpts } = lesson;
  return {
    lessons,
    count,
    lastSearchOpts
  }
}

function mapDispatchToProps(dispatch) {
  return { lessonActions: bindActionCreators(LessonActions, dispatch)};
}

class LessonsTableContainer extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      page: parseInt(query.page) || 1,
      changeAdded: false,
      chosenSortOption: parseInt(query.sortOption) || 0,
      chosenStatus: parseInt(query.status) || 0,
      chosenTrialOption: parseInt(query.trialStatus) || 0,
      studentEmail: query.studentEmail || "",
      tutorEmail: query.tutorEmail || "",
      durationStart: query.durationStart ? moment.unix(Number(query.durationStart) / 1000) : null,
      durationEnd: query.durationEnd ? moment.unix(Number(query.durationEnd) / 1000) : null,
    };
    this.handleUpdateSortOption = this.handleUpdateSortOption.bind(this);
    this.handleUpdateChosenStatus = this.handleUpdateChosenStatus.bind(this);
    this.handleUpdateStudentEmail = this.handleUpdateStudentEmail.bind(this);
    this.handleUpdateTutorEmail = this.handleUpdateTutorEmail.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleUpdateTrialOption = this.handleUpdateTrialOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getOptsFromCurrentState = this.getOptsFromCurrentState.bind(this);
    this.handleUpdateDuration = this.handleUpdateDuration.bind(this);
  }

  getOptsFromCurrentState() {
    const {
      page,
      chosenSortOption,
      chosenStatus,
      chosenTrialOption,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    } = this.state;
    const opts = {
      page,
      status: chosenStatus,
      sortOption: chosenSortOption,
      trialOption: chosenTrialOption,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    };
    return opts;
  }

  componentWillMount() {
    const {lessonActions, lessons, lastSearchOpts} = this.props;
    const currentOpts = Immutable.Map(this.getOptsFromCurrentState());
    if (!Immutable.is(currentOpts,lastSearchOpts) || lessons.length === 0) {
      lessonActions.fetch(currentOpts.toObject());
    }
  }

  handleUpdateSortOption(value) {
    this.setState({
      chosenSortOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateChosenStatus(value) {
    this.setState({
      chosenStatus: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateStudentEmail(value) {
    this.setState({
      studentEmail: value,
      changeAdded: true,
    });
  }

  handleUpdateTutorEmail(value) {
    this.setState({
      tutorEmail: value,
      changeAdded: true,
    });
  }

  handleUpdatePage(value) {
    this.setState({
      page: parseInt(value),
      changeAdded: true,
    })
  }

  handleUpdateTrialOption(value) {
    this.setState({
      chosenTrialOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateDuration(dates) {
    this.setState({
      durationStart: dates.startDate,
      durationEnd: dates.endDate,
      changeAdded: true,
    });
  }

  handleSubmit() {
    const {
      lessonActions
    } = this.props
    const {
      page,
      chosenStatus,
      chosenSortOption,
      chosenTrialOption,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    } = this.state;
    const opts = {
      page,
      status: chosenStatus,
      sortOption: chosenSortOption,
      trialOption: chosenTrialOption,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    };
    this.changeUrl(opts);
    lessonActions.fetch(opts);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    let first = true;
    let query = '?';
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          query += '&'
        }
        query += `${key}=${opts[key]}`;
        first = false;
      }
      baseUrl += query
    }
    this.props.history.push(this.props.match.path + query);
    window.scroll(0, 0);
  }

  render() {
    const {
      lessons,
      count
    } = this.props;
    const {
      page,
      status,
      chosenStatus,
      chosenSortOption,
      chosenTrialOption,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    } = this.state;
    return (
      <Lessons
        lessons={lessons}
        count={count}
        studentEmail={studentEmail}
        tutorEmail={tutorEmail}
        page={page}
        chosenSortOption={chosenSortOption}
        chosenStatus={chosenStatus}
        chosenTrialOption={chosenTrialOption}
        changeAdded={this.state.changeAdded}
        handleUpdateSortOption={this.handleUpdateSortOption}
        handleSubmit={this.handleSubmit}
        handleUpdateChosenStatus={this.handleUpdateChosenStatus}
        handleUpdateStudentEmail={this.handleUpdateStudentEmail}
        handleUpdateTutorEmail={this.handleUpdateTutorEmail}
        handleUpdatePage={this.handleUpdatePage}
        handleUpdateTrialOption={this.handleUpdateTrialOption}
        onUpdateDuration={this.handleUpdateDuration}
        durationStart={durationStart}
        durationEnd={durationEnd} />
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(LessonsTableContainer);
