import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import UserActions from '../../actions/UserActions.js';
import AvatarValidator from '../../validators/AvatarValidator';
import classnames from 'classnames';
// import ServerMessageActions from '../../actions/ServerMessageActions.js';
import DefaultAvatar from '../../assets/images/default_avatar_orange.png';

class ProfilePhotoInput extends React.Component {
  constructor() {
    super();
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.processPhoto = this.processPhoto.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file) {
      const validator = new AvatarValidator(file);
      validator.validate().then(
        () => {
          this.processPhoto(file);
        },
        (error) => {
          console.log(error);
          // ServerMessageActions.addMessage({
          //   type: 'uploadProfilePhotoFailed',
          //   message: error });
        }
      );
    }
  }

  onClickUpload() {
    this.refs.profilePhoto.click();
  }

  onClickDelete() {
    const {deleteAvatar, userId} = this.props
    deleteAvatar(userId);
  }

  processPhoto(file) {
    const self = this;
    let photoData;
    const reader = new FileReader();
    reader.onloadend = (e) => {
      photoData = {
        file,
        imageUrl: e.target.result };
      self.uploadPhoto(photoData);
    };
    reader.readAsDataURL(file);
  }

  uploadPhoto(photoData) {
    const {userId, uploadAvatar} = this.props;
    const newFile = {
      name: photoData.file.name,
      size: photoData.file.size,
      altText: '',
      caption: '',
      file: photoData.file,
      url: photoData.imageUrl,
      type: photoData.file.type };
    uploadAvatar(userId, newFile);
  }

  render() {
    let deleteButton = null;
    const currentAvatarUrl = this.props.currentAvatarUrl
    const avatarExist = currentAvatarUrl ? true : false;
    const avatarUrl = currentAvatarUrl ? currentAvatarUrl : DefaultAvatar
    if (avatarExist) {
      deleteButton = (
        <div className="col-xs-6 col-sm-6 row-space-sm-2 sm-col-center">
          <button
            className="btn btn-danger btn-block sm-center"
            onClick={this.onClickDelete}
          >
            Delete
          </button>
        </div>);
    }
    let style = { display: 'none' };
    let mediaPhotoClass = classnames('media-photo media-round', { loading: this.props.processing });
    return (
      <div id="profile-photo-input-component">
        <div className="col-md-5 col-lg-4 text-center">
          <div className="profile-photo-container">
            <div className={mediaPhotoClass}>
              <img
                alt="avatar"
                height="120"
                width="120"
                src={avatarUrl}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-7 col-lg-8 vertical-middle">
          <div className="col-xs-6 col-sm-6 row-space-top-sm-3 row-space-sm-2 sm-col-center">
            <div
              className="btn btn-success btn-block sm-center"
              onClick={this.onClickUpload}
            >
              Upload
              <input
                style={style}
                type="file"
                ref="profilePhoto"
                onChange={this.onChange}
              />
            </div>
          </div>
          {deleteButton}
        </div>
      </div>
    );
  }
}

export default ProfilePhotoInput;
