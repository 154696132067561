import {
  ADD_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE
} from '../actions/flash';

const initialState = {
  isShowing: false,
  type: "success", // Success, Error, Info
  autoDisappear: false,
  sticky: false,
  message: ""
}

export default function flashReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case ADD_FLASH_MESSAGE:
      return {
        ...state,
        isShowing: true,
        type: payload.type || "info",
        autoDisappear: payload.autoDisappear || false,
        sticky: payload.sticky || false,
        message: payload.message,
      };
    case CLEAR_FLASH_MESSAGE: 
      return {
        ...state,
        isShowing: false,
        type: "success",
        message: "",
        autoDisappear: false
      };
    default:
      return state;
  }
}