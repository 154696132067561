import Collection from '../../utils/Collection.js';

import {
  FETCH_DATA_BY_TUTORS_SUCCEEDED,
  FETCH_DATA_BY_TUTORS_FAILED,
  FETCH_DATA_BY_TUTORS_SENT,
} from '../../actions/data';

const initialState = {
  chosenDate: null,
  count: 0,
  loading: false,
  loaded: false,
  dataList: [],
};

export default function dataByTutorsReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_DATA_BY_TUTORS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        loaded: true,
        dataList: payload.dataList,
        count: payload.count
      };
    case FETCH_DATA_BY_TUTORS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DATA_BY_TUTORS_SENT:
      return {
        ...state,
        chosenDate: payload.chosenDate,
        loading: true,
      };
    default:
      return state;
  }
}
