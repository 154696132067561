import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatabaseSidenav from '../Sidenav';
import PaymentsTable from './PaymentsTable';
import PaymentFilter from './PaymentFilter';

class Payments extends Component {
  render() {
    const {
      payments,
      count,
      page,
      changeAdded,
      chosenSortOption,
      chosenLocale,
      chosenPlan,
      chosenPlanStatus,
      nameOrEmail,
      paypalProfileToken,
      handleUpdatePage,
      handleUpdateLocale,
      handleUpdatePlan,
      handleUpdatePlanStatus,
      handleUpdateSortOption,
      handleUpdateNameOrEmail,
      handleUpdatePaypalProfileToken,
      handleSubmit,
      onUpdateDuration,
      durationStart,
      durationEnd,
    } = this.props
    return (
      <div id="database-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <DatabaseSidenav />
            </div>
            <div className="col-lg-10">
              <PaymentFilter
                count={count}
                page={page}
                changeAdded={changeAdded}
                chosenSortOption={chosenSortOption}
                chosenLocale={chosenLocale}
                chosenPlan={chosenPlan}
                chosenPlanStatus={chosenPlanStatus}
                nameOrEmail={nameOrEmail}
                paypalProfileToken={paypalProfileToken}
                handleUpdatePage={handleUpdatePage}
                handleUpdateLocale={handleUpdateLocale}
                handleUpdatePlan={handleUpdatePlan}
                handleUpdatePlanStatus={handleUpdatePlanStatus}
                handleUpdateSortOption={handleUpdateSortOption}
                handleUpdateNameOrEmail={handleUpdateNameOrEmail}
                handleUpdatePaypalProfileToken={handleUpdatePaypalProfileToken}
                onUpdateDuration={onUpdateDuration}
                durationStart={durationStart}
                durationEnd={durationEnd}
                handleSubmit={handleSubmit} />
              <PaymentsTable payments={payments} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Payments.propTypes = {
  payments: PropTypes.array,
};

export default Payments;
