const prepare = (response) =>
  Promise.all([response, response.json()]);

const status = ([response, json]) => {
  if (response.status < 200 || response.status >= 300) {
    const error = new Error(json.error || json.message);
    return Promise.reject(error);
  }
  return Promise.resolve([response, json]);
};

const json = ([response, json]) => {
  if (response.status !== 204) {
    return json;
  }
  return {};
};

const xhr = (url, params = {}) => {
  const authToken = localStorage.getItem('authToken');
  let authHeader = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (authToken) {
    authHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    };
  }
  Object.assign(params, authHeader);
  return fetch(url, params)
    .then(prepare)
    .then(status)
    .then(json);
};

export default xhr;
