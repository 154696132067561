export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_SECONDARY_MODAL = 'SHOW_SECONDARY_MODAL';
export const HIDE_SECONDARY_MODAL = 'HIDE_SECONDARY_MODAL';
export const ADD_MODAL_MESSAGE = 'ADD_MODAL_MESSAGE';
export const CLEAR_MODAL_MESSAGE = 'CLEAR_MODAL_MESSAGE';
export const ADD_SECONDARY_MODAL_MESSAGE = 'ADD_SECONDARY_MODAL_MESSAGE';
export const CLEAR_SECONDARY_MODAL_MESSAGE = 'CLEAR_SECONDARY_MODAL_MESSAGE';

export function show(content, modalProps={}) {
  return {
    type: SHOW_MODAL,
    payload: {
      content,
      modalProps
    }
  }
}

export function hide() {
  return {
    type: HIDE_MODAL
  }
}


export function showSecondary(content, modalProps={}) {
  return {
    type: SHOW_SECONDARY_MODAL,
    payload: {
      content,
      modalProps
    }
  }
}

export function hideSecondary() {
  return {
    type: HIDE_SECONDARY_MODAL
  }
}

export function addMessage(message, opts={}) {
  return {
    type: ADD_MODAL_MESSAGE,
    payload: Object.assign({}, {message}, opts)
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MODAL_MESSAGE
  }
}

export function addMessageToSecondaryModal(message, opts={}) {
  return {
    type: ADD_SECONDARY_MODAL_MESSAGE,
    payload: Object.assign({}, {message}, opts)
  }
}

export function clearMessageOfSecondaryModal() {
  return {
    type: CLEAR_SECONDARY_MODAL_MESSAGE
  }
}

