import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

class CalendarTimeslot extends Component {
  constructor(props) {
    super(props);
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  onMouseDown(e) {
    const {editing, saving, onMouseDown} = this.props;
    if (editing && !saving) {
      onMouseDown(e);
    }
  }

  render() {
    const self = this;
    const { hasShift, slotNum, onMouseDown, tempSelect, editType} = this.props;
    let slotClass = classnames("timeslot", 
        {green: hasShift && !(tempSelect && editType === "Remove")}, 
        {"light-green": !hasShift && tempSelect && editType === "Add"}, 
        {yellow: hasShift && tempSelect && editType === "Remove"}
      )
    return (
      <div 
        className={slotClass} 
        data-slot-num={slotNum} 
        onMouseDown={this.onMouseDown}
        // onClick={(e) => {this.props.onClickSlot(slotNum, e)}}
      >
      </div>
    );
  }
}


CalendarTimeslot.propTypes = {
  hasShift: PropTypes.bool
};

export default CalendarTimeslot;
