import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../form/TextInput';
import Select from '../../../form/Select.js';
import Textarea from '../../../form/Textarea';
import ProfilePhotoInput from '../../../form/ProfilePhotoInput';
import UserValidator from '../../../../validators/UserValidator';
import classnames from 'classnames';

class UserDetailsBase extends Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.getLocaleOptions = this.getLocaleOptions.bind(this);
    this.onUpdateInput = this.onUpdateInput.bind(this);
    const { user } = props
    this.state = {
      tempFirstName: user.first_name,
      tempLastName: user.last_name,
      tempEmail: user.email,
      tempNickname: user.nickname,
      tempSkypeId: user.skype_name,
      tempLocale: user.locale.value,
      tempTimeZone: user.js_time_zone,
      tempBio: user.user_profile ? user.user_profile.bio : "",
      changeAdded: false,
      timezoneChanged: false,
      firstNameValidationResult: null,
      lastNameValidationResult: null,
      emailValidationResult: null,
      nicknameValidationResult: null,
      skypeIdValidationResult: null,
      localeValidationResult: null,
      timezoneValidationResult: null,
      bioValidationResult: null,
    };
  }

  getLocaleOptions() {
    const locales = [
      { i18n_form: 'en', language: 'English', subdomain: 'www', value: 'en-US' },
      { i18n_form: 'es', language: 'Español', subdomain: 'es', value: 'es-ES' },
      { i18n_form: 'pt', language: 'Português', subdomain: 'pt', value: 'pt-BR' },
      { i18n_form: 'ru', language: 'Русский', subdomain: 'ru', value: 'ru-RU' },
      { i18n_form: 'ja', language: '日本語', subdomain: 'ja', value: 'ja-JP' },
      { i18n_form: 'zh-TW', language: '中文 (繁體)', subdomain: 'tw', value: 'zh-TW' }
    ];
    let localeOptions = locales.map((l) => {
      return {key: 'locale-' + l.value,name: l.language, value: l.value}
    });
    return localeOptions;
  }

  componentDidMount() {
    this.props.fetchTimeZones();
  }

  onUpdateInput(value, stateType) {
    let state = this.state;
    state[stateType] = value;
    state.changeAdded = true;
    this.setState(state);
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      user,
      updateUser
    } = this.props;
    const {
      changeAdded,
      tempFirstName,
      tempLastName,
      tempEmail,
      tempNickname,
      tempSkypeId,
      tempLocale,
      tempTimeZone,
      tempBio
    } = this.state;
    const params = {
      tempFirstName,
      tempLastName,
      tempEmail,
      tempNickname,
      tempSkypeId,
      tempLocale,
      tempTimeZone,
      tempBio
    };
    if (!changeAdded) {
      return false;
    }
    let userValidator = new UserValidator({
      firstName: tempFirstName,
      lastName: tempLastName,
      email: tempEmail
    });
    userValidator.validate()
    .then((result) => {
      if (result.valid) {
        updateUser(user.id, params);
      } else {
        let message = '';
        for (let err of result.errors) {
          message += err + " ";
        }
        // ServerMessageActions.addMessage({
        //   alertType: "error",
        //   type: "userValidationFailed",
        //   message,
        //   autoDisappear: false,
        // });
      }
    });
  }

  render() {
    const {
      user,
      timeZones,
      updating,
      uploadAvatar,
      deleteAvatar,
      avatarProcessing
    } = this.props;
    const { id,
            first_name,
            last_name,
            nickname,
            skype_name,
            email,
            user_profile,
            js_time_zone,
            locale,
            large_avatar_url
          } = this.props.user;
    const { tempFirstName,
            tempLastName,
            tempNickname,
            tempSkypeId,
            tempEmail,
            tempTimeZone,
            tempLocale,
            tempBio
          } = this.state;
    const bio = user_profile ? user_profile.bio : '';
    let timeZoneOptions = timeZones.map((tz) => {
      tz['key'] = "time-zone-" + tz.name;
      return tz;
    })
    const firstNameProps = {
      className: 'first-name',
      value: tempFirstName !== null ? tempFirstName : (first_name || ''),
      name: 'first-name',
      stateType: 'tempFirstName',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const lastNameProps = {
      className: 'last-name',
      value: tempLastName !== null ? tempLastName : (last_name || ''),
      name: 'last-name',
      stateType: 'tempLastName',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const nicknameProps = {
      className: 'nickname',
      value: tempNickname !== null ? tempNickname : (nickname || ''),
      name: 'nickname',
      stateType: 'tempNickname',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const skypeIdProps = {
      className: 'skype-id',
      value: tempSkypeId !== null ? tempSkypeId : (skype_name || ''),
      name: 'skypeId',
      stateType: 'tempSkypeId',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const emailProps = {
      className: 'email',
      value: tempEmail !== null ? tempEmail : (email || ''),
      name: 'email',
      type: 'email',
      stateType: 'tempEmail',
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const localeProps = {
      options: this.getLocaleOptions(),
      className: 'edit-profile',
      id: 'user_preference_locale',
      stateType: 'tempLocale',
      value: tempLocale !== null ? tempLocale : (locale || ''),
      updateHandler: this.onUpdateInput,
      onKeyDown: this.onSubmit,
    };
    const timeZoneProps = {
      options: timeZoneOptions,
      className: 'edit-profile',
      id: 'user_preference_time_zone',
      value: tempTimeZone !== null ? tempTimeZone : (js_time_zone || ''),
      stateType: 'tempTimeZone',
      updateHandler: this.onUpdateInput,
      invalidLabel: true,
      onKeyDown: this.onSubmit,
    };
    const bioProps = {
      placeholder: '',
      className: 'bio',
      value: tempBio !== null ? tempBio : bio,
      name: 'bio',
      stateType: 'tempBio',
      rows: 5,
      updateHandler: this.onUpdateInput,
    };
    const panelBodyClass = classnames("panel-body", {loading: updating});
    return (
      <div className="panel panel-no-border">
        <form method="put" onSubmit={this.onSubmit}>
          <div className={panelBodyClass}>
            <h4 className="form-title">Edit Profile</h4>
            <div className="form-body">
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Avatar
                </label>
                <div className="col-sm-9">
                  <ProfilePhotoInput
                    userId={id}
                    currentAvatarUrl={large_avatar_url}
                    processing={avatarProcessing}
                    uploadAvatar={uploadAvatar}
                    deleteAvatar={deleteAvatar}/>
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  First Name
                </label>
                <div className="col-sm-9">
                  <TextInput {...firstNameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Last Name
                </label>
                <div className="col-sm-9">
                  <TextInput {...lastNameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Nickname
                </label>
                <div className="col-sm-9">
                  <TextInput {...nicknameProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Email
                </label>
                <div className="col-sm-9">
                  <TextInput {...emailProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Skype ID
                </label>
                <div className="col-sm-9">
                  <TextInput {...skypeIdProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Timezone
                </label>
                <div className="col-sm-9">
                  <Select {...timeZoneProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Language
                </label>
                <div className="col-sm-9">
                  <Select {...localeProps} />
                </div>
              </div>
              <div className="row row-condensed row-space-4">
                <label className="text-right col-sm-3 sm-text-left">
                  Bio
                </label>
                <div className="col-sm-9">
                  <Textarea {...bioProps} />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="text-right">
              <button
                className="btn btn-primary btn-large"
                type="submit"
                disabled={!this.state.changeAdded || updating}
              >Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default UserDetailsBase;
