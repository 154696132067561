import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as TutorActions from '../../../actions/tutor';
import classnames from 'classnames';
import Immutable from 'immutable';
import { Redirect } from 'react-router-dom';
import Collection from '../../../utils/Collection';
import TutorCreatorMain from '../../../components/hr/tutor/tutorCreator/Main';
import ContentWrapper from '../../../components/hr/tutor/tutorCreator/Wrapper';
import UserValidator from '../../../validators/UserValidator';

function mapStateToProps(state) {
  const { tutorData } = state
  const { tutor, creating } = tutorData;
  return {
    tutor,
    creating
  }
}

function mapDispatchToProps(dispatch) {
  return {
    tutorActions: bindActionCreators(TutorActions, dispatch)
  };
}

class TutorCreatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempFirstName: null,
      tempLastName: null,
      tempEmail: null,
      tempNickname: null,
      tempSkypeId: null,
      tempMobileNumber: null,
      tempBio: null,
      tempTeachingExperience: "",
      tempVideoUrl: "",
      tempExperienceYears: "",
      tempStartWorking: null,
      tempBaseRate: null,
      tempGender: null,
      changeAdded: false,
      firstNameValidationResult: null,
      lastNameValidationResult: null,
      emailValidationResult: null,
      nicknameValidationResult: null,
      skypeIdValidationResult: null,
      bioValidationResult: null,
      teachingExperienceValidationResult: null,
      startWorkingValidationResult: null,
      baseRateValidationResult: null,
    }
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateDate = this.onUpdateDate.bind(this);
  }

  onUpdateInput(value, stateType) {
    let state = this.state;
    state[stateType] = value;
    state.changeAdded = true;
    this.setState(state);
  }

  onUpdateDate(date, type) {
    let state = this.state;
    state[type] = date;
    state.changeAdded = true;
    this.setState(state);
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      tutor,
      tutorActions
    } = this.props;
    const {
      tempFirstName,
      tempLastName,
      tempEmail,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempBio,
      tempTeachingExperience,
      tempVideoUrl,
      tempExperienceYears,
      tempStartWorking,
      tempBaseRate,
      tempGender
    } = this.state;
    const params = {
      tempFirstName,
      tempLastName,
      tempEmail,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempBio,
      tempTeachingExperience,
      tempVideoUrl,
      tempExperienceYears,
      tempStartWorking,
      tempBaseRate,
      tempGender
    };
    if (!this.state.changeAdded) {
      return false;
    }
    let userValidator = new UserValidator({
      firstName: tempFirstName, 
      email: tempEmail,
    });
    userValidator.validateTutorProfile()
    .then((result) => {
      if (result.valid) {
        tutorActions.create(params);
      } else {
        let message = '';
        for (let err of result.errors) {
          message += err + " ";
        }
        // ServerMessageActions.addMessage({
        //   alertType: "error",
        //   type: "userValidationFailed",
        //   message,
        //   autoDisappear: false,
        // });
      }
    });
  }

  render() {
    const {
      creating,
      tutor,
      tutorActions
    } = this.props;
    const {
      changeAdded,
      tempFirstName,
      tempLastName,
      tempNickname,
      tempSkypeId,
      tempMobileNumber,
      tempEmail,
      tempBio,
      tempTeachingExperience,
      tempVideoUrl,
      tempStartWorking,
      tempBaseRate,
      tempExperienceYears,
      tempGender
    } = this.state;
    let tutorCreatorMain = <div/>
    tutorCreatorMain = (
      <TutorCreatorMain
        onSubmit={this.onSubmit}
        changeAdded={changeAdded}
        onUpdateInput={this.onUpdateInput}
        onUpdateDate={this.onUpdateDate}
        tempFirstName={tempFirstName}
        tempLastName={tempLastName}
        tempNickname={tempNickname}
        tempSkypeId={tempSkypeId}
        tempMobileNumber={tempMobileNumber}
        tempVideoUrl={tempVideoUrl}
        tempEmail={tempEmail}
        tempBio={tempBio}
        tempTeachingExperience={tempTeachingExperience}
        tempExperienceYears={tempExperienceYears}
        tempStartWorking={tempStartWorking}
        tempBaseRate={tempBaseRate}
        tempGender={tempGender}/>
    );
    if (tutor && tutor.id) {
      return <Redirect to={{
        pathname: `/a/hr/tutors/${tutor.id}`
      }}/>
    }
    return (
      <ContentWrapper>
        {tutorCreatorMain}
      </ContentWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorCreatorContainer);
