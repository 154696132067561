import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as LessonIssueActions from '../../../actions/lessonIssue';
import classnames from 'classnames';
import LessonIssues from '../../../components/db/issues/LessonIssues';
import Immutable from 'immutable';
import qs from 'query-string';
import moment from 'moment-timezone';

function mapStateToProps(state) {
  const { lessonIssue } = state;
  const { issues, count, lastSearchOpts } = lessonIssue;
  return {
    issues,
    count,
    lastSearchOpts
  }
}

function mapDispatchToProps(dispatch) {
  return { lessonIssueActions: bindActionCreators(LessonIssueActions, dispatch)};
}

class LessonIssuesTableContainer extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      page: parseInt(query.page) || 1,
      changeAdded: false,
      chosenSortOption: parseInt(query.sortOption) || 0,
      chosenStatus: parseInt(query.status) || 0,
      chosenType: parseInt(query.type) || 0,
      chosenReason: parseInt(query.type) || 0,
      studentEmail: query.studentEmail || "",
      tutorEmail: query.tutorEmail || "",
      durationStart: query.durationStart ? moment.unix(Number(query.durationStart) / 1000) : null,
      durationEnd: query.durationEnd ? moment.unix(Number(query.durationEnd) / 1000) : null,
    };
    this.handleUpdateSortOption = this.handleUpdateSortOption.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdateType = this.handleUpdateType.bind(this);
    this.handleUpdateReason = this.handleUpdateReason.bind(this);
    this.handleUpdateStudentEmail = this.handleUpdateStudentEmail.bind(this);
    this.handleUpdateTutorEmail = this.handleUpdateTutorEmail.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleUpdateDuration = this.handleUpdateDuration.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getOptsFromCurrentState = this.getOptsFromCurrentState.bind(this);
  }

  getOptsFromCurrentState() {
    const {
      page,
      chosenSortOption,
      chosenStatus,
      chosenType,
      chosenReason,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    } = this.state;
    const opts = {
      page,
      status: chosenStatus,
      sortOption: chosenSortOption,
      type: chosenType,
      reason: chosenReason,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    };
    return opts;
  }

  componentWillMount() {
    const {lessonIssueActions, issues, lastSearchOpts} = this.props;
    const currentOpts = Immutable.Map(this.getOptsFromCurrentState());
    if (!Immutable.is(currentOpts,lastSearchOpts) || issues.length === 0) {
      lessonIssueActions.fetch(currentOpts.toObject());
    }
  }

  handleUpdateSortOption(value) {
    this.setState({
      chosenSortOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateStatus(value) {
    this.setState({
      chosenStatus: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateType(value) {
    this.setState({
      chosenType: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateReason(value) {
    this.setState({
      chosenReason: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateStudentEmail(value) {
    this.setState({
      studentEmail: value,
      changeAdded: true,
    });
  }

  handleUpdateTutorEmail(value) {
    this.setState({
      tutorEmail: value,
      changeAdded: true,
    });
  }

  handleUpdatePage(value) {
    this.setState({
      page: parseInt(value),
      changeAdded: true,
    })
  }

  handleUpdateDuration(dates) {
    this.setState({
      durationStart: dates.startDate,
      durationEnd: dates.endDate,
      changeAdded: true,
    });
  }

  handleSubmit() {
    const {
      lessonIssueActions
    } = this.props
    const {
      page,
      chosenStatus,
      chosenSortOption,
      chosenTrialOption,
      studentEmail,
      tutorEmail,
      durationStart,
      durationEnd,
    } = this.state;
    const opts = this.getOptsFromCurrentState()
    this.changeUrl(opts);
    lessonIssueActions.fetch(opts);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    let first = true;
    let query = '?';
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          query += '&'
        }
        query += `${key}=${opts[key]}`;
        first = false;
      }
      baseUrl += query
    }
    this.props.history.push(this.props.match.path + query);
    window.scroll(0, 0);
  }

  render() {
    const {
      issues,
      count
    } = this.props;
    const {
      page,
      studentEmail,
      tutorEmail,
      chosenSortOption,
      chosenStatus,
      chosenType,
      chosenReason,
      changeAdded,
      durationStart,
      durationEnd,
    } = this.state;
    return (
      <LessonIssues
        issues={issues}
        count={count}
        studentEmail={studentEmail}
        tutorEmail={tutorEmail}
        page={page}
        chosenSortOption={chosenSortOption}
        chosenStatus={chosenStatus}
        chosenType={chosenType}
        chosenReason={chosenReason}
        changeAdded={this.state.changeAdded}
        handleUpdateSortOption={this.handleUpdateSortOption}
        handleSubmit={this.handleSubmit}
        handleUpdateStatus={this.handleUpdateStatus}
        handleUpdateType={this.handleUpdateType}
        handleUpdateReason={this.handleUpdateReason}
        handleUpdateStudentEmail={this.handleUpdateStudentEmail}
        handleUpdateTutorEmail={this.handleUpdateTutorEmail}
        handleUpdatePage={this.handleUpdatePage}
        onUpdateDuration={this.handleUpdateDuration}
        durationStart={durationStart}
        durationEnd={durationEnd}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonIssuesTableContainer);
