import Collection from '../utils/Collection.js';

import {
  FETCH_COURSES_SUCCEEDED,
  FETCH_COURSES_FAILED,
} from '../actions/course';

const initialState = {
  courses: [],
  count: 0,
  lastSearchOpts: {
    page: 1,
    sortOption: 0,
    status: 0,
    difficulty: 0
  }
}

export default function courseReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_COURSES_SUCCEEDED:
      return {
        ...state, 
        courses: Collection.reset(state.courses, payload.courses),
        count: payload.count,
        opts: payload.opts
      };
    default:
      return state;
  }
}