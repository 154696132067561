import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table';
import { Link } from 'react-router-dom';

class LinkCell extends Component {
  render() {
    const {
      baseUrl,
      tableName,
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    const link = baseUrl + tableName + '/' + data[rowIndex][field];
    return (
      <Cell {...props}>
        <Link to={link}>{data[rowIndex][field]}</Link>
      </Cell>
    );
  }
}

export default LinkCell;

LinkCell.defaultProps = {
  baseUrl: "/a/db/",
};

LinkCell.propTypes = {
  baseUrl: PropTypes.string,
};
