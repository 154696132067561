import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../form/TextInput';
import classnames from 'classnames';

class TutorBankInfoHome extends Component {

  render() {
    const {
      bankInfo,
      loading,
      updating,
      changeAdded,
      tempBankName, 
      tempBranchName,
      tempBranchNumber,
      tempNameOnAccount,
      tempAccountNumber,
      bankNameValidationResult,
      branchNumberValidationResult,
      branchNameValidationResult,
      nameOnAccountValidationResult,
      accountNumberValidationResult,
      onUpdateInput,
      onSubmit
    } = this.props;
    const bankNameProps = {
      placeholder: '',
      className: 'bank-name',
      value: tempBankName,
      stateType: "tempBankName",
      name: 'bank-name',
      type: 'text',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const branchNameProps = {
      placeholder: '',
      className: 'branch-name',
      value: tempBranchName,
      stateType: "tempBranchName",
      name: 'branch-name',
      type: 'text',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const branchNumberProps = {
      placeholder: '',
      className: 'branch-number',
      value: tempBranchNumber,
      stateType: "tempBranchNumber",
      name: 'branch-number',
      type: 'text',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const nameOnAccountProps = {
      placeholder: '',
      className: 'name-on-account',
      value: tempNameOnAccount,
      stateType: "tempNameOnAccount",
      name: 'name-on-account',
      type: 'text',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    const accountNumberProps = {
      placeholder: '',
      className: 'account-number',
      value: tempAccountNumber,
      stateType: "tempAccountNumber",
      name: 'account-umber',
      type: 'text',
      updateHandler: onUpdateInput,
      onKeyDown: onSubmit,
    };
    let saveReady = false
    if (changeAdded && !updating) {
      saveReady = true
    }
    const panelBodyClass = classnames("panel-body", {loading: loading || updating})
    return (
      <div id="main-content">
        <div className="panel">
          <form method="put" onSubmit={onSubmit}>
            <div className={panelBodyClass}>
              <h4 className="form-title">Edit Bank Info</h4>
              <p>Currently only banks in the Philippines are supported</p>
              <div className="form-body">
                <div className="row row-condensed row-space-4">
                  <label className="text-right col-sm-3 sm-text-left">
                    Bank Name*
                  </label>
                  <div className="col-sm-5">
                    <TextInput {...bankNameProps} />
                  </div>
                </div>
                <div className="row row-condensed row-space-4">
                  <label className="text-right col-sm-3 sm-text-left">
                    Branch Name*
                  </label>
                  <div className="col-sm-5">
                    <TextInput {...branchNameProps} />
                  </div>
                </div>
                <div className="row row-condensed row-space-4">
                  <label className="text-right col-sm-3 sm-text-left">
                    Branch Number*
                  </label>
                  <div className="col-sm-5">
                    <TextInput {...branchNumberProps} />
                  </div>
                </div>
                <div className="row row-condensed row-space-4">
                  <label className="text-right col-sm-3 sm-text-left">
                    Name on Account*
                  </label>
                  <div className="col-sm-5">
                    <TextInput {...nameOnAccountProps} />
                  </div>
                </div>
                <div className="row row-condensed row-space-4">
                  <label className="text-right col-sm-3 sm-text-left">
                    Account Number*
                  </label>
                  <div className="col-sm-5">
                    <TextInput {...accountNumberProps} />
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-footer">
              <div className="text-right">
                <button
                  className="btn btn-primary btn-large"
                  type="submit"
                  disabled={!saveReady}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

}

export default TutorBankInfoHome;