import Collection from '../utils/Collection.js';

import {
  FETCH_LESSON_SUCCEEDED,
  FETCH_LESSON_FAILED
} from '../actions/lesson';

import {
  UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED,
} from '../actions/lessonIssue';

const initialState = {
  lesson: null,
  review: null,
  summary: null,
  issue: null
}

export default function lessonDataReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_LESSON_SUCCEEDED:
      return {
        ...state, 
        lesson: payload.lesson,
        review: payload.review,
        summary: payload.summary,
        issue: payload.issue
      };
    case UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED: 
      return {
        ...state,
        issue: payload.issue
      }
    default:
      return state;
  }
}