import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import TimeslotConstants from '../../../constants/TimeslotConstants';
import _ from 'lodash';
import CalendarTimeslot from './CalendarTimeslot';

const msOfOneDay = 86400000;
const msOfHalfAnHour = 1800000;
const TWENTY_FIVE_MINUTES = 1500000;
const TWENTY_MINUTES = 1200000;
const FIFTY_MINUTES = 3000000;

class CalendarTimeslotTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenTimestamp: null,
      type: 'Book',
    };
    this.getSlotAvailability = this.getSlotAvailability.bind(this);
    this.getSlotLessonNum = this.getSlotLessonNum.bind(this);
  }

  getSlotAvailability(timestamp, slotNum) {
    const shifts = this.props.shifts;
    const shiftsOfSlot = _.filter(shifts,
      (s) => { 
        return _.inRange(timestamp, s.duration_start, s.duration_end) && 
          _.inRange(slotNum, s.slot_start, s.slot_end)
      }
    );
    return shiftsOfSlot.length;
  }

  getSlotLessonNum(slotNum) {
    const { lessonNumsOfSlots } = this.props;
    let num = parseFloat(lessonNumsOfSlots[slotNum.toString()]) / 2.0
    if (isNaN(num)) {
      num = 0;
    }
    return num;
  }

  render() {
    const { shifts, loadingShifts, startTime } = this.props;
    const timeslotArray = TimeslotConstants.slotArray.slice(0, 48);
    let timeslots;
    let slotCountToShow = 0;
    let arrayForCheckNeededRows = new Array(48)
    if (!loadingShifts) {
      _.map(timeslotArray,
        (time, key) => {
          _.map([0, 1, 2, 3, 4, 5, 6],
            (n) => {
              let copyOfStartTime = _.cloneDeep(startTime)
              const timestampOfSlot = copyOfStartTime
                .add(n, 'days')
                .add(key * 30, 'minutes')
                .format('x');
              const slotNum = n * 24 + key * 0.5;
              const slotAvailability = this.getSlotAvailability(timestampOfSlot, slotNum);
              const slotLessonNum = this.getSlotLessonNum(slotNum);
              let ratio = (slotAvailability - slotLessonNum) / slotAvailability
              if (isNaN(ratio)) {
                ratio = 0;
              }
              const slotProps = {
                key: slotNum,
                ref: `slot${slotNum.toString()}`,
                slotNum,
                ratio,
                timestampOfSlot,
                slotAvailability,
              };
              arrayForCheckNeededRows[key] = arrayForCheckNeededRows[key] || [];
              arrayForCheckNeededRows[key].push(slotProps);
          });
      });
      let start = 0;
      let end = 47;
      const neededRowsArray = _.range(start, end + 1)
      timeslots = neededRowsArray.map(val => {
        let upperOrLower = val % 2 === 0 ? 'upper' : 'lower';
        let ts = [0, 1, 2, 3, 4, 5, 6].map(n => {
          const slotProps = arrayForCheckNeededRows[val][n];
          return <CalendarTimeslot {...slotProps} />;
        });
        return (
          <tr key={`calendar-timeslot-${val}`}>
            <td className="side">{val % 2 === 0 ? timeslotArray[val] : ''}</td>
            <td className={upperOrLower}>
              {ts}
            </td>
          </tr>
        );
      });
    } else {
      timeslots = (
        <tr className="row-space-top-4 row-space-4">
          <td colSpan={8} height="70" className="text-center h5">Loading..</td>
        </tr>
      );
    }
    
    let timeslotDivStyle = { cellpadding: 0 };
    return (
      <table className="timeslot-table" style={timeslotDivStyle}>
        <tbody>
          {timeslots}
        </tbody>
      </table>
    );
  }
}


CalendarTimeslotTable.propTypes = {
  startTime: PropTypes.object,
  shifts: PropTypes.array
};

export default CalendarTimeslotTable;
