import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AdminDatabaseDashboard extends Component {
  render() {
    return (
      <div id="admin-database-dashboard">
        <div className="container-fluid">
          <div className="row">
            <ul>
              <li><Link className="row-space-2" to="/a/db/users">Users</Link></li>
              <li><Link className="row-space-2" to="/a/db/lessons">Lessons</Link></li>
              <li><Link className="row-space-2" to="/a/db/lesson-issues">Lesson Issues</Link></li>
              <li><Link className="row-space-2" to="/a/db/payments">Payments</Link></li>
              <li><Link className="row-space-2" to="/a/db/courses">Courses</Link></li>
              <li><Link className="row-space-2" to="/a/db/materials">Materials</Link></li>
              <li><Link className="row-space-2" to="/a/db/holidays">Holidays</Link></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDatabaseDashboard;
