import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classnames from 'classnames';

class Flash extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.autoDisappear && nextProps.message.length > 0) {
      window.setTimeout(() => {
        nextProps.clearFlashMessage();
      }, 3000)
    }
  }

  onClick(e) {
    e.preventDefault();
    this.props.clearFlashMessage();
  }

  render() {
    const {type, message, sticky} = this.props
    const alertClass = classnames("alert alert-notice", {"alert-info": type === "success"}, {"alert-error": type === "error"});
    const wrapperClass = classnames("flash-container", {fixed: sticky});
    let transitionName = "flash-anim"
    if (message.length > 0) {
      return (
        <ReactCSSTransitionGroup transitionAppear={true}　transitionAppearTimeout={500} transitionName={transitionName} transitionEnterTimeout={200} transitionLeaveTimeout={300}>
          <div className={wrapperClass} id="flash-component">
            <div className={alertClass}>
              <a className="close alert-close" onClick={this.onClick}>x</a>
              <i className="icon alert-icon icon-comment"></i>
              {message}
            </div>
          </div>
        </ReactCSSTransitionGroup>
      );
    } else {
      return <ReactCSSTransitionGroup transitionAppear={true} transitionAppearTimeout={500} transitionName={transitionName} transitionEnterTimeout={200} transitionLeaveTimeout={300} />;
    }
  }
}

Flash.propTypes = {
  message: PropTypes.string,
  autoDisappear: PropTypes.bool,
  clearFlashMessage: PropTypes.func.isRequired,
  type: PropTypes.string,
}


export default Flash;