import Collection from '../utils/Collection.js';

import {
  FETCH_HOLIDAYS_SUCCEEDED,
  FETCH_HOLIDAYS_FAILED
} from '../actions/holiday';

import {
  FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED
} from '../actions/tutor'

const initialState = {
  holidays: [],
  count: 0,
  lastSearchOpts: {
    page: 1,
    sortOption: 0,
    status: 0
  }
}

export default function holidayReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_HOLIDAYS_SUCCEEDED:
      return {
        ...state, 
        holidays: Collection.reset(state.holidays, payload.items),
        count: payload.count,
        opts: payload.opts
      };
    case FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED:
      return {
        ...state,
        holidays: Collection.reset(state.holidays, payload.items),
      }
    default:
      return state;
  }
}