class BaseValidator {
  constructor() {}
  cannotEmpty(value) {
    return new Promise((resolve, reject) => {
      if (!!value) {
        resolve({value: value, success: true});
      } else {
        resolve({success: false, err: " cannot be empty."});
      }
    })
  }

}

export default BaseValidator;
