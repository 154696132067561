import Collection from '../utils/Collection.js';

import {
  CLEAR_LESSON_ISSUE_REDUCER,
  FETCH_LESSON_ISSUES_SUCCEEDED,
  FETCH_LESSON_ISSUES_FAILED,
  REPORT_LESSON_ISSUE_SENT,
  REPORT_LESSON_ISSUE_SUCCEEDED,
  REPORT_LESSON_ISSUE_FAILED,
  RESOLVE_LESSON_ISSUE_SENT,
  RESOLVE_LESSON_ISSUE_SUCCEEDED,
  RESOLVE_LESSON_ISSUE_FAILED,
  REPORT_AND_RESOLVE_LESSON_ISSUE_SENT,
  REPORT_AND_RESOLVE_LESSON_ISSUE_SUCCEEDED,
  REPORT_AND_RESOLVE_LESSON_ISSUE_FAILED,
  UPDATE_SELECTED_LESSON_ISSUE_TYPE,
  UPDATE_SELECTED_LESSON_ISSUE_ACTION,
  UPDATE_SELECTED_LESSON_ISSUE_REASON,
  UPDATE_LESSON_ISSUE_COMMENT,
  UPDATE_RESOLVED_LESSON_ISSUE_SENT,
  UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED,
  UPDATE_RESOLVED_LESSON_ISSUE_FAILED
} from '../actions/lessonIssue';

const initialState = {
  loading: false,
  saving: false,
  issues: [],
  count: 0,
  lastSearchOpts: {
    page: 1,
    sortOption: 0,
    status: 0,
    type: 0,
    studentEmail: "",
    tutorEmail: ""
  },
  selectedType: null,
  selectedAction: null,
  selectedReason: null,
  comment: "",
}

export default function lessonIssueReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case CLEAR_LESSON_ISSUE_REDUCER:
      return initialState;
    case FETCH_LESSON_ISSUES_SUCCEEDED:
      return {
        ...state, 
        issues: Collection.reset(state.issues, payload.issues),
        count: payload.count,
        lastSearchOpts: payload.opts
      };
    case UPDATE_SELECTED_LESSON_ISSUE_TYPE:
      return {
        ...state, 
        selectedType: payload.value
      };
    case UPDATE_SELECTED_LESSON_ISSUE_ACTION:
      return {
        ...state, 
        selectedAction: payload.value
      };
    case UPDATE_SELECTED_LESSON_ISSUE_REASON:
      return {
        ...state, 
        selectedReason: payload.value
      };
    case UPDATE_LESSON_ISSUE_COMMENT:
      return {
        ...state, 
        comment: payload.value
      };
    case REPORT_LESSON_ISSUE_SENT:
    case RESOLVE_LESSON_ISSUE_SENT:
    case REPORT_AND_RESOLVE_LESSON_ISSUE_SENT:
    case UPDATE_RESOLVED_LESSON_ISSUE_SENT:
      return {
        ...state, 
        saving: true
      };
    case RESOLVE_LESSON_ISSUE_SUCCEEDED:
    case UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED:
      return {
        ...state, 
        issues: Collection.update(state.issues, payload.issue.id, payload.issue),
        saving: false
      }
    case UPDATE_RESOLVED_LESSON_ISSUE_FAILED:
      return {
        ...state,
        saving: false,
      }
    default:
      return state;
  }
}