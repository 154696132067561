import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class PasswordInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
      validationResult: null,
      showInvalidLabel: true
    }
    this._updateState = this._updateState.bind(this);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    let showInvalidLabel;
    if (nextProps.invalidLabel === true) {
      showInvalidLabel = true;
    }
    this.setState({
      value: nextProps.value,
      validationResult: nextProps.validationResult,
      showInvalidLabel: showInvalidLabel
    })
  }

  _onKeyDown(e) {
    if (e.keyCode === 13) {
      this.props.onKeyDown(e);
    }
  }

  _updateState(value) {
    let _this = this;
    return new Promise((resolve, reject) => {
      _this.setState({
        value: value
      });
      resolve("updated state");
    });
  }

  _handleChange(event) {
    let _this = this;
    this._updateState(event.target.value).then((log) => {
      _this.props.updateHandler(_this.state.value);
    });
  }
  render() {
    let className, ErrorLabel;
    if (this.props.validationResult && this.props.validationResult.success === false) {
      if(this.props.invalidLabel && this.state.showInvalidLabel) {
        ErrorLabel = (<label className="invalid">{this.props.validationResult.message}</label>);
      }
      className = classnames(this.props.className, 'invalid')
    } else {
      className = this.props.className
    }
    return (
      <div>
        {ErrorLabel}
        <input type="password" name={this.props.name} value={this.state.value} onChange={this._handleChange.bind(this)} onKeyDown={this._onKeyDown.bind(this)} className={className} placeholder={this.props.placeholder} />
      </div>
    );
  }
};

export default PasswordInput;