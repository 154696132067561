import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE, END_DATE} from 'react-dates/constants';
import Select from '../../form/Select';
import TextInput from '../../form/TextInput';

class UserFilter extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getPageOptions = this.getPageOptions.bind(this);
    this.getProfileOptions = this.getProfileOptions.bind(this);
    this.getLocaleOptions = this.getLocaleOptions.bind(this);
    this.getPlanOptions = this.getPlanOptions.bind(this);
    this.getPlanStatusOptions = this.getPlanStatusOptions.bind(this);
    this.getSortOptions = this.getSortOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFocusOnDurationChange = this.onFocusOnDurationChange.bind(this);
    this.state = {
      focusedOnRangePicker: null,
    };
  }

  onFocusOnDurationChange(focused) {
    this.setState({
      focusedOnRangePicker: focused,
    });
  }

  getOptions(keyBase, baseOpts) {
    let options = [];
    baseOpts.map((val, index) => {
      options.push({ key: keyBase + index, name: val, value: index });
    });
    return options;
  }

  getPageOptions() {
    const count = this.props.count;
    const raw_pages = count / 100;
    if (count === 0 || raw_pages <= 1) {
      return [{ key: 'user-page-1', name: 'page1', value: 1 }]
    }
    const floored = Math.floor(raw_pages);
    const totalPages = raw_pages === floored > 0 ? floored + 1 : raw_pages;
    let options = [];
    for (let i = 1; i < totalPages; i++) {
      options.push({ key: 'user-page-' + i, name: 'page' + i, value: i });
    }
    return options;
  }

  getProfileOptions() {
    const base = ['Select Profile', 'Student', 'Tutor'];
    return this.getOptions('user-profile-', base);
  }

  getLocaleOptions() {
    const base = ['Select Locale', 'en', 'ja', 'es', 'pt', 'ru', 'tw'];
    return this.getOptions('user-locale-', base);
  }

  getPlanOptions() {
    const base = ['Select Plan', 'Trial', 'Starter', 'Regular', 'Plus', 'Premium'];
    return this.getOptions('user-plan-', base);
  }

  getPlanStatusOptions() {
    const base = ['Select Plan Status', 'Active', 'Suspended', 'Cancelled'];
    return this.getOptions('user-plan-status-', base);
  }

  getSortOptions() {
    const base = ['Sort by created at', 'Sort by last login'];
    return this.getOptions('user-sort-', base);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    const {
      focusedOnRangePicker,
    } = this.state;
    const {
      count,
      page,
      changeAdded,
      chosenSortOption,
      chosenLocale,
      chosenPlan,
      chosenPlanStatus,
      chosenProfile,
      nameOrEmail,
      handleUpdatePage,
      handleUpdateLocale,
      handleUpdateProfile,
      handleUpdatePlan,
      handleUpdatePlanStatus,
      handleUpdateSortOption,
      handleUpdateNameOrEmail,
      handleSubmit,
      durationEnd,
      durationStart,
      onUpdateDuration,
    } = this.props

    const sortProps = {
      options: this.getSortOptions(),
      updateHandler: handleUpdateSortOption,
      value: chosenSortOption,
    };
    const profileProps = {
      options: this.getProfileOptions(),
      updateHandler: handleUpdateProfile,
      value: chosenProfile,
    };
    const planProps = {
      options: this.getPlanOptions(),
      updateHandler: handleUpdatePlan,
      value: chosenPlan,
    };
    const planStatusProps = {
      options: this.getPlanStatusOptions(),
      updateHandler: handleUpdatePlanStatus,
      value: chosenPlanStatus,
    };
    const localeProps = {
      options: this.getLocaleOptions(),
      updateHandler: handleUpdateLocale,
      value: chosenLocale,
    };
    const pageProps = {
      options: this.getPageOptions(),
      updateHandler: handleUpdatePage,
      value: page
    }
    const nameOrEmailProps = {
      placeholder: 'Name or Email',
      className: '',
      value: nameOrEmail,
      name: 'name-or-email',
      updateHandler: handleUpdateNameOrEmail,
      onKeyDown: this.handleSubmit,
    };

    return (
      <div className="data-filter">
        <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
          <li className="row-space-2">
            <Select {...sortProps} />
          </li>
          <li className="row-space-2">
            <Select {...profileProps} />
          </li>
          <li className="row-space-2">
            <Select {...planProps} />
          </li>
          <li className="row-space-2">
            <Select {...planStatusProps} />
          </li>
          <li className="row-space-2">
            <Select {...localeProps} />
          </li>
          <li className="row-space-2">
            <TextInput {...nameOrEmailProps} />
          </li>
          <li className="row-space-2">
            <DateRangePicker
              startDatePlaceholderText={"Created At - Start"}
              endDatePlaceholderText={"Created At - End"}
              enableOutsideDays={true}
              withPortal={true}
              startDate={durationStart}
              endDate={durationEnd}
              numberOfMonths={1}
              focusedInput={focusedOnRangePicker}
              isOutsideRange={() => false}
              onDatesChange={(dates) => { onUpdateDuration(dates) }}
              onFocusChange={( focused ) => { this.onFocusOnDurationChange(focused) }}
            />
          </li>
          <li className="row-space-2">
            <Select {...pageProps} />
          </li>
          <li className="row-space-2">
            <button onClick={this.handleSubmit} className="btn btn-success" disabled={!changeAdded}>Search</button>
          </li>
        </ul>
      </div>
    );
  }
}

UserFilter.propTypes = {
  changeAdded: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleUpdateSortOption: PropTypes.func,
  handleUpdatePage: PropTypes.func,
  handleUpdateLocale: PropTypes.func,
  handleUpdateProfile: PropTypes.func,
  handleUpdatePlan: PropTypes.func,
  handleUpdatePlanStatus: PropTypes.func,
  handleUpdateNameOrEmail: PropTypes.func,
  chosenSortOption: PropTypes.number,
  chosenLocale: PropTypes.number,
  chosenPlan: PropTypes.number,
  chosenPlanStatus: PropTypes.number,
  chosenProfile: PropTypes.number,
  nameOrEmail: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number
};

export default UserFilter;
