import TutorBankInfoApiUtils from '../utils/api/tutorBankInfo';
import {addMessage as addFlashMessage} from './flash';

export const FETCH_BANK_INFO_BY_TUTOR_SENT = 'FETCH_BANK_INFO_BY_TUTOR_SENT';
export const FETCH_BANK_INFO_BY_TUTOR_SUCCEEDED = 'FETCH_BANK_INFO_BY_TUTOR_SUCCEEDED';
export const FETCH_BANK_INFO_BY_TUTOR_FAILED = 'FETCH_BANK_INFO_BY_TUTOR_FAILED';
export const UPDATE_BANK_INFO_SENT = 'UPDATE_BANK_INFO_SENT';
export const UPDATE_BANK_INFO_SUCCEEDED = 'UPDATE_BANK_INFO_SUCCEEDED';
export const UPDATE_BANK_INFO_FAILED = 'UPDATE_BANK_INFO_FAILED';

export function fetchByTutorSent() {
  return {
    type: FETCH_BANK_INFO_BY_TUTOR_SENT
  }
}

export function fetchByTutorSucceeded(data) {
  return {
    type: FETCH_BANK_INFO_BY_TUTOR_SUCCEEDED,
    payload: data
  }
}

export function fetchByTutorFailed(error) {
  return {
    type: FETCH_BANK_INFO_BY_TUTOR_FAILED
  }
}

export function updateSent(data) {
  return {
    type: UPDATE_BANK_INFO_SENT
  }
}

export function updateSucceeded(data) {
  return {
    type: UPDATE_BANK_INFO_SUCCEEDED,
    payload: data
  }
}

export function updateFailed(error) {
  return {
    type: UPDATE_BANK_INFO_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetchByTutor(tutorId) {
  return (dispatch) => {
    dispatch(fetchByTutorSent);
    return TutorBankInfoApiUtils.fetchByTutor(tutorId).then(
      (data) => dispatch(fetchByTutorSucceeded(data)),
      (error) => dispatch(fetchByTutorFailed(error))
    );
  };
}

export function update(params) {
  return (dispatch) => {
    dispatch(updateSent());
    return TutorBankInfoApiUtils.update(params).then(
      (data) => {
        dispatch(addFlashMessage("Successfully updated", {autoDisappear: true, sticky: true}));
        dispatch(updateSucceeded(data))
      },
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error", sticky: true}));
        dispatch(updateFailed(error))
      }
    );
  };
}
