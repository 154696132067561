import TimeZoneApiUtils from '../utils/api/timeZone.js';

export const FETCH_TIME_ZONES_SUCCEEDED = 'FETCH_TIME_ZONES_SUCCEEDED';
export const FETCH_TIME_ZONES_FAILED = 'FETCH_TIME_ZONES_FAILED';

export function fetchSucceeded(data) {
  return {
    type: FETCH_TIME_ZONES_SUCCEEDED,
    payload: data
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_TIME_ZONES_FAILED
  }
}

export function fetch() {
  return (dispatch) => {
    return TimeZoneApiUtils.fetch().then(
      (data) => dispatch(fetchSucceeded(data)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}