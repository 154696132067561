import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const UserApiUtils = {
  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.FetchUsers + query);
  },
  fetchOne(userId) {
    return xhr(ApiConstants.FetchUsers + '/' + userId);
  },
  createTutor(params) {
    let createParams = {};
    const re = /[A-Z][a-z]+/g;
    for (const key of Object.keys(params)) {
      if (params[key] !== null) {
        let ar = key.match(re)
        var newKey = '';
        for (let i = 0; i < ar.length; i++) {
          if (i === 0) {
            newKey += ar[i].toLowerCase();
          } else {
            newKey += '_' + ar[i].toLowerCase();
          }
        }
        createParams[newKey] = params[key];
      }
    }
    return xhr(ApiConstants.Base + 'users/as_tutor/', {
      method: 'post',
      body: JSON.stringify({...createParams})
    });
  },
  updateTutor(userId, params) {
    let updatedParams = {};
    const re = /[A-Z][a-z]+/g;
    for (const key of Object.keys(params)) {
      if (params[key] !== null) {
        let ar = key.match(re)
        var newKey = '';
        for (let i = 0; i < ar.length; i++) {
          if (i === 0) {
            newKey += ar[i].toLowerCase();
          } else {
            newKey += '_' + ar[i].toLowerCase();
          }
        }
        updatedParams[newKey] = params[key];
      }
    }
    return xhr(ApiConstants.FetchUsers + '/' + userId + '/as-tutor', {
      method: 'PUT',
      body: JSON.stringify({user: updatedParams})
    });
  },
  update(userId, params) {
    let updatedParams = {};
    const re = /[A-Z][a-z]+/g;
    for (const key of Object.keys(params)) {
      if (params[key] !== null) {
        let ar = key.match(re)
        var newKey = '';
        for (let i = 0; i < ar.length; i++) {
          if (i === 0) {
            newKey += ar[i].toLowerCase();
          } else {
            newKey += '_' + ar[i].toLowerCase();
          }
        }
        updatedParams[newKey] = params[key];
      }
    }
    return xhr(ApiConstants.FetchUsers + '/' + userId, {
      method: 'put',
      body: JSON.stringify({user: updatedParams})
    });
  },
  uploadAvatar(userId, file) {
    return xhr(ApiConstants.FetchUsers + '/' + userId + '/avatar', {
      method: 'post',
      body: JSON.stringify({ file }),
    });
  },

  deleteAvatar(userId) {
    return xhr(ApiConstants.FetchUsers + '/' + userId + '/avatar', {
      method: 'delete',
    });
  },


};

export default UserApiUtils;
