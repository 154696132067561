import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as CourseActions from '../../../actions/course';
import classnames from 'classnames';
import Courses from '../../../components/db/courses/Courses';
import Immutable from 'immutable';
import qs from 'query-string';

function mapStateToProps(state) {
  const { course } = state;
  const { courses, count, lastSearchOpts } = course;
  return {
    courses,
    count,
    lastSearchOpts
  }
}

function mapDispatchToProps(dispatch) {
  return { courseActions: bindActionCreators(CourseActions, dispatch)};
}

class CoursesTableContainer extends Component {

  constructor(props) {
    super(props);
    const query = qs.parse(props.location.search);
    this.state = {
      page: parseInt(query.page) || 1,
      changeAdded: false,
      chosenSortOption: parseInt(query.sortOption) || 0,
      chosenStatus: parseInt(query.status) || 0,
      chosenDifficulty: parseInt(query.difficulty) || 0
    };
    this.handleUpdateSortOption = this.handleUpdateSortOption.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdateDifficulty = this.handleUpdateDifficulty.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getOptsFromCurrentState = this.getOptsFromCurrentState.bind(this);
  }

  getOptsFromCurrentState() {
    const {
      page,
      chosenSortOption,
      chosenStatus,
      chosenDifficulty
    } = this.state;
    const opts = {
      page,
      status: chosenStatus,
      sortOption: chosenSortOption,
      difficulty: chosenDifficulty
    };
    return opts;
  }

  componentWillMount() {
    const {courseActions, courses, lastSearchOpts} = this.props;
    const currentOpts = Immutable.Map(this.getOptsFromCurrentState());
    if (!Immutable.is(currentOpts,lastSearchOpts) || courses.length === 0) {
      courseActions.fetch(currentOpts.toObject());
    }
  }

  handleUpdateSortOption(value) {
    this.setState({
      chosenSortOption: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateStatus(value) {
    this.setState({
      chosenStatus: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdateDifficulty(value) {
    this.setState({
      chosenDifficulty: parseInt(value),
      changeAdded: true,
    });
  }

  handleUpdatePage(value) {
    this.setState({
      page: parseInt(value),
      changeAdded: true,
    })
  }

  handleSubmit() {
    const {
      courseActions
    } = this.props
    const opts = this.getOptsFromCurrentState();
    this.changeUrl(opts);
    courseActions.fetch(opts);
  }

  changeUrl(opts) {
    const location = window.location;
    let baseUrl = location.href;
    baseUrl = baseUrl.replace(/\?.*/, '');
    baseUrl += '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          baseUrl += '&';
        }
        baseUrl += `${key}=${opts[key]}`;
        first = false;
      }
    }
    window.history.pushState(opts, '', baseUrl);
    window.scroll(0, 0);
  }

  render() {
    const {
      courses,
      count
    } = this.props;
    const {
      changeAdded,
      page,
      chosenSortOption,
      chosenStatus,
      chosenDifficulty
    } = this.state;
    return (
      <Courses
        courses={courses}
        count={count}
        page={page}
        chosenSortOption={chosenSortOption}
        chosenStatus={chosenStatus}
        chosenDifficulty={chosenDifficulty}
        changeAdded={this.state.changeAdded}
        handleUpdateSortOption={this.handleUpdateSortOption}
        handleSubmit={this.handleSubmit}
        handleUpdateStatus={this.handleUpdateStatus}
        handleUpdateDifficulty={this.handleUpdateDifficulty}
        handleUpdatePage={this.handleUpdatePage}/>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesTableContainer);
