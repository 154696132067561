import BaseValidator from './BaseValidator.js';
import moment from 'moment-timezone';

class UserValidator extends BaseValidator {
  constructor(opts={}) {
    super();
    this.validationErrors = [];
    this.validity = null;
    this.email = opts.email || "";
  }

  validateTutorProfile() {
    const validityCheck = Promise.all([
      this.validateEmail(),
    ]).then((responses) => {
      if (this.validationErrors.length === 0) {
        return Promise.resolve({valid: true});
      } else {
        return Promise.resolve({valid: false, errors: this.validationErrors});
      }
    })
    return validityCheck
  }

  validate() {
    const validityCheck = Promise.all([
      this.validateEmail(),
    ]).then((responses) => {
      if (this.validationErrors.length === 0) {
        return Promise.resolve({valid: true});
      } else {
        return Promise.resolve({valid: false, errors: this.validationErrors});
      }
    })
    return validityCheck
  }

  dealValidationResult(type, res) {
    if (res.success) {
      return res;
    } else {
      this.validationErrors.push(type + res.err);
      return res;
    }
  }

  validateEmail() {
    if (this.email === null) {
      return true;
    }
    super.cannotEmpty(this.email)
    .then(this.checkEmailFormat)
    .then((res) => {
      this.dealValidationResult("Email", res);
    })
  }

  validatePassword() {
    if (this.password === null) {
      return true;
    }
    super.cannotEmpty(this.password)
    .then((res) => {
      this.dealValidationResult("Password", res);
    })
  }

  checkEmailFormat(res) {
    const re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
    if (!res.success || re.test(res.value) === true) {
      return res
    } else {
      return {success: false, err: " format is invalid."}
    }
  }

}

export default UserValidator;
