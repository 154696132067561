import React from 'react';
import DataFilter from './Filter';
import Table from './Table';

class DataByTutorsMain extends React.Component {

  constructor() {
    super();
    this.state={
      focused: false
    }
  }

  render() {
    const {
      loading,
      loaded,
      changeAdded,
      chosenDate,
      count,
      dataList,
      handleUpdateChosenDate,
      handleSubmit
    } = this.props;
    if (!loaded) {
      return <div />;
    }
    return (
      <div id="data-by-tutors">
        <DataFilter
          changeAdded={changeAdded}
          chosenDate={chosenDate}
          handleUpdateChosenDate={handleUpdateChosenDate}
          handleSubmit={handleSubmit} />
        <Table
          count={count}
          dataList={dataList} />
      </div>
    );
  }
}

export default DataByTutorsMain;
