import _ from 'lodash';

const Collection = {
  add(collection, models) {
    const singular = !_.isArray(models);
    models = singular ? (models ? [models] : []) : _.clone(models);

    // Loop over the array and try to add them to the collection.
    _.each(models, (model) => {
      // Make sure this is a unique item. If there is already a match, fail this.
      const unique = _.find(collection, { id: model.id });
      if (!_.isUndefined(unique)) return;
      collection.push(model);
    });
    return collection;
  },
  all(collection) {
  },

  reset(collection, data) {
    const emptyCollection = this.removeAll(collection);
    const newCollection = this.add(emptyCollection, data);
    return newCollection;
  },
  get(collection, id) {
    return _.find(collection, { id });
  },

  where(collection, context) {
    if (_.isEmpty(context) || collection.length === 0) return [];
    // Filter the collection
    const results = _.where(collection, context);
    return results;
  },

  removeAndAdd(collection, changedObject) {
    const tempCollection = this.remove(collection, changedObject);
    const newCollection = this.add(tempCollection, changedObject);
    return newCollection;
  },

  update(collection, id, changedObject, options = {}) {
    // Find the correct entry and update it with the new info
    let model = _.find(collection, { id }) || {};
    // Apply all the data to the object
    model = _.assign(model, changedObject);

    // Remove the old object and add the new one
    collection = _.reject(collection, { id });
    collection.push(model);
    return collection;
  },

  updateMany(collection, changedObjects, options = {}) {
    let newCollection
    for (const changedObject of changedObjects) {
      newCollection = this.update(collection, changedObject.id, changedObject, options);
    }
    return newCollection;
  },

  remove(collection, changedObject, options = {}) {
    // Find the correct entry and update it with the new info
    const index = _.findIndex(collection, { id: changedObject.id });

    if (index !== -1) {
      // Remove the old object
      collection.splice(index, 1);
    }
    return collection;
  },

  removeAll(collection) {
    collection = [];
    return collection;
  },

  first(collection) {

  },

  size(collection) {
    return _.size(collection);
  },

};

export default Collection;
