import LessonIssueApiUtils from '../utils/api/lessonIssue';
import {addMessage as addModalMessage} from './modal';
import {addMessage as addFlashMessage} from './flash';
import {hide as hideModal} from './modal';

export const CLEAR_LESSON_ISSUE_REDUCER = 'CLEAR_LESSON_ISSUE_REDUCER';
export const FETCH_LESSON_ISSUES_SUCCEEDED = 'FETCH_LESSON_ISSUES_SUCCEEDED';
export const FETCH_LESSON_ISSUES_FAILED = 'FETCH_LESSON_ISSUES_FAILED';
export const REPORT_LESSON_ISSUE_SENT = 'REPORT_LESSON_ISSUE_SENT';
export const REPORT_LESSON_ISSUE_SUCCEEDED = 'REPORT_LESSON_ISSUE_SUCCEEDED';
export const REPORT_LESSON_ISSUE_FAILED = 'REPORT_LESSON_ISSUE_FAILED';
export const RESOLVE_LESSON_ISSUE_SENT = 'RESOLVE_LESSON_ISSUE_SENT';
export const RESOLVE_LESSON_ISSUE_SUCCEEDED = 'RESOLVE_LESSON_ISSUE_SUCCEEDED';
export const RESOLVE_LESSON_ISSUE_FAILED = 'RESOLVE_LESSON_ISSUE_FAILED';
export const UPDATE_RESOLVED_LESSON_ISSUE_SENT = 'UPDATE_RESOLVED_LESSON_ISSUE_SENT';
export const UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED = 'UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED';
export const UPDATE_RESOLVED_LESSON_ISSUE_FAILED = 'UPDATE_RESOLVED_LESSON_ISSUE_FAILED';
export const REPORT_AND_RESOLVE_LESSON_ISSUE_SENT = 'REPORT_AND_RESOLVE_LESSON_ISSUE_SENT';
export const REPORT_AND_RESOLVE_LESSON_ISSUE_SUCCEEDED = 'REPORT_AND_RESOLVE_LESSON_ISSUE_SUCCEEDED';
export const REPORT_AND_RESOLVE_LESSON_ISSUE_FAILED = 'REPORT_AND_RESOLVE_LESSON_ISSUE_FAILED';
export const UPDATE_SELECTED_LESSON_ISSUE_TYPE = 'UPDATE_SELECTED_LESSON_ISSUE_TYPE';
export const UPDATE_SELECTED_LESSON_ISSUE_ACTION = 'UPDATE_SELECTED_LESSON_ISSUE_ACTION';
export const UPDATE_SELECTED_LESSON_ISSUE_REASON = 'UPDATE_SELECTED_LESSON_ISSUE_REASON';
export const UPDATE_LESSON_ISSUE_COMMENT = 'UPDATE_LESSON_ISSUE_COMMENT';

export function clear() {
  return { type: CLEAR_LESSON_ISSUE_REDUCER }
}

export function fetchSucceeded(data, opts) {
  return {
    type: FETCH_LESSON_ISSUES_SUCCEEDED,
    payload: Object.assign({}, data, {opts})
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_LESSON_ISSUES_FAILED
  }
}

export function updateSelectedType(value) {
  return {
    type: UPDATE_SELECTED_LESSON_ISSUE_TYPE,
    payload: {
      value
    }
  }
}

export function updateSelectedAction(value) {
  return {
    type: UPDATE_SELECTED_LESSON_ISSUE_ACTION,
    payload: {
      value
    }
  }
}

export function updateSelectedReason(value) {
  return {
    type: UPDATE_SELECTED_LESSON_ISSUE_REASON, 
    payload: {
      value
    }
  }
}

export function updateComment(value) {
  return {
    type: UPDATE_LESSON_ISSUE_COMMENT, 
    payload: {
      value
    }
  }
}

export function reportSent() {
  return {
    type: REPORT_LESSON_ISSUE_SENT
  }
}

export function reportSucceeded(data) {
  return {
    type: REPORT_LESSON_ISSUE_SUCCEEDED,
    payload: data
  }
}

export function reportFailed(error) {
  return {
    type: REPORT_LESSON_ISSUE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function resolveSent() {
  return {
    type: RESOLVE_LESSON_ISSUE_SENT
  }
}

export function resolveSucceeded(data) {
  return {
    type: RESOLVE_LESSON_ISSUE_SUCCEEDED,
    payload: data
  }
}

export function resolveFailed(error) {
  return {
    type: RESOLVE_LESSON_ISSUE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function reportAndResolveSent() {
  return {
    type: REPORT_AND_RESOLVE_LESSON_ISSUE_SENT
  }
}

export function reportAndResolveSucceeded(data) {
  return {
    type: REPORT_AND_RESOLVE_LESSON_ISSUE_SUCCEEDED,
    payload: data
  }
}

export function reportAndResolveFailed(error) {
  return {
    type: REPORT_AND_RESOLVE_LESSON_ISSUE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function fetch(opts) {
  return (dispatch) => {
    return LessonIssueApiUtils.fetch(opts).then(
      (data) => dispatch(fetchSucceeded(data, opts)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}


export function report(lessonId, issueType, comment) {
  return (dispatch) => {
    return LessonIssueApiUtils.report(lessonId, issueType, comment).then(
      (data) => dispatch(reportSucceeded(data)),
      (error) => dispatch(reportFailed(error))
    );
  };
}

export function resolve(id, selectedAction, selectedReason, comment) {
  return (dispatch) => {
    dispatch(resolveSent());
    return LessonIssueApiUtils.resolve(id, selectedAction, selectedReason, comment).then(
      (data) => {
        dispatch(hideModal());
        dispatch(resolveSucceeded(data))
        dispatch(addFlashMessage("Successfully resolved", {autoDisappear: true, sticky: true}));
      },
      (error) => {
        dispatch(resolveFailed(error))
        dispatch(addModalMessage(error.message, {autoDisappear: false, type: "error"}));
      }
    );
  };
}

export function reportAndResolve(lessonId, issueType, action, reason, comment) {
  return (dispatch) => {
    dispatch(reportAndResolveSent());
    return LessonIssueApiUtils.reportAndResolve(lessonId, issueType, action, reason, comment).then(
      (data) => {
        dispatch(hideModal());
        dispatch(reportAndResolveSucceeded(data))
        dispatch(addFlashMessage("Successfully resolved", {autoDisappear: true, sticky: true}));
      },
      (error) => {
        dispatch(reportAndResolveFailed(error))
        dispatch(addModalMessage(error.message, {autoDisappear: false, type: "error"}));
      }
    );
  };
}


export function updateResolvedIssueSent() {
  return {
    type: UPDATE_RESOLVED_LESSON_ISSUE_SENT
  }
}

export function updateResolvedIssueSucceeded(data) {
  return {
    type: UPDATE_RESOLVED_LESSON_ISSUE_SUCCEEDED,
    payload: data
  }
}

export function updateResolvedIssueFailed(error) {
  return {
    type: UPDATE_RESOLVED_LESSON_ISSUE_FAILED,
    payload: {
      message: error.message
    }
  }
}

export function updateResolvedIssue(issueId, reason) {
  return (dispatch) => {
    dispatch(updateResolvedIssueSent());
    return LessonIssueApiUtils.updateResolvedIssue(issueId, reason).then(
      (data) => {
        dispatch(hideModal());
        dispatch(updateResolvedIssueSucceeded(data))
        dispatch(addFlashMessage("Successfully updated", {autoDisappear: true, sticky: true}));
      },
      (error) => {
        dispatch(updateResolvedIssueFailed(error))
        dispatch(addModalMessage(error.message, {autoDisappear: false, type: "error"}));
      }
    );
  };
}


