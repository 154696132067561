import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as UserActions from '../../../actions/user';
import * as TimeZoneActions from '../../../actions/timeZone';
import classnames from 'classnames';
import UserDetailsBase from '../../../components/db/users/details/base';
import UserDetailsSidenav from '../../../components/db/users/details/Sidenav';
import Immutable from 'immutable';


function mapStateToProps(state) {
  const { userData, timeZone } = state
  const { user, updating, avatarProcessing } = userData;
  const { timeZones } = timeZone;
  return {
    user,
    updating,
    avatarProcessing,
    timeZones,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    timeZoneActions: bindActionCreators(TimeZoneActions, dispatch)
  };
}

class UserDetailsContainer extends Component {

  componentDidMount() {
    const { match, userActions, user } = this.props;
    const userId = match.params.id;
    if (!user || user.id !== userId) {
      userActions.fetchOne(userId);
    }
  }

  render() {
    const {
      user,
      userActions,
      updating,
      avatarProcessing,
      timeZones,
      timeZoneActions
    } = this.props;;

    let userDetailsBase = <div></div>;
    if (user) {
      userDetailsBase = <UserDetailsBase
        user={user}
        timeZones={timeZones}
        updating={updating}
        avatarProcessing={avatarProcessing}
        fetchTimeZones={timeZoneActions.fetch}
        updateUser={userActions.update}
        uploadAvatar={userActions.uploadAvatar}
        deleteAvatar={userActions.deleteAvatar}/>;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 row-space-sm-4">
            <UserDetailsSidenav />
          </div>
          <div className="col-md-9">
            {userDetailsBase}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);
