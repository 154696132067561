import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CalendarTimeslotTable from './CalendarTimeslotTable';
import CalendarHeader from './CalendarHeader';

class Calendar extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { 
      startTime,
      shifts,
      loading,
      editing,
      saving,
      editType,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet,
    } = this.props;
    const headerProps = {
    }
    const timeslotTableProps = {
      startTime,
      shifts,
      loading,
      editing,
      saving,
      editType,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet,
    };
    return (
      <div>
        <CalendarHeader {...headerProps} />
        <div className="cal-week-timed">
          <table className="day">
            <tbody>
              <tr className="day-row">
                <td className="side"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
                <td className="day-col"></td>
              </tr>
            </tbody>
          </table>
          <CalendarTimeslotTable {...timeslotTableProps} />
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  shifts: PropTypes.array,
  startTime: PropTypes.object,
};



export default Calendar;
