import ShiftApiUtils from '../utils/api/shift';

export const FETCH_SHIFTS_SENT = 'FETCH_SHIFTS_SENT';
export const FETCH_SHIFTS_SUCCEEDED = 'FETCH_SHIFTS_SUCCEEDED';
export const FETCH_SHIFTS_FAILED = 'FETCH_SHIFTS_FAILED';

export function fetchSent(data) {
  return {
    type: FETCH_SHIFTS_SENT
  }
}

export function fetchSucceeded(data) {
  return {
    type: FETCH_SHIFTS_SUCCEEDED,
    payload: data
  }
}

export function fetchFailed(error) {
  return {
    type: FETCH_SHIFTS_FAILED
  }
}

export function fetch(startTime) {
  return (dispatch) => {
    dispatch(fetchSent());
    return ShiftApiUtils.fetch(startTime).then(
      (data) => dispatch(fetchSucceeded(data)),
      (error) => dispatch(fetchFailed(error))
    );
  };
}