import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FileValidator from '../../validators/FileValidator';
import classnames from 'classnames';

class FileInput extends React.Component {
  constructor() {
    super();
    this.uploadFile = this.uploadFile.bind(this);
    this.processFile = this.processFile.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file) {
      const {allowedExtensions, updateProcessingState} = this.props;
      const validator = new FileValidator(file, {allowedExtensions});
      validator.validate().then(
        () => {
          this.processFile(file);
        },
        (error) => {
          // ServerMessageActions.addMessage({
          //   type: 'uploadFileFailed',
          //   message: error });
        }
      );
    }
  }

  onClickUpload() {
    this.refs.fileInput.click();
  }

  onClickDelete() {
    this.props.deleteFile();
  }

  processFile(file) {
    const self = this;
    let data;
    const reader = new FileReader();
    reader.onloadend = (e) => {
      data = {
        file,
        fileUrl: e.target.result };
      self.uploadFile(data);
    };
    reader.readAsDataURL(file);
  }

  uploadFile(data) {
    const newFile = {
      name: data.file.name,
      size: data.file.size,
      altText: '',
      caption: '',
      file: data.file,
      url: data.fileUrl,
      type: data.file.type,
    };
    this.props.uploadFile(newFile);
  }

  render() {
    let deleteButton = null;
    const currentFile = this.props.currentFile
    let currentStatus = ""
    if(this.props.processing) {
      currentStatus = "Processing..."
    } else if (currentFile) {
      currentStatus = currentFile;
      deleteButton = (
        <div className="col-xs-6 col-sm-6 row-space-sm-2 sm-col-center">
          <button
            className="btn btn-danger btn-block sm-center"
            onClick={this.onClickDelete}
          >
            Delete
          </button>
        </div>);
    } else {
      currentStatus = "No file found"
    }
    let style = { display: 'none' };
    return (
      <div id="profile-photo-input-component">
        <div className="vertical-middle">
          <div className="row-space-3">{currentStatus}</div>
          <div className="col-xs-6 col-sm-6 row-space-top-sm-3 row-space-sm-2 sm-col-center">
            <div
              className="btn btn-success btn-block sm-center"
              onClick={this.onClickUpload}
            >
              Upload
              <input
                style={style}
                type="file"
                ref="fileInput"
                onChange={this.onChange}
              />
            </div>
          </div>
          {deleteButton}
        </div>
      </div>
    );
  }
}

FileInput.propTyeps = {
  isProcessing: PropTypes.bool,
  uploadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  currentFile: PropTypes.object,
  allowedExtensions: PropTypes.array.isRequired
}


export default FileInput;
