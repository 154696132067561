import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';

const MaterialApiUtils = {

  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.FetchMaterials + query);
  },

  fetchOne(id) {
    return xhr(ApiConstants.FetchMaterials + '/' + id);
  },

  update(id, params) {
    let updatedParams = {};
    const re = /[A-Z][a-z]+/g;
    for (const key of Object.keys(params)) {
      if (params[key] !== null) {
        let ar = key.match(re)
        var newKey = '';
        for (let i = 0; i < ar.length; i++) {
          if (i === 0) {
            newKey += ar[i].toLowerCase();
          } else {
            newKey += '_' + ar[i].toLowerCase();
          }
        }
        updatedParams[newKey] = params[key];
      }
    }
    return xhr(ApiConstants.FetchMaterials + '/' + id, {
      method: 'put',
      body: JSON.stringify({material: updatedParams})
    });
  },
  uploadFile(id, file) {
    return xhr(ApiConstants.FetchMaterials + '/' + id + '/file', {
      method: 'post',
      body: JSON.stringify({ file }),
    });
  },

  deleteFile(id) {
    return xhr(ApiConstants.FetchMaterials + '/' + id + '/file', {
      method: 'delete',
    });
  },
};

export default MaterialApiUtils;
