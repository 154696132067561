import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class TutorSidenav extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const tutorId = this.props.tutorId;
    return (
      <div className="sidenav layout-box o-has-rows row-space-3">
        <div className="sidenav-filter-wrapper">
          <ul className="sidenav-list">
            <li><h4>Tutor</h4></li>
            <li><Link to="/a/hr/tutors" className="sidenav-item">Go Back</Link></li>
            <li><Link to={"/a/hr/tutors/" + tutorId} className="sidenav-item">Edit Tutor</Link></li>
            <li><Link to={"/a/hr/tutors/" + tutorId + "/bank-info"} className="sidenav-item">Bank Info</Link></li>
            <li><Link to={"/a/hr/tutors/" + tutorId + "/shifts"} className="sidenav-item">Regular Shift</Link></li>
            <li><Link to={"/a/hr/tutors/" + tutorId + "/schedules"} className="sidenav-item">Schedules</Link></li>
            <li><Link to={"/a/hr/tutors/" + tutorId + "/data"} className="sidenav-item">Data</Link></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default TutorSidenav;
