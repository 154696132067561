import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as AuthActions from '../actions/auth';
import * as ModalActions from '../actions/modal';
import * as FlashActions from '../actions/flash';
import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer';
import Flash from '../components/shared/Flash';
import Modal from '../containers/modals/Modal';
import { RootRoutes } from '../routes';

function mapStateToProps(state) {
  const { auth, modal, secondaryModal, flash } = state
  return {
    auth,
    modal,
    flash
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch),
    flashActions: bindActionCreators(FlashActions, dispatch)
  };
}

class App extends Component {

  constructor() {
    super();
    this.onClickLogout = this.onClickLogout.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.renderFlash = this.renderFlash.bind(this);
  }

  getChildContext() {
    return {
      showModal: this.showModal,
      hideModal: this.hideModal,
    };
  }

  onClickLogout(e) {
    e.preventDefault();
    const {authActions} = this.props;
    authActions.logout();
  }

  showModal(content, modalProps = {}) {
    this.props.modalActions.show(
      content,
      modalProps
    );
  }

  hideModal() {
    this.props.modalActions.hide();
  }

  renderHeader() {
    const {auth, authActions} = this.props;
    const {user} = auth;
    if (auth.isLoggedIn) {
      return (
        <Header user={user} onClickLogout={this.onClickLogout} />
      );
    } else {
      return <div/>
    }
  }

  renderFooter() {
    const {auth, authActions} = this.props;
    const {user} = auth;
    if (auth.isLoggedIn) {
      return (
        <Footer/>
      );
    } else {
      return <div/>
    }
  }

  renderFlash() {
    const {flash, flashActions} = this.props;
    const {message, type, sticky, autoDisappear} = flash;
    const flashProps = {
      message,
      type,
      sticky,
      autoDisappear,
      clearFlashMessage: flashActions.clearMessage
    }
    return <Flash {...flashProps}/>;
  }

  renderModal() {

  }

  renderApp() {
    return (
      <div id="app-wrapper">
        <Modal />
        {this.renderFlash()}
        {this.renderHeader()}
         <RootRoutes />
        {this.renderFooter()}
      </div>
    );
  }

  renderEmptyScreen() {
    return (
      <div id="app-wrapper" />
    );
  }

  render() {
    return this.renderApp();
  }
}

App.childContextTypes = {
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
