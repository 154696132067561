import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CalendarTimeslotTable from './CalendarTimeslotTable';
import CalendarHeader from './CalendarHeader';
import classnames from 'classnames';

class Calendar extends Component {
  constructor(props, context) {
    super(props);
    this.renderAddRemoveButtons = this.renderAddRemoveButtons.bind(this);
  }

  renderAddRemoveButtons() {
    const {editType, onUpdateEditType} = this.props;
    let addClass = classnames("btn btn-small btn-no-radius", 
      {"btn-secondary": editType === "Remove"}, {"btn-green": editType === "Add"});
    let removeClass = classnames("btn btn-small btn-no-radius with-left-margin", 
      {"btn-secondary": editType === "Add"}, {"btn-green": editType === "Remove"});
    return (
      <div className="shift-edit-type-buttons">
        <button className={addClass} onClick={(e) => {onUpdateEditType("Add", e)}}>Add</button>
        <button className={removeClass} onClick={(e) => {onUpdateEditType("Remove", e)}}>Remove</button>
      </div>
    )
  }

  render() {
    const { 
      tutorId,
      startTime,
      shifts, 
      holidays,
      bookings,
      schedules,
      loading,
      saving,
      newAddTypeSchedules,
      newRemoveTypeSchedules, 
      editType,
      onUpdateNewSchedules,
      cancelCreatingNewSchedules,
      saveNewSchedules,
      goNextWeek,
      goPreWeek,
    } = this.props;
    const headerProps = {
      startTime,
      goNextWeek,
      goPreWeek,
    }
    const timeslotTableProps = {
      tutorId,
      startTime,
      shifts, 
      holidays,
      bookings,
      schedules,
      loading,
      saving,
      newAddTypeSchedules,
      newRemoveTypeSchedules, 
      editType,
      onUpdateNewSchedules,
      cancelCreatingNewSchedules,
      saveNewSchedules,
    };
    let panelBodyClass = classnames('panel-body', {loading: loading || saving})
    return (
      <div className="panel">
        <div className="calendar-content">
          <div className="panel-header">Weekly Schedules</div>
          <div className={panelBodyClass}>
            <div className="cal week-scroll">
              <div className="cal-container">
                {this.renderAddRemoveButtons()}
                <CalendarHeader {...headerProps} />
                <div className="cal-week-timed">
                  <table className="day">
                    <tbody>
                      <tr className="day-row">
                        <td className="side"></td>
                        <td className="day-col"></td>
                        <td className="day-col"></td>
                        <td className="day-col"></td>
                        <td className="day-col"></td>
                        <td className="day-col"></td>
                        <td className="day-col"></td>
                        <td className="day-col"></td>
                      </tr>
                    </tbody>
                  </table>
                  <CalendarTimeslotTable {...timeslotTableProps} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <button
            onClick={cancelCreatingNewSchedules}
            className="btn btn-secondary text-left"
            disabled={!!saving}
            type="reset"
          >
            Cancel
          </button>
          <button
            onClick={saveNewSchedules}
            className="btn btn-secondary pull-right"
            type="submit"
            disabled={!!saving}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  shifts: PropTypes.array,
  startTime: PropTypes.object,
};



export default Calendar;
