import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import TimeslotConstants from '../../../../constants/TimeslotConstants';
import _ from 'lodash';
import CalendarTimeslot from './CalendarTimeslot';


function isNotTouchEvent(e) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

function getTouchPosition(e) {
  return { x: e.touches[0].pageX, y: e.touches[0].pageY };
}

function getMousePosition(e) {
  return { x: e.pageX, y: e.pageY };
}

class CalendarTimeslotTable extends Component {
  constructor(props) {
    super(props);
    this.getSlotStatus = this.getSlotStatus.bind(this);
    this.getSlotTableRect = this.getSlotTableRect.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onMoveEnd = this.onMoveEnd.bind(this);
    this.getSlotPosition = this.getSlotPosition.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    this.state = {
      selectingSlots: false,
      tempChosenSlots: [],
    };
  }

  onStart = (e) => {
    const clickedSlotNum = parseFloat(e.target.getAttribute('data-slot-num'));
    const startPosition = getMousePosition(e);
    const tableRect = this.getSlotTableRect();
    const sideZero = this.refs.side0;
    const sideWidth = sideZero.getBoundingClientRect().width;
    const startSlotPosition = this.getSlotPosition(clickedSlotNum);
    this.setState({
      tableRect,
      startPosition,
      sideWidth,
      startSlotPosition,
      selectingSlots: true,
      tempChosenSlots: [clickedSlotNum],
    });
    e.stopPropagation();
    e.preventDefault();
  }

  onMouseDown = (e) => {
    this.onStart(e);
    document.addEventListener('mousemove', this.onMouseMove, false);
    document.addEventListener('mouseup', this.onMoveEnd, false);
  }

  onTouchStart = (e) => {
    this.onStart(e);
    document.addEventListener('touchmove', this.onMouseMove, false);
    document.addEventListener('touchend', this.onMoveEnd, false);
  }

  getSlotTableRect = () => {
    const table = this.refs.timeslotTable;
    const rect = table.getBoundingClientRect();
    return rect;
  }

  getSlotPosition = (slotNum) => {
    const currentSlotY = slotNum % 24;
    const currentSlotX = (slotNum - currentSlotY) / 24;
    return { x: currentSlotX, y: currentSlotY };
  }

  onMove = (currentPosition, e) => {
    const { startTime } = this.props;
    const currentScrollX = window.scrollX;
    const currentScrollY = window.scrollY;
    const {
      tableRect,
      startPosition,
      startSlotPosition,
      sideWidth,
    } = this.state;
    let currentSlotPosition = { x: null, y: null };
    const currentX = currentPosition.x;
    const currentY = currentPosition.y;
    const tableRight = tableRect.right + currentScrollX;
    const tableLeft = tableRect.left + sideWidth + currentScrollX;
    const tableBottom = tableRect.bottom + currentScrollY;
    const tableTop = tableRect.top + currentScrollY;
    if (currentX >= tableRight) {
      currentSlotPosition.x = 6;
    } else if (currentX <= tableLeft) {
      currentSlotPosition.x = 0;
    } else {
      const tempX = ((currentX - tableLeft) / ((tableRight - tableLeft) / 7));
      currentSlotPosition.x = Math.floor(tempX);
    }
    if (currentY >= tableBottom) {
      currentSlotPosition.y = 24;
    } else if (currentY <= tableTop) {
      currentSlotPosition.y = 0;
    } else {
      const tempY = ((currentY - tableTop) / ((tableBottom - tableTop) / 48));
      currentSlotPosition.y = Math.floor(tempY) / 2;
    }
    if (currentX < tableRight &&
      currentX > tableLeft &&
      currentY > tableTop &&
      currentY < tableBottom) {
      const slotNum = e.target.getAttribute('data-slot-num');
      if (slotNum === null) return; // This happenes when the cusor is on the line
      currentSlotPosition = this.getSlotPosition(slotNum);
    }
    const tempChosenSlots = [];
    if (startSlotPosition.x >= currentSlotPosition.x) {
      if (startSlotPosition.y >= currentSlotPosition.y) {
        for (let start = currentSlotPosition.x; start <= startSlotPosition.x; start++) {
          let ys = currentSlotPosition.y;
          while (ys <= startSlotPosition.y) {
            tempChosenSlots.push(start * 24 + ys);
            ys += 0.5;
          }
        }
      } else {
        for (let start = currentSlotPosition.x; start <= startSlotPosition.x; start++) {
          let ys = startSlotPosition.y;
          while (ys <= currentSlotPosition.y) {
            tempChosenSlots.push(start * 24 + ys);
            ys += 0.5;
          }
        }
      }
    } else if (startSlotPosition.y >= currentSlotPosition.y) {
      for (let start = startSlotPosition.x; start <= currentSlotPosition.x; start++) {
        let ys = currentSlotPosition.y;
        while (ys <= startSlotPosition.y) {
          tempChosenSlots.push(start * 24 + ys);
          ys += 0.5;
        }
      }
    } else {
      for (let start = startSlotPosition.x; start <= currentSlotPosition.x; start++) {
        let ys = startSlotPosition.y;
        while (ys <= currentSlotPosition.y) {
          tempChosenSlots.push(start * 24 + ys);
          ys += 0.5;
        }
      }
    }
    this.setState({
      tempChosenSlots: _.sortedUniq(tempChosenSlots),
    });
  }

  onTouchMove = (e) => {
    if (isNotTouchEvent(e)) {
      this.moveEnd();
      return;
    }
    const currentPosition = getTouchPosition(e);
    this.onMove(currentPosition, e);
  }

  onMouseMove = (e) => {
    const currentPosition = getMousePosition(e);
    this.onMove(currentPosition, e);
  }

  onMoveEnd = (e) => {
    const { tempChosenSlots } = this.state;
    const { onUpdateNewSchedules } = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.removeEvents();
    onUpdateNewSchedules(tempChosenSlots);
    this.setState({
      tempChosenSlots: [],
      selectingSlots: false,
    });
  }

  removeEvents = () => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMoveEnd);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onMoveEnd);
  }

  getSlotStatus = (timestamp, slotNum) => {
    const {
      holidays,
      shifts,
      bookings,
      schedules,
      editType,
      newAddTypeSchedules,
      newRemoveTypeSchedules,
    } = this.props;
    const { tempChosenSlots } = this.state;
    const shiftOnSlot = _.findLast(shifts,
      s => _.inRange(timestamp, s.duration_start, s.duration_end) &&
          _.inRange(slotNum, parseFloat(s.slot_start), parseFloat(s.slot_end)),
    );
    const bookingOnSlot = _.findLast(bookings,
      b => _.inRange(timestamp, b.start_at, b.end_at),
    );
    const holidayOnSlot = _.findLast(holidays,
      h => _.inRange(timestamp, h.start_at, h.end_at),
    );
    const schedule = _.findLast(schedules,
      s => _.inRange(timestamp, s.start_at, s.end_at),
    );
    const selectingSchedule = _.findLast(tempChosenSlots,
      n => slotNum === n,
    );
    const tempAddSchedule = _.findLast(newAddTypeSchedules,
      n => slotNum === n,
    );
    const tempRemoveSchedule = _.findLast(newRemoveTypeSchedules,
      n => slotNum === n,
    );
    if (bookingOnSlot) {
      return 'booked';
    }
    if (editType === 'Add' && selectingSchedule >= 0) {
      return 'tempAdded';
    }
    if (editType === 'Remove' && selectingSchedule >= 0) {
      return 'tempRemoved';
    }
    if (tempAddSchedule >= 0) {
      return 'added';
    }
    if (tempRemoveSchedule >= 0) {
      return 'removed';
    }

    if (schedule && schedule.type === 'add') {
      return 'added';
    }
    if (schedule && schedule.type === 'remove') {
      return 'removed';
    }
    if (holidayOnSlot) {
      return null;
    }
    if (shiftOnSlot) {
      return 'on';
    }
  }

  render() {
    const { shifts, loadingShifts, startTime } = this.props;
    const timeslotArray = TimeslotConstants.slotArray.slice(0, 48);
    let timeslots;
    const slotCountToShow = 0;
    const arrayForCheckNeededRows = new Array(48);
    if (!loadingShifts) {
      _.map(timeslotArray,
        (time, key) => {
          _.map([0, 1, 2, 3, 4, 5, 6],
            (n) => {
              const copyOfStartTime = _.cloneDeep(startTime);
              const timestampOfSlot = copyOfStartTime
                .add(n, 'days')
                .add(key * 30, 'minutes')
                .format('x');
              const slotNum = n * 24 + key * 0.5;
              const slotStatus = this.getSlotStatus(timestampOfSlot, slotNum);
              const slotProps = {
                key: slotNum,
                ref: `slot${slotNum.toString()}`,
                slotNum,
                timestampOfSlot,
                slotStatus,
                onMouseDown: this.onMouseDown,
              };
              arrayForCheckNeededRows[key] = arrayForCheckNeededRows[key] || [];
              arrayForCheckNeededRows[key].push(slotProps);
            });
        });
      const start = 0;
      const end = 47;
      const neededRowsArray = _.range(start, end + 1);
      timeslots = neededRowsArray.map((val) => {
        const upperOrLower = val % 2 === 0 ? 'upper' : 'lower';
        const ts = [0, 1, 2, 3, 4, 5, 6].map((n) => {
          const slotProps = arrayForCheckNeededRows[val][n];
          return <CalendarTimeslot {...slotProps} />;
        });
        return (
          <tr key={`calendar-timeslot-${val}`}>
            <td className="side" ref={`side${val}`}>{val % 2 === 0 ? timeslotArray[val] : ''}</td>
            <td className={upperOrLower}>
              {ts}
            </td>
          </tr>
        );
      });
    } else {
      timeslots = (
        <tr className="row-space-top-4 row-space-4">
          <td colSpan={8} height="70" className="text-center h5">Loading..</td>
        </tr>
      );
    }

    const timeslotDivStyle = { cellpadding: 0 };
    return (
      <table className="timeslot-table" style={timeslotDivStyle} ref="timeslotTable">
        <tbody>
          {timeslots}
        </tbody>
      </table>
    );
  }
}


CalendarTimeslotTable.propTypes = {
  startTime: PropTypes.object,
  shifts: PropTypes.array,
};

export default CalendarTimeslotTable;
