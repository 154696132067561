import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as AuthActions from '../actions/auth';
import classnames from 'classnames';
import Login from '../components/auth/Login';

function mapStateToProps(state) {
  const { auth } = state;
  return {
    auth,
  };
}

function mapDispatchToProps(diapatch) {
  return { authActions: bindActionCreators(AuthActions, diapatch) };
}

class LoginContainer extends Component {

  constructor(props) {
    super(props);
    this.onClickLogin = this.onClickLogin.bind(this);
  }

  onClickLogin(email, password) {
    const {
      authActions
    } = this.props
    authActions.login(email, password);
  }

  render() {
    const {
      user,
      isLoggedIn,
    } = this.props.auth;
    if (isLoggedIn && user) {
      return <Redirect to="/a/db" />;
    }
    return (
      <div>
        <Login onClickLogin={this.onClickLogin} />
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
