import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class LessonDetailsSummary extends Component {

  constructor(props) {
    super(props);
  }
  
  render() {
    const review = this.props.review;
    let totalSatisfaction = "N/A";
    let audioQuality = "N/A";
    let comment = "N/A";
    if (review !== null) {
      totalSatisfaction = review.total_satisfaction;
      audioQuality = review.audio_quality;
      comment = review.comment_on_tutor;
    }
    return (
      <div className="panel row-space-4">
        <div className="panel-header">Lesson Review</div>
        <div className="panel-body panel-body-no-border">
          <div className="form-body">
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Total Satisfaction</b>
              </div>
              <div className="col-sm-10">
                {totalSatisfaction}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Audio Quality</b>
              </div>
              <div className="col-sm-10">
                {audioQuality}
              </div>
            </div>
            <div className="row row-condensed row-space-2">
              <div className="text-right col-sm-2 sm-text-left">
                <b>Comment</b>
              </div>
              <div className="col-sm-10">
                {comment}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LessonDetailsSummary;