import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from '../../form/Select.js';

class HolidayFilter extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.getStatusOptions = this.getStatusOptions.bind(this);
    this.getSortOptions = this.getSortOptions.bind(this);
    this.getPageOptions = this.getPageOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getOptions(keyBase, baseOpts) {
    let options = [];
    baseOpts.map((val, index) => {
      options.push({ key: keyBase + index, name: val, value: index });
    });
    return options;
  }

  getPageOptions() {
    const count = this.props.count;
    const raw_pages = count / 100;
    if (count === 0 || raw_pages <= 1) {
      return [{ key: 'user-page-1', name: 'page1', value: 1 }]
    }
    const floored = Math.floor(raw_pages);
    const totalPages = raw_pages === floored > 0 ? floored + 1 : raw_pages;
    let options = [];
    for (let i = 1; i < totalPages; i++) {
      options.push({ key: 'lessons-page-' + i, name: 'page' + i, value: i });
    }
    return options;
  }

  getStatusOptions() {
    const base = ['Select Status', 'Active', 'Inactive'];
    return this.getOptions('holiday-status-', base);
  }

  getSortOptions() {
    const base = ['Sort by created at']
    return this.getOptions('holiday-sort-', base);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    const {
      changeAdded,
      handleUpdateSortOption,
      chosenSortOption,
      handleUpdateStatus,
      chosenStatus,
      handleUpdatePage,
      page } = this.props;

    const sortProps = {
      options: this.getSortOptions(),
      updateHandler: handleUpdateSortOption,
      value: chosenSortOption,
    };
    const statusFilterProps = {
      options: this.getStatusOptions(),
      updateHandler: handleUpdateStatus,
      value: chosenStatus,
    };
    const pageProps = {
      options: this.getPageOptions(),
      updateHandler: handleUpdatePage,
      value: page
    }
    return (
      <div className="data-filter">
        <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
          <li className="row-space-2">
            <Select {...sortProps} />
          </li>
          <li className="row-space-2">
            <Select {...statusFilterProps} />
          </li>
          <li className="row-space-2">
            <Select {...pageProps} />
          </li>
          <li className="row-space-2">
            <button onClick={this.handleSubmit} className="btn btn-success" disabled={!changeAdded}>Search</button>
          </li>
        </ul>
      </div>
    );
  }
}

HolidayFilter.propTypes = {
  handleSubmit: PropTypes.func,
  handleUpdateSortOption: PropTypes.func,
  chosenSortOption: PropTypes.number,
  handleUpdateStatus: PropTypes.func,
  chosenStatus: PropTypes.number,
  page: PropTypes.number,
  updatePage: PropTypes.func,
  count: PropTypes.number,
};

export default HolidayFilter;
