import Collection from '../utils/Collection.js';

import {
  FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED,
} from '../actions/tutor';

const initialState = {
  tutorBookings: [],
}

export default function bookingReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_DATA_FOR_SCHEDULES_CAL_SUCCEEDED:
      return {
        ...state, 
        tutorBookings: Collection.reset(state.tutorBookings, payload.bookings)
      };
    default:
      return state;
  }
}