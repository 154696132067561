// @flow

import AuthApiUtils from '../utils/api/auth';
import {addMessage as addFlashMessage} from './flash';

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const FETCH_CURRENT_USER_SUCCEEDED = 'FETCH_CURRENT_USER_SUCCEEDED';
export const FETCH_CURRENT_USER_FAILED = 'FETCH_CURRENT_USER_FAILED';

export function loginSucceeded(data: {
  authToken: string,
  profile: string,
  success: boolean,
  userId: number,
  user: Object // Todo: Should define precisely
}) {
  localStorage.setItem('authToken', data.authToken);
  localStorage.setItem('isLoggedIn', true);
  localStorage.setItem('user', JSON.stringify(data.user));
  return {
    type: LOGIN_SUCCEEDED,
    payload: data
  }
}

export function loginFailed(error: Object) {
  return { type: LOGIN_FAILED }
}

export function logout() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('user');
  return {
    type: LOGOUT,
    payload: {}
  }
}

export function fetchCurrentUserSucceeded(data) {
  return {
    type: FETCH_CURRENT_USER_SUCCEEDED,
    payload: data
  }
}

export function fetchCurrentUserFailed(error) {
  return {
    type: FETCH_CURRENT_USER_FAILED
  }
}

export function login(email, password) {
  return (dispatch) => {
    return AuthApiUtils.loginWithEmail(email, password).then(
      (data) => dispatch(loginSucceeded(data)),
      (error) => {
        dispatch(addFlashMessage(error.message, {autoDisappear: true, type: "error" }));
        dispatch(loginFailed(error));
      }
    );
  };
}

export function fetchCurrentUser() {
  return (dispatch) => {
    return AuthApiUtils.me().then(
      (data) => dispatch(fetchCurrentUserSucceeded(data)),
      (error) => dispatch(fetchCurrentUserFailed(error))
    );
  };
}
