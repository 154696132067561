import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const ShiftApiUtils = {
  fetch(startTime) {
    let query = "?startTime=" + startTime;
    return xhr(ApiConstants.Base + 'shifts' + query);
  }
};

export default ShiftApiUtils;