import Collection from '../../utils/Collection.js';

import {
  FETCH_DATA_FOR_DASHBOARD_SUCCEEDED,
  FETCH_DATA_FOR_DASHBOARD_FAILED,
  FETCH_DATA_FOR_DASHBOARD_SENT,
} from '../../actions/data';

const initialState = {
  chosenDate: null,
  loading: false,
  loaded: false,
  numOfTrialStudentsGrowh: 0.0,
  numOfTrialStudents: 0,
  numOfTrialLessonsGrowth: 0.0,
  numOfTrialLessons: 0,
  subscribeRateAfterOneMonth: 0,
  subscribeRateGrowth: 0.0,
  lessonCompletionRate: 0.0, // Includes all the classes without tutor-related issue.
  tutorRelatedIssueRate: 0.0,
  tutorRelatedNetworkingIssueRate: 0.0,
  tutorRelatedBlackoutRate: 0.0,
  tutorRelatedComputerProblemRate: 0.0,
  tutorRelatedSicknessRate: 0.0,
  tutorRalatedFamilySicknessRate: 0.0,
  // other tutor-related issue
  tutorRelatedOtherIssuesRate: 0.0,
  // rateTookFirstAfterOneMonth: 0.0,
  // rateTookSecondAfterFirst: 0.0,
  // rateSubscribeAfterSecond: 0.0,
};

export default function dataForDashboardReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_DATA_FOR_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        loading: false,
        loaded: true,
        numOfTrialStudentsGrowh: payload.numOfTrialStudentsGrowh,
        numOfTrialStudents: payload.numOfTrialStudents,
        numOfTrialLessonsGrowth: payload.numOfTrialLessonsGrowth,
        numOfTrialLessons: payload.numOfTrialLessons,
        subscribeRateAfterOneMonth: payload.subscribeRateAfterOneMonth,
        subscribeRateGrowth: payload.subscribeRateGrowth,
        lessonCompletionRate: payload.lessonCompletionRate,
        tutorRelatedIssueRate: payload.tutorRelatedIssueRate,
        tutorRelatedNetworkingIssueRate: payload.tutorRelatedNetworkingIssueRate,
        tutorRelatedBlackoutRate: payload.tutorRelatedBlackoutRate,
        tutorRelatedComputerProblemRate: payload.tutorRelatedComputerProblemRate,
        tutorRelatedSicknessRate: payload.tutorRelatedSicknessRate,
        tutorRalatedFamilySicknessRate: payload.tutorRalatedFamilySicknessRate,
        tutorRelatedOtherIssuesRate: payload.tutorRelatedOtherIssuesRate,
        studentRelatedIssueRate: payload.studentRelatedIssueRate,
        // rateTookFirstAfterOneMonth,
        // rateTookSecondAfterFirst,
        // rateSubscribeAfterSecond,
      };
    case FETCH_DATA_FOR_DASHBOARD_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DATA_FOR_DASHBOARD_SENT:
      return {
        ...state,
        chosenDate: payload.chosenDate,
        loading: true,
      };
    default:
      return state;
  }
}
