import Collection from '../utils/Collection.js';

import {
  FETCH_USERS_SUCCEEDED,
  FETCH_USERS_FAILED,
  UPDATE_USER_SUCCEEDED,
  UPDATE_USER_FAILED,
  UPLOAD_AVATAR_SUCCEEDED,
  UPLOAD_AVATAR_FAILED,
  DELETE_AVATAR_SUCCEEDED,
  DELETE_AVATAR_FAILED
} from '../actions/user';


const initialState = {
  users: [],
  count: 0,
  lastSearchOpts: {
    locale: 0,
    nameOrEmail: '',
    page: 1,
    plan: 0,
    planStatus: 0,
    profile: 0,
    sortOption: 0
  }
}

export default function userReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_USERS_SUCCEEDED:
      return {
        ...state, 
        users: Collection.reset(state.users, payload.users),
        count: payload.count,
        lastSearchOpts: payload.opts
      };
    case UPDATE_USER_SUCCEEDED:
    case UPLOAD_AVATAR_SUCCEEDED:
    case DELETE_AVATAR_SUCCEEDED:
      return {
        ...state, 
        users: Collection.update(state.users, payload.user.id, payload.user),
      };
    default:
      return state;
  }
}