import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone'

class ShiftSetNav extends Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
  }

  renderButtons(shiftSet) {
    const { chosenShiftSetId, switchShiftSet } = this.props;
    const startTime = moment(shiftSet.duration_start).tz("Asia/Manila").format('YYYY-MM-DD')
    const endTime = moment(shiftSet.duration_end).tz("Asia/Manila").format('YYYY-MM-DD')
    if (chosenShiftSetId === shiftSet.id) {
      return (
        <div className="col-sm-6 col-md-4 col-lg-3" key={"shift-set-nav-" + shiftSet.id}>
          <button 
            className="btn btn-small btn-small-radius btn-primary" 
            onClick={(e) => {
             switchShiftSet(shiftSet.id, e);
            }}>
            {startTime} to {endTime}
          </button>
        </div>
      );
    } else {
      return (
        <div className="col-sm-6 col-md-4 col-lg-3" key={"shift-set-nav-" + shiftSet.id}>
          <button 
            className="btn btn-small btn-small-radius btn-secondary"
            onClick={(e) => {
              switchShiftSet(shiftSet.id, e);
            }}>
            {startTime} to {endTime}
          </button>
        </div>
      );
    }
  }

  render() {
    const {shiftSets} = this.props;
    let buttons;
    if (shiftSets) {
      buttons = shiftSets.map((ss) => {
        return this.renderButtons(ss);
      })
    }
    return (
      <div className="row">
        {buttons}
        <div
          className="btn btn-small btn-small-radius btn-light-gray"
          onClick={this.props.openEditShiftSetModal}>Set end date</div>
        <div
          className="btn btn-small btn-small-radius btn-light-gray"
          onClick={this.props.openAddShiftSetModal}>Add shfit set</div>
      </div>
    );
  }
}

export default ShiftSetNav;

ShiftSetNav.propTypes = {
  shiftSets: PropTypes.array,
  editing: PropTypes.bool
}