import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

// const msOfOneDay = 86400000;
// const msOfHalfAnHour = 1800000;
// const TWENTY_FIVE_MINUTES = 1500000;
const FIFTY_MINUTES = 3000000;

class CalendarTimeslot extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const self = this;
    const { timestamp, slotNum, slotAvailability, ratio } = this.props;
    let slotClass = classnames("timeslot", 
        {red: ratio < 0.2}, 
        {yellow: ratio < 0.5 && ratio >= 0.2},
        {blue: ratio >= 0.5})
    return (
      <div>
        <div className={slotClass}>
          <div className="text-center">
            {slotAvailability}
          </div>
        </div>
      </div>
    );
  }
}


CalendarTimeslot.propTypes = {
  timestamp: PropTypes.number,
  status: PropTypes.object
};

export default CalendarTimeslot;
