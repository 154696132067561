import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table';

class LessonActionCell extends Component {

  constructor() {
    super();
    this.openResolveModal = this.openResolveModal.bind(this);
    this.openRevokeModal = this.openRevokeModal.bind(this);
    this.openReportAndResolveModal = this.openReportAndResolveModal.bind(this);
    this.openCancelReservationModal = this.openCancelReservationModal.bind(this);
  }

  openCancelReservationModal() {
    const {rowIndex, data, ...props} = this.props;
    this.context.showModal('cancelBooking', {
      lesson: data[rowIndex]
    });
  }

  openResolveModal() {
    const {rowIndex, data, ...props} = this.props;
    this.context.showModal('resolveIssue', {
      issue: data[rowIndex].lesson_issue
    });
  }

  openRevokeModal() {
    const {rowIndex, data, ...props} = this.props;
    if (data[rowIndex].status_id === 1) {
      this.context.showModal('revokeReportedIssue', {
        issue: data[rowIndex].lesson_issue
      });
    } else {
      this.context.showModal('revokeResolvedIssue', {
        issue: data[rowIndex].lesson_issue
      });
    }
  }

  openReportAndResolveModal() {
    const {rowIndex, data, ...props} = this.props;
    this.context.showModal('reportAndResolveIssue', {
      lesson: data[rowIndex]
    });
  }

  render() {
    const {tableName, rowIndex, field, data, ...props} = this.props;
    const lesson = data[rowIndex]
    const issue = lesson.lesson_issue;
    let links = <div></div>
    if (issue === null) {
      if (lesson.status_id === 6 || lesson.status_id === 7) {
        links = <a onClick={this.openReportAndResolveModal}>Report and resolve</a>
      } else if (lesson.status_id === 1) {
        links = <a onClick={this.openCancelReservationModal}>Cancel Reservation</a>
      }
    } else {
      if (issue.status_id === 1) {
        links = (
          <a onClick={this.openResolveModal}>Resolve</a>
        );
      }
    }
    return (
      <Cell {...props}>
        {links}
      </Cell>
    );
  }
}

LessonActionCell.contextTypes = {
  showModal: PropTypes.func
}

export default LessonActionCell;