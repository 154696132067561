let APIRoot;
const hostname = window.location.hostname
let spHostname = hostname.split('.').reverse()
let tld = spHostname[1] + '.' +spHostname[0]

if (typeof document !== "undefined") { 
  if (tld === 'lingualbox.jp') {
    APIRoot = 'https://api-stage.lingualbox.com/admin/'
  } else if (tld === 'lingualbox.com') {
    APIRoot = 'https://api.lingualbox.com/admin/'
  } else {
    APIRoot = "http://localhost:3000/admin/"
  }
}


const ApiConstants = {
  Base: APIRoot,
  LoginWithEmail: APIRoot + 'users/authenticate',
  Me: APIRoot + 'me/',
  FetchSupportTasks: APIRoot + 'support-tasks',
  FetchUsers: APIRoot + 'users',
  FetchLessons: APIRoot + 'lessons',
  FetchLessonIssues: APIRoot + 'lesson-issues',
  FetchPayments: APIRoot + 'payments',
  FetchCourses: APIRoot + 'courses',
  FetchMaterials: APIRoot + 'materials',
  FetchHolidays: APIRoot + 'holidays',
  FetchTimeZones: APIRoot + 'time-zones',
}

export default ApiConstants;