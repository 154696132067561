import React from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class DataDashboardMain extends React.Component {

  constructor() {
    super();
    this.state={
      focused: false
    }
  }

  render() {
    const {
      loading,
      loaded,
      chosenDate,
      numOfTrialStudentsGrowh,
      numOfTrialStudents,
      numOfTrialLessonsGrowth,
      numOfTrialLessons,
      subscribeRateAfterOneMonth,
      subscribeRateGrowth,
      lessonCompletionRate,
      tutorRelatedIssueRate,
      tutorRelatedNetworkingIssueRate,
      tutorRelatedBlackoutRate,
      tutorRelatedComputerProblemRate,
      tutorRelatedSicknessRate,
      tutorRalatedFamilySicknessRate,
      tutorRelatedOtherIssuesRate,
      studentRelatedIssueRate,
      handleUpdateChosenDate,
      handleSubmit
    } = this.props;
    if (!loaded) {
      return <div />;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="data-filter col-md-12">
            <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
              <li className="row-space-2">
                <SingleDatePicker
                  id="duration_end_of_set_input"
                  date={chosenDate}
                  numberOfMonths={1}
                  focused={this.state.focused}
                  onDateChange={(date) => { handleUpdateChosenDate(date)}}
                  onFocusChange={({ focused }) => { this.setState({ focused }); }}
                  isOutsideRange={() => false}
                  withPortal={true}
                />
              </li>
              <li className="row-space-2">
                <button onClick={handleSubmit} className="btn btn-success" disabled={loading}>Update</button>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Number of trial students
              </div>
              <div className="panel-body">
                <div className="h1">
                  {numOfTrialStudents}: {(numOfTrialStudentsGrowh * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Number of trial lessons
              </div>
              <div className="panel-body">
                <div className="h1">
                  {numOfTrialLessons}: {(numOfTrialLessonsGrowth * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Subscribe Rate after one month
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(subscribeRateAfterOneMonth * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Lesson Completion rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(lessonCompletionRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Tutor-related issue rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRelatedIssueRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Internet problem rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRelatedNetworkingIssueRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Blackout rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRelatedBlackoutRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Computer problem rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRelatedComputerProblemRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Sickness rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRelatedSicknessRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Family sickness rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRalatedFamilySicknessRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 row-space-4">
            <div className="panel">
              <div className="panel-header">
                Other turor-related issue rate
              </div>
              <div className="panel-body">
                <div className="h1">
                  {(tutorRelatedOtherIssuesRate * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataDashboardMain;
