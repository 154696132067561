import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import TimeslotConstants from '../../../../../constants/TimeslotConstants';
import _ from 'lodash';
import CalendarTimeslot from './CalendarTimeslot';

const msOfOneDay = 86400000;
const msOfHalfAnHour = 1800000;
const TWENTY_FIVE_MINUTES = 1500000;
const TWENTY_MINUTES = 1200000;
const FIFTY_MINUTES = 3000000;

function isNotTouchEvent(e) {
  return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

function getTouchPosition(e) {
  return {x: e.touches[0].pageX, y: e.touches[0].pageY};
}

function getMousePosition(e) {
  return {x: e.pageX, y: e.pageY};
}

class CalendarTimeslotTable extends Component {
  constructor(props) {
    super(props);
    this.getSlotAvailability = this.getSlotAvailability.bind(this);
    this.getSlotTableRect = this.getSlotTableRect.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onMoveEnd = this.onMoveEnd.bind(this);
    //this.onClickSlot = this.onClickSlot.bind(this);
    this.getSlotPosition = this.getSlotPosition.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    this.state = {
      selectingSlots: false
    }
  }

  componentDidMount() {
  }

  getSlotAvailability(slotNum) {
    const {shifts, editing} = this.props;
    if (!editing) {
      const shiftsOfSlot = _.filter(shifts,
        (s) => { 
          return _.inRange(slotNum, s.start_slot, s.end_slot)
        }
      );
      return shiftsOfSlot.length === 1;
    } else {
      return shifts.includes(slotNum);
    }
  }

  onStart(e) {
    const clickedSlotNum = parseFloat(e.target.getAttribute('data-slot-num'));
    const startPosition = getMousePosition(e)
    const tableRect = this.getSlotTableRect()
    const sideZero = this.refs.side0;
    const sideWidth = sideZero.getBoundingClientRect().width;
    const startSlotPosition = this.getSlotPosition(clickedSlotNum)
    this.setState({
      tableRect, 
      startPosition,
      sideWidth,
      startSlotPosition,
      selectingSlots: true,
      tempChosenSlots: [clickedSlotNum],
    });
    e.stopPropagation();
    e.preventDefault();
  }

  onMouseDown(e) {
    this.onStart(e);
    document.addEventListener('mousemove', this.onMouseMove, false);
    document.addEventListener('mouseup', this.onMoveEnd, false);
  }

  onTouchStart(e) {
    this.onStart(e);
    document.addEventListener('touchmove', this.onMouseMove, false);
    document.addEventListener('touchend', this.onMoveEnd, false);
  }

  getSlotTableRect() {
    const table = this.refs.timeslotTable;
    const rect = table.getBoundingClientRect();
    return rect
  }

  //onClickSlot(slotNum, e) {
  //  if (e) e.preventDefault();
  //  const { 
  //    editing, 
  //    hasShift, 
  //    editType, 
  //    onUpdateNewShiftSetShifts
  //  } = this.props;
  //  const {selectingSlots} = this.state;
  //  if (!selectingSlots && editing) {
  //    if ((editType === "Add" && !hasShift) || (editType === "remove" && hasShift)) {
  //      let slotNums = [];
  //      slotNums.push(slotNum);
  //      console.log(slotNums);
  //      console.log(slotNums === [parseFloat(slotNum)])
  //      onUpdateNewShiftSetShifts(slotNums);
  //    }
  //  }
  //}

  getSlotPosition(slotNum) {
    let currentSlotY = slotNum % 24
    let currentSlotX = (slotNum - currentSlotY) / 24
    return {x: currentSlotX, y: currentSlotY}
  }

  onMove(currentPosition, e) {
    const currentScrollX = window.scrollX 
    const currentScrollY = window.scrollY
    const {
      tableRect,
      startPosition,
      startSlotPosition,
      sideWidth,
    } = this.state;
    let { tempChosenSlots } = this.state;
    let currentSlotPosition = {x: null, y: null};
    const currentX = currentPosition.x
    const currentY = currentPosition.y
    const tableRight = tableRect.right + currentScrollX
    const tableLeft = tableRect.left + sideWidth + currentScrollX
    const tableBottom = tableRect.bottom + currentScrollY
    const tableTop = tableRect.top + currentScrollY
    if (currentX >= tableRight) {
      currentSlotPosition.x = 6
    } else if (currentX <= tableLeft) {
      currentSlotPosition.x = 0
    } else {
      let tempX = ((currentX - tableLeft) / ((tableRight - tableLeft) / 7))
      currentSlotPosition.x = Math.floor(tempX)
    }
    if (currentY >= tableBottom) {
      currentSlotPosition.y = 24
    } else if (currentY <= tableTop) {
      currentSlotPosition.y = 0
    } else {
      let tempY = ((currentY - tableTop) / ((tableBottom - tableTop) / 48))
      currentSlotPosition.y = Math.floor(tempY) / 2
    }
    if (currentX < tableRight && 
      currentX > tableLeft && 
      currentY > tableTop && 
      currentY < tableBottom) {
      const slotNum = e.target.getAttribute('data-slot-num');
      if (slotNum === null) return // This happenes when the cusor is on the line
      currentSlotPosition = this.getSlotPosition(slotNum);
    }
    tempChosenSlots = [];
    if (startSlotPosition.x >= currentSlotPosition.x) {
      if (startSlotPosition.y >= currentSlotPosition.y) {
        for (let start = currentSlotPosition.x; start <= startSlotPosition.x; start++) {
          let ys = currentSlotPosition.y
          while (ys <= startSlotPosition.y) {
            tempChosenSlots.push(start * 24 + ys);
            ys += 0.5;
          }
        }
      } else {
        for (let start = currentSlotPosition.x; start <= startSlotPosition.x; start++) {
          let ys = startSlotPosition.y
          while (ys <= currentSlotPosition.y) {
            tempChosenSlots.push(start * 24 + ys);
            ys += 0.5;
          }
        }
      }
    } else {
      if (startSlotPosition.y >= currentSlotPosition.y) {
        for (let start = startSlotPosition.x; start <= currentSlotPosition.x; start++) {
          let ys = currentSlotPosition.y
          while (ys <= startSlotPosition.y) {
            tempChosenSlots.push(start * 24 + ys);
            ys += 0.5;
          }
        }
      } else {
        for (let start = startSlotPosition.x; start <= currentSlotPosition.x; start++) {
          let ys = startSlotPosition.y
          while (ys <= currentSlotPosition.y) {
            tempChosenSlots.push(start * 24 + ys);
            ys += 0.5;
          }
        }
      }
    }
    this.setState({
      tempChosenSlots
    });
  }

  onTouchMove(e) {
    if (isNotTouchEvent(e)) {
      this.moveEnd();
      return;
    }
    const currentPosition = getTouchPosition(e);
    this.onMove(currentPosition, e);
  }

  onMouseMove(e) {
    const currentPosition = getMousePosition(e);
    this.onMove(currentPosition, e);
  }

  onMoveEnd(e) {
    const {tempChosenSlots} = this.state;
    const {onUpdateNewShiftSetShifts} = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.removeEvents();
    onUpdateNewShiftSetShifts(tempChosenSlots)
    this.setState({
      tempChosenSlots: [],
      selectingSlots: false
    });
  }

  removeEvents() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMoveEnd);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onMoveEnd);
  }
 
  render() {
    const { 
      startTime,
      shifts,
      loading,
      editing,
      saving,
      editType,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet
    } = this.props;
    const {selectingSlots, tempChosenSlots} = this.state;
    const timeslotArray = TimeslotConstants.slotArray.slice(0, 48);
    let timeslots;
    let slotCountToShow = 0;
    let arrayForCheckNeededRows = new Array(48)
    if (!loading) {
      _.map(timeslotArray,
        (time, key) => {
          _.map([0, 1, 2, 3, 4, 5, 6],
            (n) => {
              const slotNum = n * 24 + key * 0.5;
              const hasShift = this.getSlotAvailability(slotNum)
              let tempSelect = false
              if (selectingSlots) {
                if (tempChosenSlots.includes(slotNum)) tempSelect = true 
              }
              const slotProps = {
                key: slotNum,
                ref: `slot${slotNum.toString()}`,
                slotNum,
                hasShift,
                tempSelect,
                editType,
                editing,
                saving,
                onUpdateNewShiftSetShifts,
                selecting: selectingSlots,
                onMouseDown: this.onMouseDown,
                onClickSlot: this.onClickSlot
              };
              arrayForCheckNeededRows[key] = arrayForCheckNeededRows[key] || [];
              arrayForCheckNeededRows[key].push(slotProps);
          });
      });
      let start = 0;
      let end = 47;
      const neededRowsArray = _.range(start, end + 1)
      timeslots = neededRowsArray.map(val => {
        let upperOrLower = val % 2 === 0 ? 'upper' : 'lower';
        let ts = [0, 1, 2, 3, 4, 5, 6].map(n => {
          const slotProps = arrayForCheckNeededRows[val][n];
          return <CalendarTimeslot {...slotProps} />;
        });
        return (
          <tr key={`calendar-timeslot-${val}`}>
            <td className="side" ref={"side" + val}>{val % 2 === 0 ? timeslotArray[val] : ''}</td>
            <td className={upperOrLower}>
              {ts}
            </td>
          </tr>
        );
      });
    } else {
      timeslots = (
        <tr className="row-space-top-4 row-space-4">
          <td colSpan={8} height="70" className="text-center h5">Loading..</td>
        </tr>
      );
    }
    
    let timeslotDivStyle = { cellpadding: 0 };
    return (
      <table className="timeslot-table" style={timeslotDivStyle} ref="timeslotTable">
        <tbody>
          {timeslots}
        </tbody>
      </table>
    );
  }
}


CalendarTimeslotTable.propTypes = {
  startTime: PropTypes.object,
  shifts: PropTypes.array
};

export default CalendarTimeslotTable;
