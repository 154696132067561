import Collection from '../utils/Collection.js';

import {
  FETCH_UNITS_SUCCEEDED,
  FETCH_UNITS_FAILED,
} from '../actions/unit';


const initialState = {
  units: []
}

export default function unitReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_UNITS_SUCCEEDED:
      return {
        ...state, 
        units: Collection.reset(state.units, payload.units)
      };
    default:
      return state;
  }
}