import Collection from '../utils/Collection.js';

import {
  FETCH_USER_SUCCEEDED,
  FETCH_USER_FAILED,
  UPDATE_USER_SENT,
  UPDATE_USER_SUCCEEDED,
  UPDATE_USER_FAILED,
  UPLOAD_AVATAR_SENT,
  UPLOAD_AVATAR_SUCCEEDED,
  UPLOAD_AVATAR_FAILED,
  DELETE_AVATAR_SENT,
  DELETE_AVATAR_SUCCEEDED,
  DELETE_AVATAR_FAILED
} from '../actions/user';


const initialState = {
  updating: false,
  avatarProcessing: false,
  user: null
}

export default function userDataReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_USER_SUCCEEDED:
    case UPDATE_USER_SUCCEEDED:
    case UPLOAD_AVATAR_SUCCEEDED:
    case DELETE_AVATAR_SUCCEEDED:
      return {
        ...state, 
        user: payload.user,
        updating: false,
        avatarProcessing: false
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        updating: false
      }
    case UPLOAD_AVATAR_FAILED:
    case DELETE_AVATAR_FAILED:
      return {
        ...state,
        avatarProcessing: false
      }
    case UPDATE_USER_SENT:
      return {
        ...state,
        updating: true
      }
    case UPLOAD_AVATAR_SENT:
    case DELETE_AVATAR_SENT:
      return {
        ...state,
        avatarProcessing: true
      }
    default:
      return state;
  }
}