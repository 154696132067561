import ApiConstants from '../../constants/ApiConstants.js';
import xhr from '../xhr';

const PaymentApiUtils = {

  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (opts[key]) {
        if (!first) {
          query += '&';
        } else {
          first = false;
        }
        query += key + "=" + opts[key]
      }
    }
    return xhr(ApiConstants.FetchPayments + query);
  },
};

export default PaymentApiUtils;
