import React from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class DataByTutorsFilter extends React.Component {

  constructor() {
    super();
    this.state={
      focused: false,
    }
  }

  render() {
    const {
      loading,
      loaded,
      chosenDate,
      changeAdded,
      handleUpdateChosenDate,
      handleSubmit
    } = this.props;
    return (
      <div className="data-filter">
        <ul className="flex-container flex-ext list-unstyled flex-items-start flex-wrap">
          <li className="row-space-2">
            <SingleDatePicker
              id="duration_end_of_set_input"
              date={chosenDate}
              numberOfMonths={1}
              focused={this.state.focused}
              onDateChange={(date) => { handleUpdateChosenDate(date)}}
              onFocusChange={({ focused }) => { this.setState({ focused }); }}
              isOutsideRange={() => false}
              withPortal={true}
            />
          </li>
          <li className="row-space-2">
            <button onClick={handleSubmit} className="btn btn-success" disabled={loading || !changeAdded}>Update</button>
          </li>
        </ul>
      </div>
    );
  }
}

export default DataByTutorsFilter;
