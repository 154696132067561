import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';

const CalendarHeader = (props) => {
  const { startTime } = props;
  let format = 'ddd M/D'
  let days = _.map([0, 1, 2, 3, 4, 5, 6], (i) => {
    let copyOfStartTime = _.cloneDeep(startTime)
    let formattedDate = copyOfStartTime.add(i, 'days').format(format);
    return (
      <td
        key={`cal-header-date${i}`}
        className="today text-center"
      >{formattedDate}
      </td>
    );
  });
  return (
    <div className="cal-header">
      <table>
        <tbody>
          <tr>
            <td className="cal-timezone side text-center">GMT+8</td>
            {days}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CalendarHeader.propTypes = {
  startTime: PropTypes.object,
};

export default CalendarHeader;
