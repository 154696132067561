import ApiConstants from '../../constants/ApiConstants.js';
import checkResults from '../checkResults.js';
import xhr from '../xhr';

const TutorApiUtils = {
  fetch(opts) {
    let query = '?';
    let first = true;
    for (const key of Object.keys(opts)) {
      if (!first) {
        query += '&';
      }
      query += key + "=" + opts[key]
      if (first) {
        first = false;
      }
    }
    return xhr(ApiConstants.Base + 'tutors' + query);
  },
  fetchOne(id) {
    return xhr(ApiConstants.Base + 'tutors/' + id);
  },

  fetchDataForSchedulesCal(id, startTime) {
    let query = "?startTime=" + startTime;
    return xhr(ApiConstants.Base + 'tutors/' + id + '/data-for-schedules-cal' + query);
  }
};

export default TutorApiUtils;