import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table';
// import { Link } from 'react-router-dom';
import '../../../styles/vendor/fixed-data-table/fixed-data-table.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ActionCell from './LessonIssueActionCell';

class LessonIssuesTable extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    this.setState({ width: window.innerWidth });
    e.preventDefault();
  }

  getTitle(actionOrReason) {
    if (actionOrReason && actionOrReason.title) {
      return actionOrReason.title
    }
  }

  render() {
    const items = this.props.issues;
    const screenWidth = this.state.width
    const width = screenWidth >= 1100 ? screenWidth * 0.833 - 25 : screenWidth - 25;
    const height = 50 + items.length * 50 > 500 ? 500 : 50 + items.length * 50;
    return (
      <Table
        width={width}
        height={height}
        overflowX={'auto'}
        overflowY={'auto'}
        rowHeight={50}
        rowsCount={items.length}
        headerHeight={50}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].id}
            </Cell>
          )}
          width={50}
        />
        <Column
          header={<Cell>Lesson</Cell>}
          cell={props => (
            <Cell {...props}>
              <Link to={`/a/db/lessons/${items[props.rowIndex].lesson_id}`}>
                {items[props.rowIndex].lesson_id}
              </Link>
            </Cell>
          )}
          width={70}
        />
        <Column
          header={<Cell>Date</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].lesson_start).format('YYYY-MM-DD')}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Time</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].lesson_start).format('HH:mm')}-{moment(items[props.rowIndex].lesson_end).format('HH:mm')} UTC{moment(items[props.rowIndex].lesson_start).format('ZZ')}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Student</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].student_name} ({items[props.rowIndex].student_id})
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Tutor</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].tutor_name} ({items[props.rowIndex].tutor_id})
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Sender</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].sender_id}
            </Cell>
          )}
          width={70}
        />
        <Column
          header={<Cell>Status</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].status_id === 1 ? 'Open' : 'Resolved'}
            </Cell>
          )}
          width={70}
        />
        <Column
          header={<Cell>Reported At</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].sent_at).fromNow()}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Resolved At</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].status_id === 2 ? moment(items[props.rowIndex].resolved_at).fromNow() : ''}
            </Cell>
          )}
          width={100}
        />
        <Column
          header={<Cell>Issue Reported</Cell>}
          cell={props => (
            <Cell {...props}>
              {items[props.rowIndex].issue_types[0]}
            </Cell>
          )}
          width={150}
        />
        <Column
          header={<Cell>Action Taken</Cell>}
          cell={props => (
            <Cell {...props}>
              {this.getTitle(items[props.rowIndex].action)}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Reason</Cell>}
          cell={props => (
            <Cell {...props}>
              {this.getTitle(items[props.rowIndex].reason)}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>Actions</Cell>}
          cell={
            <ActionCell
              tableName='lessonIssues'
              data={items}
            />
          }
          width={100}
        />
        <Column
          header={<Cell>CREATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].created_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={220}
        />
        <Column
          header={<Cell>UPDATED AT</Cell>}
          cell={props => (
            <Cell {...props}>
              {moment(items[props.rowIndex].updated_at).format('YYYY-MM-DD HH:mm ZZ')}
            </Cell>
          )}
          width={220}
        />
      </Table>
    );
  }
}

LessonIssuesTable.propTypes = {
  issues: PropTypes.array,
};

export default LessonIssuesTable;
