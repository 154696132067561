import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class DatabaseSidenav extends Component {

  render() {
    return (
      <div className="sidenav layout-box o-has-rows row-space-3">
        <div className="sidenav-filter-wrapper">
          <ul className="sidenav-list">
            <li><h4>Database</h4></li>
            <li><Link to="/a/db/users" className="sidenav-item">Users</Link></li>
            <li><Link to="/a/db/lessons" className="sidenav-item">Lessons</Link></li>
            <li><Link to="/a/db/lesson-issues" className="sidenav-item">Lesson Issues</Link></li>
            <li><Link to="/a/db/payments" className="sidenav-item">Payments</Link></li>
            <li><Link to="/a/db/courses" className="sidenav-item">Courses</Link></li>
            <li><Link to="/a/db/materials" className="sidenav-item">Materials</Link></li>
            <li><Link to="/a/db/holidays" className="sidenav-item">Holidays</Link></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default DatabaseSidenav;
