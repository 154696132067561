import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShiftSetNav from './ShiftSetNav';
import CalendarContainer from './cal/CalendarContainer';
import _ from 'lodash';
import Editor from './Editor';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      editing,
      saving,
      loading,
      shiftSets,
      editType,
      chosenShiftSetId,
      newShiftSetShifts,
      openAddShiftSetModal,
      openEditShiftSetModal,
      durationEndOfFomerSet,
      durationStartOfNewSet,
      durationEndOfNewSet,
      onUpdateDurationEndOfFormerSet,
      onUpdateDurationStartOfNewSet,
      onUpdateDurationEndOfNewSet,
      onUpdateDurationOfNewSet,
      onUpdateTypeOfEditor,
      onUpdateNewShiftSetShifts,
      saveNewShiftSet,
      cancelCreatingNewShiftSet,
      initiateNewShiftSet,
      switchShiftSet,
    } = this.props;
    let shifts,
      shiftSet,
      main,
      footer;
    if (!editing) {
      shiftSet = _.findLast(shiftSets, shiftSet => shiftSet.id === chosenShiftSetId);
      shifts = shiftSet ? shiftSet.shifts : [];
      main = (
        <CalendarContainer
          loading={loading}
          shifts={shifts}
        />
      );
      footer = <div />;
    } else {
      shifts = newShiftSetShifts;
      main = (
        <Editor
          editing={editing}
          saving={saving}
          editType={editType}
          shiftSets={shiftSets}
          newShiftSetShifts={newShiftSetShifts}
          durationEndOfFomerSet={durationEndOfFomerSet}
          durationStartOfNewSet={durationStartOfNewSet}
          durationEndOfNewSet={durationEndOfNewSet}
          onUpdateDurationEndOfFormerSet={onUpdateDurationEndOfFormerSet}
          onUpdateDurationStartOfNewSet={onUpdateDurationStartOfNewSet}
          onUpdateDurationEndOfNewSet={onUpdateDurationEndOfNewSet}
          onUpdateDurationOfNewSet={onUpdateDurationOfNewSet}
          onUpdateTypeOfEditor={onUpdateTypeOfEditor}
          onUpdateNewShiftSetShifts={onUpdateNewShiftSetShifts}
          saveNewShiftSet={saveNewShiftSet}
        />
      );
      footer = (
        <div className="panel-footer">
          <button
            onClick={cancelCreatingNewShiftSet}
            className="btn btn-secondary text-left"
            disabled={!!saving}
            type="reset"
          >
            Cancel
          </button>
          <button
            onClick={saveNewShiftSet}
            className="btn btn-secondary pull-right"
            type="submit"
            disabled={!!saving}
          >
            Create
          </button>
        </div>
      );
    }
    return (
      <div className="panel">
        <div className="panel-header">
          Tutor Shift Sets
        </div>
        <div className="panel-body">
          <ShiftSetNav
            shiftSets={shiftSets}
            chosenShiftSetId={chosenShiftSetId}
            openAddShiftSetModal={openAddShiftSetModal}
            openEditShiftSetModal={openEditShiftSetModal}
            initiateNewShiftSet={initiateNewShiftSet}
            editing={editing}
            switchShiftSet={switchShiftSet}
          />
          <div className="shift-cal-main-wrapper row-space-top-4">
            {main}
          </div>
        </div>
        {footer}
      </div>
    );
  }
}

export default Home;
