import {
  SHOW_MODAL,
  HIDE_MODAL,
  ADD_MODAL_MESSAGE,
  CLEAR_MODAL_MESSAGE
} from '../actions/modal';

const initialState = {
  isOpen: false,
  content: "",
  autoDisappear: false,
  message: "",
  messageType: "info",
  modalProps: {}
}

export default function modalReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SHOW_MODAL:
      const {content, modalProps} = payload;
      return {
        ...state,
        isOpen: true,
        content,
        message: "",
        messageType: "info",
        modalProps
      };
    case HIDE_MODAL:
      return {
        ...state,
        isOpen: false,
        content: "",
        message: "",
        messageType: "info",
        autoDisappear: false,
        modalProps: {}
      };
    case ADD_MODAL_MESSAGE:
      return {
        ...state,
        message: payload.message,
        messageType: payload.type || "info",
        autoDisappear: payload.autoDisappear || false
      };
    case CLEAR_MODAL_MESSAGE: 
      return {
        ...state,
        message: "",
        messageType: "info",
        autoDisappear: false
      };
    default:
      return state;
  }
}